import React from "react";
import "./css/Erangle.scss";
function Erangle({ image }) {
  return (
    // <div className='erangle' style={{ backgroundImage: `url(${image})` }}>
    //   <h1>raza</h1>
    // </div>

    <div className="erangle" style={{ backgroundImage: `url('${image}')` }}>
      <div className="data">
        <div>
          <h3>
            Enlarge Your <span>Brand</span>
          </h3>
          <p>
            We boom by staring at the latest market trends, insights, and
            reality. For us, no boundary is limited, no complex product
            difficult to outline, and no obstacle is too enormous. We push our
            limitations beyond & create a sophisticated solution through dynamic
            mobile apps and websites.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Erangle;
