import React from "react";
import "./css/Description.scss";
import { FaInfinity } from "react-icons/fa";

function Description({ data }) {
  return (
    <div className="description">
      <div className="data">
        <div className="inner">
          <div className="conboinner">
            {data.h1 ? <h1>{data.h1}</h1> : <h2>{data.title}</h2>}
            {data.subtitle ? <h3>{data.subtitle}</h3> : null}
            {data.desc ? <p>{data.desc}</p> : null}
            <ul className="twocolsec">
              {data.arr.map((item, index) => {
                return (
                  <li key={index}>
                    <FaInfinity className="icon" /> {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Description;
