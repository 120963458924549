import React from "react";
import "./css/Leadexpertise.scss";
function Leadexpertise() {
  return (
    <div className="leadexpertise">
      <div className="inner">
        <div className="data">
          <h1>
            <span>Web and App </span>
            Development Company
          </h1>
          <p>
            A passionate and technologically advanced web and mobile app
            development company with leading expertise of creating fast, smooth,
            and customized web and mobile app solutions. We develop unique,
            intelligent and user-friendly UI/UX for web and mobile application
            which allow our customers to achieve their organizational
            objectives.
          </p>
        </div>
        <div className="image">
          <img src="assets/home/28.webp" />
        </div>
      </div>
    </div>
  );
}

export default Leadexpertise;
