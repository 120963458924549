import React, { useEffect } from "react";
import Banner from "../components/Banner";
import Fwsec from "../components/Fwsec";
import Insig from "../components/Insig";
import Testimonial from "../components/Testimonial";
import Vission from "../components/Vission";
import Wearecreative from "../components/Wearecreative";
import "./About.scss";
function About() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  return (
    <div className="about">
      <Banner img={"assets/about/aboutbanner.webp"} />
      <Vission />
      <Insig />
      <Wearecreative />
      <Fwsec />
      <Testimonial />
    </div>
  );
}

export default About;
