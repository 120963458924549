import React from "react";
import "./css/Banner.scss";
import Button from "./Button";
function Banner({ img }) {
  return (
    <div className="banner" style={{ backgroundImage: `url(${img})` }}>
      {/* <img src={img} /> */}
      <div className="texti">
        <h1>
          <span>Enlarge</span> Your <span>Brand</span>
        </h1>
        <p>
          We develop the best online solutions by developing our top-notch
          services that linkage with our customer’s business and helps in
          engaging more leads, more audience, and grow their business.
        </p>
        <a href="tel:+19563023709">Get Call</a>
      </div>
    </div>
  );
}

export default Banner;
