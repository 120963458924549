import React from "react";
import { Link } from "react-router-dom";
import "./css/Buildapp.scss";
function Buildapp() {
  return (
    <div className="bapp">
      <div className="inner">
        <div className="data">
          <h2>
            We Build <span>Delightful</span> Digital Experiences
          </h2>
          <p>
            Get leverage with fully-featured bespoke web and app development
            services. Being a full-cycled web and mobile app development
            company, we develop 100% customer-oriented websites, mobile
            applications, and e-commerce platforms.
          </p>
          <Link to={"/web-development-service"}>Learn more</Link>
        </div>
        <div className="image">
          <img src="assets/home/buildapp.webp" />
        </div>
      </div>
    </div>
  );
}

export default Buildapp;
