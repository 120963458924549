import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footerv1 from "./components/Footerv1";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import About from "./pages/About";
import MobileAppDevelopment from "./pages/MobileAppDevelopment";
import Webdevelopment from "./pages/Webdevelopment";
import Digitalmarketing from "./pages/Digitalmarketing";
import Magento from "./pages/Magento";
import Magentodevelopmentservice from "./pages/Magentodevelopmentservice";
import Shopifydevelopmentservice from "./pages/Shopifydevelopmentservice";
import Reactdevelopmentservice from "./pages/Reactdevelopmentservice";
import Mobileappdevelopmentservice from "./pages/Mobileappdevelopmentservice";
import Pwa from "./pages/Pwa";
import Sem from "./pages/Sem";
import Smm from "./pages/Smm";
import Seo from "./pages/Seo";
import Smo from "./pages/Smo";
import BlogListing from "./pages/BlogListing";
import BlogDetail from "./pages/BlogDetail";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/portfolio" exact element={<Portfolio />} />
          <Route path="/about" exact element={<About />} />

          <Route
            path="/web-development-service"
            exact
            element={<Webdevelopment />}
          />
          {/* <Route path='/mobile-app-development2' exact element={<MobileAppDevelopment />} /> */}
          {/* <Route path='/digital-marketing2' exact element={<Digitalmarketing />} /> */}
          {/* <Route path='/magento-development2' exact element={<Magento />} /> */}

          {/* as content Routes */}

          {/*  */}
          <Route
            path="/mobile-app-development-service"
            exact
            element={<Mobileappdevelopmentservice />}
          />
          {/*  */}
          <Route path="/pwa-development-service" exact element={<Pwa />} />
          {/*  */}
          <Route
            path="/react-development-service"
            exact
            element={<Reactdevelopmentservice />}
          />
          {/*  */}
          <Route
            path="/magento-development-service"
            exact
            element={<Magentodevelopmentservice />}
          />

          <Route
            path="/shopify-development-service"
            exact
            element={<Shopifydevelopmentservice />}
          />

          <Route path="/sem-service" exact element={<Sem />} />

          <Route path="/smm-service" exact element={<Smm />} />

          <Route path="/seo-service" exact element={<Seo />} />

          <Route path="/smo-service" exact element={<Smo />} />

          <Route path="/blog" exact element={<BlogListing />} />

          <Route
            path="/blog/why-mobile-apps-are-important-for-your-business"
            element={<BlogDetail />}
          />
        </Routes>
        <Footerv1 />
      </Router>
    </div>
  );
}

export default App;
