import React from "react";
import "./css/Vission.scss";
function Vission() {
  return (
    <div className="vission">
      <div className="leftsec">
        <h3>
          We are Creative Development Team for Modern World of Digitalization
        </h3>
        <p>
          <span>
            We are a web and app development agency that works with modern
            customers. We specialize in web and app development services,
            develop them to be user-friendly, with proper functionalities, and
            fully secured. Our web experience is technologically high-performing
            and transformative that is helpful to the growth of yours company.
          </span>
        </p>
      </div>
      <div className="rightsec">
        <h1>
          A Relentless Approach to Creating Exceptional{" "}
          <span> Digital Experiences</span>
        </h1>
      </div>
    </div>
  );
}

export default Vission;
