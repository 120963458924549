import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Appemergingtec from "../components/Appemergingtec";
import Buildin from "../components/Buildin";
import Businesstaxsec from "../components/Businesstaxsec";
import Cta from "../components/Cta";
import CustomSubbanner from "../components/CustomSubbanner";
import Description from "../components/Description";
import Descriptionwh from "../components/Descriptionwh";
import Dynamiccontent from "../components/Dynamiccontent";
import Dynamiccontentwh from "../components/Dynamiccontentwh";
import Faq from "../components/Faq";
import Homeportfolio from "../components/Homeportfolio";
import Severalapp from "../components/Severalapp";
import Webtec from "../components/Webtec";
import "./Pwa.scss";
function Pwa() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  let banner = {
    img: "assets/banners/pwa.webp",
    width: "700px",
    heading: " Future Promising <span>PWA Development </span>Services",

    para: "Guaranteeing an ideal environment for consistently expanding your business through our future promising PWA development services.",
    bottom: "-250px",
    marginbottom: "250px",
    backgroundImg: `url('assets/appdevelopment/pwa.webp')`,
  };
  let webd = {
    title: "Progressive Web App ",
    subtitle: "Future of Web & App Development ",
    desc: ``,
    arr: [
      "Highly responsive & easily discoverable",
      "Offline browsing",
      "Most Interactive",
      "Future-Promising ",
      "Multiple Payment Options",
      "Advance business cost-effectively",
    ],
  };

  let portfolio = {
    title: "Our Work Experience",
    desc: "Discover how we have helped businesses with our PWA development services delivering customers beyond their expectations.",
  };
  // builtin feature

  let builtinfeature = {
    title: "Fully Integrated & Optimized Build-In Features",
    desc: "Our fully integrated and optimized build-in features can enhance the performance of your web and app development, such as:",
    arr: [
      "Virtual DOM",
      "Model View Controller",
      "Declarative UI",
      "Speed, efficiency & Flexibility",
      "JavaScript XML",
      "One-way Data Binding",
    ],
  };

  let cta = {
    one: "Progressive web app development can take your business to the next level!",
    two: `Contact Simboz to get the exciting edge over your competitors!`,
  };

  let mdservice = {
    h1: "PWA Development Services ",
    subtitle: "Futuristic PWA Development",
    desc: `<p>Since the traditional application developments are too costly and time-consuming, Progressive Web App development enters the game. </p>
        <p><b>“Progressive Web App development has been strongly patronized following the increasing success rates of AliExpress, 5miles, and the Wahington Post; which grants it the opportunity to be the future of web development.” (Google Inc.)</b></p>
            <p>As a ranking progressive web app development company, our PWA development services accommodate you to experience cutting-edge, highly-responsive, and easily discoverable PWA development services. Our Progressive web application performs faster than an ordinary app. In this way, the user can easily access the web on any device, ensuring the quickest possible load time.</p>
            `,
  };

  let mds = {
    title: "Our PWA Developers with their language Skills ",
    subtitle: "",
    desc: `<p>Our team of PWA developers implements the most recent functionalities such as offline browsing, push notification, data analysis, and native app-like UI; in accordance with the customer's specifications, regardless of multiple technological constraints. </p>
        <p>With the latest features, we provide PWA development services for a multitude of niches, including e-commerce, social media, and gaming apps. It can be added as home screen icons alongside native mobile apps and offers tons of functionalities which are accessible in offline mode as well.</p>
       `,
  };

  let grow = {
    title: "Modern Tools to Grow your Business",
    subtitle: "",
    desc: `<p>Our progressive web application development company helps disruptive startups, multinational corporations, and mid-sized businesses broadening at rapid growth. Our team specializes in developing futuristic PWAs using their ideal frameworks such as Ionic, Polymer, AngularJS, and React PWA library, ensuring that they don’t depend on single API and deliver the best possible web experience. </p>
        <p>Our developers help to make the most interactive PWA UI, highly responsive, and optimized as per user’s specification. Being a prominent Progressive Web App development company, we ensure our PWAs cope with smooth transitions, dynamic menu interfaces, advanced search functionality, hyper-speed first-time loading, and robust programming for data transfers.</p>`,
  };

  const faq = [
    [
      "<p>Will I get only limited number of pages on my PWA or I can get as much as I require?</p>",
      "<p>No, you get unlimited pages and categories as per your specifications. To remove your confusion, as a progressive web app development company, our developer team provides the three pages or layouts; homepage, services page, and about page. You can see all the layouts of pages then after decided which one you want to add more to your PWA – it will be much better to describe your details of pages in a list so that the team can operate smoothly following your instructions.</p>",
    ],

    [
      "<p>What is the total cost of progressive web app development?</p>",
      "<p>It depends on the complexity of the project and its features, technologies that is used, and the quality and creativity of the progressive web app development.</p>",
    ],

    [
      "<p>What if I don’t like the progressive web app design & development or what if the designing and contents are not provided as I recommended?</p>",
      "<p>Having the expert progressive web app developers, we design in that way which makes you happy, and if you don’t like, we will design on another way until you get complete satisfaction.</p>",
    ],
    [
      "<p>After launching the progressive web app what if I get the errors or the app crashed?</p>",
      "<p>First thing is that we don’t launch the progressive web app unless we testify all the programs and make surety that the mobile app will not have bugs or malfunctions. But even if there is an issue or crash – you have our backup team supporting all the crashes and problems raising on your progressive web app for three months, until then your mobile app will start running smoothly.</p>",
    ],
    [
      "<p>Do you provide me the app content or I have to make my own?</p>",
      "<p>Yes, we do! But it’s up to you… If you don’t have the text of your progressive web app & want to add more impressive progressive web application contents – texts, we are here to give you our enriched quality PWA development services as well.</p>",
    ],
  ];

  const data = {
    h1: "Our Process ",
    p: `
         <p className='fp'>
         For developing intriguing PWAs, our process includes proactive approach that covers everything from conceptualization, designing and development, testing, launching, and after-sale services. We affix ourselves from ideation to delivery and ongoing support as well.
        </p>`,

    arr: [
      [
        `Project Understanding`,
        `  understanding the core-concepts of your business niche `,
        "assets/icon/PWA/ProjectUnderstanding.png",
      ],

      [
        `PWA Development Team `,
        ` Assigns you an expert team designed to work throughout your project`,
        "assets/icon/PWA/pwa.png",
      ],

      [
        `Technology Stack  `,
        `Wireframing aids in understanding the PWA flow to select the technological stack that best suits your company's demands`,
        "assets/icon/PWA/TechnologyStack.png",
      ],

      [
        `Designing UI/UX `,
        `Select the brand color and other mandatory aspects to make the final UI for each screen`,
        "assets/icon/PWA/DesigningUIUX.png",
      ],

      [
        `Development   `,
        ` Coding from the ground to build a user-oriented PWA`,
        "assets/icon/PWA/launch.png",
      ],

      [
        `Launch   `,
        `Keeping your quality assurance, we launch your PWA on your domain`,
        "assets/icon/PWA/Maintenance.png",
      ],

      [
        `Maintenance  `,
        `Maintain and fix bugs with recent updates`,
        "assets/icon/PWA/Maintenance.png",
      ],
    ],
  };

  return (
    <div className="Pwa">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Progressive Web App Development Service Providing Company</title>
        <meta
          name="description"
          content="We develop high-end network independent, responsively designed, and easily installable PWAs using modern stacks such as Iconic, Polymer, ReactJs, and Lighthouse."
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />

        {/* ------- */}

        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://simboz.com/assets/logos/logo.png"
        />
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://simboz.com/mobile-app-development-service"
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="We develop high-end network independent, responsively designed, and easily installable PWAs using modern stacks such as Iconic, Polymer, ReactJs, and Lighthouse.-"
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Progressive Web App Development Service Providing Company"
        />
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Progressive Web App Development Service Providing Company | Simboz "
        />
        <meta data-react-helmet="true" property="fb:app_id" content="Simboz" />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <meta
          data-react-helmet="true"
          name="description"
          content="We develop high-end network independent, responsively designed, and easily installable PWAs using modern stacks such as Iconic, Polymer, ReactJs, and Lighthouse.-"
        />
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://simboz.com/mobile-app-development-service"
        />
      </Helmet>
      <CustomSubbanner data={banner} />
      <Description data={webd} />
      <Homeportfolio portfolio={portfolio} />
      <Descriptionwh data={builtinfeature} />
      <Cta data={cta} />
      <Dynamiccontentwh data={mdservice} />
      <Dynamiccontent data={mds} />
      <Dynamiccontentwh data={grow} />
      <Businesstaxsec data={data} />
      {/* <Description data={process} /> */}
      {/* <Appemergingtec data={emmertec} /> */}
      <Severalapp />
      <Faq faq={faq} />
    </div>
  );
}

export default Pwa;
