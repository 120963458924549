import React from "react";
import "./css/Fwsec.scss";
function Fwsec() {
  return (
    <div
      className="bann"
      style={{ backgroundImage: `url('assets/about/banner.webp')` }}
    >
      <div className="inner">
        <div className="hedi">
          <h5>
            <span>
              LEARN.
              <br />
            </span>{" "}
            IDEATE. <br />
            <span>
              INCUBATE. <br />
              ITERATE. <br />
            </span>
            SCALE.
          </h5>
        </div>
      </div>
    </div>
  );
}

export default Fwsec;
