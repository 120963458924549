import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Appemergingtec from "../components/Appemergingtec";
import Buildin from "../components/Buildin";
import Cta from "../components/Cta";
import CustomSubbanner from "../components/CustomSubbanner";
import Description from "../components/Description";
import Descriptionwh from "../components/Descriptionwh";
import Dynamiccontent from "../components/Dynamiccontent";
import Dynamiccontentwh from "../components/Dynamiccontentwh";
import Faq from "../components/Faq";
import Homeportfolio from "../components/Homeportfolio";
import Severalapp from "../components/Severalapp";
import Webtec from "../components/Webtec";

import "./Sem.scss";
function Sem() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  let banner = {
    img: "assets/banners/PPC.webp",
    width: "700px",
    heading: "  Driving<span> Valuable Traffic </span>  to Your Site",

    para: "Simboz promises to deliver 100% traffic to your site using PPC and tailored ad campaigns!",
    bottom: "-250px",
    marginbottom: "250px",
    backgroundImg: `url('assets/appdevelopment/smmm.webp')`,
  };
  let webd = {
    title: "Performance Based Sem Services Across NYC",
    desc: `Handling all of your paid Search Engine Marketing with positive analytics, we put your business in front of the crowd who are actively searching on Google, Bing, YouTube and other Search Engines. `,
    arr: [
      "Optimizing Analytics",
      "Measuring ROI",
      "Effective Copywriting",
      "Competitor Research",
      "Secure Payment",
    ],
  };

  let portfolio = {
    title: "Our Work Experience",
    desc: "Discover how we have helped businesses with our Professional Search Engine Marketing Services and delivered customers beyond their expectations.",
  };
  // builtin feature

  let builtinfeature = {
    title: "Excellent Built-In Features",
    desc: "Our SEM Company provides excellent built-in features that enhance the performance of your website, such as:",
    arr: [
      "Ad copy and creation tools",
      "Keyword research and reporting",
      "Impression and impact analytics",
      "Campaign planning",
      "Automated alert management",
      "Measuring ROI",
    ],
  };

  let cta = {
    one: "Increase your visibility in Search Engines and expand your reach!",
    two: "Our SEM Experts help you meet your goals! ",
  };

  let mdservice = {
    h1: "Bringing You Positive Results with Tailored SEM Services",
    subtitle: "Our Highly Experts Will Never Let You Down",
    desc: `<p>If you are investing in paid Search Engine Marketing to boost your website to stand among the ranking businesses, you will always want the highly experienced experts or the most wanted SEM company who understand your needs without wasting your time and money on ineffective campaigns.</p>
        <p>Working with Simboz can benefit you getting access with the top SEM experts. And that relieves your worries in learning SEM, as you will already have experts on your hand facilitating with their SEM services for your Site.</p>
        <p>As a ranking Search engine marketing company, our SEM Specialists know exactly what works or not as they have excessive expertise in creating custom paid ads campaigns and the time duration to run them with a valid amount of bidding. You can simply avail our exclusive Search Engine Marketing Services to boost your sales and make your place amongst the ranking giants. </p>`,
  };

  let mds = {
    title: "Maximize Your ROIs",
    subtitle: "",
    desc: `<p>You might get a hard time in managing your own SEM Services as you would not have extensive knowledge and broader exposure of dealing all the Ads Campaigning systems compared to a well-developed Search Engine Marketing Company. You might not have a lot of tools that can even counter and inform your analytics graph downfall as your created campaigns might not be reaching up to their fullest potentials. </p>
        <p>But once you contact Simboz, you get everything in hand. Our Expert Search Engine Marketing Services Providers will never feel hesitancy to bring you excessive ROIs on your Site using their daily based strong exposure of Ads campaigning with most recent tools.</p>
        <p>The Work of our SEM Specialists speaks in bold for them and at Simboz, you will never regret spending on our tailored ads and paid Search Engine Marketing that will be delivered to you with positive results. </p>`,
  };

  let grow = {
    title: "Drive Results In Cost-Effective Budget",
    subtitle: "",
    desc: `<p>As a ranking Search Engine Marketing Company, we communicate openly and understand needs related to your paid Search Engine Marketing. On the basis of your business module requirements, we create campaigns and drive sea of crowd to your site at extremely cost-effective budget. </p>
        <p>Aware of your business details, we design a SEM campaign tailored to your company's specific requirements.  execute custom-tailored strategies to guarantee that our SEM Services improves your profitability and enables you to generate exceptional results.</p>
        <p>It has always been difficult to reach the target audience. But since you have signed a contract with Simboz, we provide you a SEM Specialist team that completely understands your business and controls your spending by targeting the most interested customers. Keyword targeting shows your adverts to prospects searching for your products or services. </p>`,
  };

  let process = {
    title: "OUR PROCESS",
    desc: "We put into action to process the desired Magento development in order to obtain the following benefits as a whole:",
    arr: [
      "Planning – Plan after understanding the project brief",
      "Shopify Team – Assign you a team throughout the entire procedure",
      "Designing UI/UX – Create eye-catching and user-friendly UI for each page",
      "Development – Code from the ground to develop and customize each page on specification",
      "Launch – Keeping your quality assurance, we launch Shopify store on your domain",
      "Maintenance – Maintain and fix bugs with recent updates",
    ],
  };

  const faq = [
    [
      "<p>How is a search engine marketing (SEM) ad designed?</p>",
      "<p>When a user goes for a search, the results of an automatic auction that takes place in real time are used to determine which sponsored search results are displayed to the user. The amount that an advertiser bids, the quality of the advertiser's marketing, and the increased possibility of a user clicking an ad because of ad extensions are the factors that determine which advertisements show up in which locations on a website. Ad extensions also play a role in this determination.</p>",
    ],

    [
      "<p>How can I ensure that my search ads appear at the top of the page?</p>",
      "<p>However, the frequency with which you appear at the top of the page can be adjusted by a broad variety of various circumstances. This can have an effect on how often people see your profile. This is due to the fact that Google has a compelling reason to ensure that nobody can consistently get first place in search results 100% of the time.</p>",
    ],

    [
      "<p>Which of these two marketing strategies—SEO or SEM—should I prioritize for my business?</p>",
      "<p>The answer, in a nutshell, is that they are both essential in roughly the same extents. Both organic search engine optimization (SEO) and search engine marketing (SEM) are geared toward accomplishing the same thing, which is to enhance the visibility of your brand on the pages that are returned by search engines (SERPs). This has the potential to contribute to the expansion of your brand as well as boost its visibility online, and this can only be possible if you get the services from the well-developed SEM Company.</p>",
    ],
    [
      "<p>How can I evaluate whether or not my business would benefit from Search Engine Marketing?</p>",
      "<p>It is quite likely that your firm will benefit from some component of SEM Services, whether it be Search Engine Optimization (SEO) or Pay-Per-Click (PPC) advertising. This is the case even if your company is headquartered in a cave and your clients are other cavemen. Search Engine Marketing provides an opportunity to boost your bottom line regardless of the primary thing you aim to generate online, whether it be awareness, new recruitment, strategic partners, investors, leads, or sales. A brief evaluation carried out by trained experts such as Simboz will assist in elucidating the requirement and the appropriateness of the solution.</p>",
    ],
    [
      "<p>Can you rank me #1 on Google?</p>",
      "<p>No, we are unable to promise that you will be ranked number one among organic products, and you should be wary of anyone who claims they can. There are approximately 200 different aspects that go into determining where a website appears in the search engine results page. These aspects include things like the location of the website, how long it has been online, the quality of the inbound links, how competitive the market is, and many more. We focus on the most relevant search terms for your company and employ a variety of techniques to get you up to the top of the first page of results. When properly optimized and advertised, local search results and pay-per-click (PPC) advertisements are also capable of occupying on the first page of a search engine's results.</p>",
    ],
  ];

  return (
    <div className="sem">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Search Engine Marketing Company - SEM Service</title>
        <meta
          name="description"
          content="Using our SEM Services, our professionals at Simboz create custom ads campaigns and drive leads, consistent sales by targeting specific audience. "
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />

        {/* ------------ */}

        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://simboz.com/assets/logos/logo.png"
        />
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://simboz.com/sem-service"
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Using our SEM Services, our professionals at Simboz create custom ads campaigns and drive leads, consistent sales by targeting specific audience."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Best Search Engine Marketing Company - SEM Service"
        />
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Best Search Engine Marketing Company - SEM Service | Simboz "
        />
        <meta data-react-helmet="true" property="fb:app_id" content="Simboz" />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Using our SEM Services, our professionals at Simboz create custom ads campaigns and drive leads, consistent sales by targeting specific audience."
        />
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://simboz.com/sem-service"
        />
      </Helmet>

      <CustomSubbanner data={banner} />
      <Description data={webd} />
      <Homeportfolio portfolio={portfolio} />
      <Descriptionwh data={builtinfeature} />
      <Cta data={cta} />
      <Dynamiccontentwh data={mdservice} />
      <Dynamiccontent data={mds} />
      <Dynamiccontentwh data={grow} />
      <Description data={process} />
      {/* <Appemergingtec data={emmertec} /> */}
      <Severalapp />
      <Faq faq={faq} />
    </div>
  );
}

export default Sem;
