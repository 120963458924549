import React from "react";
import "./css/Descriptionwh.scss";
import { FaInfinity } from "react-icons/fa";

function Descriptionwh({ data }) {
  return (
    <div className="Descriptionwh">
      <div className="data">
        <div className="inner">
          <div className="conboinner">
            <h3 className="h1">{data.title}</h3>
            {data.subtitle ? <h2>{data.subtitle}</h2> : null}
            {data.desc ? <p>{data.desc}</p> : null}
            <ul className="twocolsec">
              {data.arr.map((item, index) => {
                return (
                  <li key={index}>
                    <FaInfinity className="icon" /> {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Descriptionwh;
