import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ScrollLock from "./ScrollLock";

import "./css/Header.scss";

import { FaBars, FaAngleDown, FaPhoneAlt } from "react-icons/fa";

function Header() {
  const [nav, setNav] = useState("home");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [blockScroll, allowScroll] = ScrollLock();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  if (window.screen.width > 1059) {
    window.addEventListener("scroll", (e) => {
      window.scrollY > 100
        ? document.getElementById("stickyid").classList.add("sticky")
        : document.getElementById("stickyid").classList.remove("sticky");
    });
  }

  let [toggle, setToggle] = useState(false);

  let showsubnav = () => {
    document.getElementById("innersub").style.display = "block";
  };

  let hidesubnav = () => {
    document.getElementById("innersub").style.display = "none";
  };

  let togocontacl = () => {
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#contact").getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          160,
      });
    }, 100);
  };

  let togocontacfmob = () => {
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#contact").getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          30,
      });
    }, 100);
  };

  let [showweb, setShowweb] = useState(false);
  let [showdigi, setShowdigi] = useState(false);

  return (
    <>
      {window.screen.width > 1059 ? (
        <div className="header" id="stickyid">
          <div className="inner">
            <div className="logo">
              <Link
                to={"/"}
                onClick={() => {
                  return setNav("home");
                }}
              >
                <img src="/assets/logos/logo.png" />
              </Link>
            </div>
            <div className="navbar">
              <ul>
                <li
                  onClick={() => {
                    return setNav("home");
                  }}
                >
                  <Link
                    to={"/"}
                    className={nav == "home" ? "active" : "inactive"}
                  >
                    Home
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setNav("service");
                  }}
                  className="showsubnav"
                  onMouseEnter={showsubnav}
                  onMouseLeave={hidesubnav}
                >
                  <a
                    href={"#"}
                    className={nav == "service" ? "active" : "inactive"}
                  >
                    Services
                  </a>
                  <div className="innersub" id="innersub">
                    <div className="inner2">
                      <div className="our_services">
                        <div className="serviceinner">
                          <p className="hedi">Our services</p>
                          <p className="para">
                            Leveraging cutting-edge technologies to build
                            industry-leading digital products.
                          </p>
                          <div className="core_services dis">
                            <p className="hedi">How we work</p>
                            <div className="wraper">
                              <h3>Discovery</h3>
                              <p>Exploring project requirements</p>
                            </div>
                            <div className="wraper">
                              <h3>Design</h3>
                              <p>Intuitive and engaging designs</p>
                            </div>
                            <div className="wraper">
                              <h3>Development</h3>
                              <p>Agile development processes</p>
                            </div>
                            <div className="wraper">
                              <h3>Testing</h3>
                              <p>Incremental testing and QA</p>
                            </div>
                            <div className="wraper">
                              <h3>Deployment</h3>
                              <p>Full-function product delivery</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="our_servicer">
                        <p className="hedi">Services</p>
                        <div className="links">
                          <div className="webdev">
                            <div className="devi">
                              <Link
                                onClick={hidesubnav}
                                to="/web-development-service"
                              >
                                <span>Web Development</span>
                                <p>
                                  Unleash your digital potential with expert Web
                                  Development.
                                </p>
                              </Link>
                            </div>
                            <div className="devi">
                              <Link
                                onClick={hidesubnav}
                                to="/mobile-app-development-service"
                              >
                                <span> Moible App Development Service</span>
                                <p>
                                  Captivate your audience with custom Mobile App
                                  Development.
                                </p>
                              </Link>
                            </div>
                            <div className="devi">
                              <Link
                                onClick={hidesubnav}
                                to="/pwa-development-service"
                              >
                                <span> PWA Development Service</span>
                                <p>
                                  Transform the web with Progressive Web Apps
                                </p>
                              </Link>
                            </div>
                            <div className="devi">
                              <Link
                                onClick={hidesubnav}
                                to="/react-development-service"
                              >
                                <span>React Development Service</span>
                                <p>Build dynamic, fast, and responsive UI</p>
                              </Link>
                            </div>
                            <div className="devi">
                              <Link
                                onClick={hidesubnav}
                                to="/magento-development-service"
                              >
                                <span> Magento Development Service</span>
                                <p>
                                  Experience the power of Magento E-commerce
                                </p>
                              </Link>
                            </div>
                            <div className="devi">
                              <Link
                                onClick={hidesubnav}
                                to="/shopify-development-service"
                              >
                                <span>Shopify Development Service</span>
                                <p>
                                  Turn your business dreams into a reality with
                                  Shopify development.
                                </p>
                              </Link>
                            </div>
                          </div>
                          <div className="socialmedia">
                            <div className="devi">
                              <Link onClick={hidesubnav} to="/sem-service">
                                <span> Search Engine Marketing Services</span>
                                <p>
                                  Drive traffic, boost sales, and dominate
                                  search
                                </p>
                              </Link>
                            </div>
                            <div className="devi">
                              <Link onClick={hidesubnav} to="/smm-service">
                                <span> Social Media Marketing Service</span>
                                <p>Grow Your ROI With Social Media Marketing</p>
                              </Link>
                            </div>
                            <div className="devi">
                              <Link onClick={hidesubnav} to="/seo-service">
                                <span> Search Engine Optimization Service</span>
                                <p>Turn Traffic Increases Into Revenue</p>
                              </Link>
                            </div>

                            <div className="devi">
                              <Link onClick={hidesubnav} to="/smo-service">
                                <span> Social Media Optimization Service</span>
                                <p>Maximize your online presence</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="core_services showit">
                        <p className="hedi">How we work</p>
                        <div className="wraper">
                          <h3>Discovery</h3>
                          <p>Exploring project requirements</p>
                        </div>
                        <div className="wraper">
                          <h3>Design</h3>
                          <p>Intuitive and engaging designs</p>
                        </div>
                        <div className="wraper">
                          <h3>Development</h3>
                          <p>Agile development processes</p>
                        </div>
                        <div className="wraper">
                          <h3>Testing</h3>
                          <p>Incremental testing and QA</p>
                        </div>
                        <div className="wraper">
                          <h3>Deployment</h3>
                          <p>Full-function product delivery</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  onClick={() => {
                    setNav("portfolio");
                  }}
                >
                  <Link
                    to={"portfolio"}
                    className={nav == "portfolio" ? "active" : "inactive"}
                  >
                    Portfolio
                  </Link>
                </li>
                <li
                  onClick={() => {
                    setNav("about");
                  }}
                >
                  <Link
                    to={"about"}
                    className={nav == "about" ? "active" : "inactive"}
                  >
                    About
                  </Link>
                </li>
                {/* <li
                  onClick={() => {
                    setNav("Contact");
                    togocontacl();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <a className={nav == "Contact" ? "active" : "inactive"}>
                    Contact
                  </a>
                </li> */}
              </ul>
            </div>

            <div className="btnsec">
              <div className="callsec">
                <a href="tel:+13477593666">
                  <FaPhoneAlt className="icon" /> +1 (347) 759-3666
                </a>
              </div>
              <div
                onClick={() => {
                  setNav("Contact");
                  togocontacl();
                }}
                style={{ cursor: "pointer" }}
                className="upcont"
              >
                <a className={nav == "Contact" ? "active" : "inactive"}>
                  Estimate Project
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mobilenav">
          <div className="mobheaderupper">
            <div
              className="ham"
              onClick={() => {
                setToggle(!toggle);
                blockScroll();
              }}
            >
              <FaBars />
            </div>

            <div className="moblogo">
              <img src="assets/logos/logo.png" />
            </div>
          </div>
          <div className={"drower"} style={{ left: toggle ? "0%" : "-100%" }}>
            <ul className="mobilenavli">
              <li
                onClick={() => {
                  setToggle(false);
                  allowScroll();
                }}
              >
                {" "}
                <Link to="/">Home</Link>{" "}
              </li>
              {/* ==================== webdev services ====================== */}

              <li
                // onClick={() => {
                //   setToggle(false);
                // }}
                className="web"
              >
                <span className="inlinearr">
                  <p>Website Development Services</p>
                  <FaAngleDown
                    className={showweb == "web" ? "iconweb" : "iconwebinv"}
                    onClick={() => {
                      setShowweb("web");
                    }}
                  />
                </span>
                <ul
                  className="subnav"
                  style={{ display: showweb == "web" ? "block" : "none" }}
                >
                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/web-development-service">Web Development</Link>
                  </li>

                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/mobile-app-development-service">
                      Moible App Development Service
                    </Link>
                  </li>

                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/pwa-development-service">
                      PWA Development Service
                    </Link>
                  </li>

                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/react-development-service">
                      React Development Service
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/magento-development-service">
                      Magento Development Service
                    </Link>
                  </li>

                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/shopify-development-service">
                      Shopify Development Service
                    </Link>
                  </li>
                </ul>
              </li>
              {/* ==================== social media dev services ====================== */}
              <li>
                <span className="inlinearr">
                  <p>Digital Marketing Services</p>
                  <FaAngleDown
                    className={showweb == "digi" ? "iconweb" : "iconwebinv"}
                    onClick={() => {
                      setShowweb("digi");
                    }}
                  />
                </span>
                <ul
                  className="subnav"
                  id="subnav"
                  style={{ display: showweb == "digi" ? "block" : "none" }}
                >
                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/digital-marketing">Digital Marketing</Link>
                  </li>
                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/sem-service">
                      Search Engine Marketing Services
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/smm-service">
                      Social Media Marketing Service
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/seo-service">
                      Search Engine Optimization Service
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setToggle(false);
                      allowScroll();
                    }}
                  >
                    <Link to="/smo-service">
                      Social Media Optimization Service
                    </Link>
                  </li>
                </ul>
              </li>
              {/* =========================================================== */}
              <li
                onClick={() => {
                  setToggle(false);
                  allowScroll();
                }}
              >
                {" "}
                <Link to="portfolio">Portfolio</Link>{" "}
              </li>
              <li
                onClick={() => {
                  setToggle(false);
                  allowScroll();
                }}
              >
                <Link to="about">About</Link>{" "}
              </li>
              <li
                onClick={() => {
                  setToggle(false);
                  togocontacfmob();
                  allowScroll();
                }}
              >
                Contact
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
