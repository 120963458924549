import React, { useEffect } from "react";
import "./css/Custombanner.scss";
// import Aos from "aos";
// import "aos/dist/aos.css";
function Custombanner() {
  // useEffect(() => {
  //   Aos.init({ duration: 1000 });
  // }, []);
  return (
    <div
      className="custombanner"
      style={{ backgroundImage: `url('assets/home/banner.webp')` }}
    >
      <div className="heading">
        <h2>
          Generate More <span> Revenue </span>from your{" "}
          <span>Futuristic Website</span>
        </h2>
        <p>
          Our top priority when working on a website design for you is
          increasing business revenue. Your modern website will enhance traffic,
          lead generation, and ultimately revenue.
        </p>
      </div>

      <img
        src="assets/home/comp.webp"
        // data-aos="zoom-in-up"
      />
    </div>
  );
}

export default Custombanner;
