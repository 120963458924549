import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Appemergingtec from "../components/Appemergingtec";
import Buildin from "../components/Buildin";
import Businesstaxsec from "../components/Businesstaxsec";
import Cta from "../components/Cta";
import CustomSubbanner from "../components/CustomSubbanner";
import Description from "../components/Description";
import Descriptionwh from "../components/Descriptionwh";
import Dynamiccontent from "../components/Dynamiccontent";
import Dynamiccontentwh from "../components/Dynamiccontentwh";
import Faq from "../components/Faq";
import Homeportfolio from "../components/Homeportfolio";
import Severalapp from "../components/Severalapp";
import "./Seo.scss";
function Seo() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  let banner = {
    img: "assets/banners/seo.webp",
    width: "700px",
    heading: "  Bringing You <span> The Sea of Organic Traffic </span>  ",

    para: "Guaranteeing 100% professional SEO Services to maximize organic growth rate!",
    bottom: "-250px",
    marginbottom: "250px",
    backgroundImg: `url('assets/appdevelopment/seobna.webp')`,
  };
  let webd = {
    title: "Tailored SEO Services across NYC",
    desc: `Providing a long-term Organic Search Engine Optimization Services to improve your website analytics and growth.`,
    arr: [
      "Quick Page Speed",
      "Quality Content ",
      "User-Friendly Multimedia",
      "Responsive UI/UX",
    ],
  };

  let portfolio = {
    title: "Tailored SEO Services across NYC",
    desc: "Discover how we have helped businesses with our Professional SEO Services and deliver customers beyond their expectations.",
  };
  // builtin feature

  let builtinfeature = {
    title: "Excellent Built-In Features",
    desc: "Our excellent SEO built-in features can enhance the performance of your website, such as:",
    arr: [
      "Quick Page Speed",
      "Mobile Friendliness",
      "URL and Links",
      "Optimized Content",
      "Technical SEO",
      "User Experience (Rank Brain)",
    ],
  };

  let cta = {
    one: "Ranking amongst the top ten blue ticks is no longer a problem!",
    two: "Get Intelligent SEO Specialists at Simboz!",
  };
  // ------
  let mdservice = {
    h1: "Website Growth Rate Increasing SEO Services at NYC",
    subtitle: "SEO Solutions Tailored To Your Specifications ",
    desc: `<p>To find the best Search Engine Optimization Company has become difficult these days. You never know, who to choose and where to get the professional SEO experts who will take your website on the lead competing the ranking brands. At Simboz, we create absolute solutions that are customize designed to take your business to the new heights.  </p>
        <p>It has been difficult and challenging to get tailored Organic Search Engine Optimization Services for your business, as there is already a huge competition in the SEO Market over the internet. Hundreds of Specialists over the Internet are playing are battling each other to rank on the top of their best and most searched keywords. </p>`,
  };

  let mds = {
    title: "Get Your Business Online",
    subtitle: "",
    desc: `<p>The majority of visitors use Google, Bing and yahoo to get services they are looking for and if they unable to find you through the relevant searching keywords, you are losing the biggest opportunity for a huge amount of crowd and audience that can be facilitated by your services to stand taller among the top ten blue ticks.</p>
        <p>With the help of our Best Search Engine Optimization Company, you can easily boost your active users’ rate and get lead amongst the top-ranking giants. Once, our SEO Agency facilitates you with our customize services, we will then accommodate you with more of our entertaining packages that are tailored on your business plan. </p>`,
  };

  let grow = {
    title: "Transform Your Traffic into Revenues ",
    subtitle: "",
    desc: `<p>It has been a huge issue over the cyber world towards SEO marketing. Even if you get the huge traffic running on your website with active users having excessive credibility, it is almost impossible to get the right way to transform your traffic into revenues. </p>
        <p>But it has been now solved, as hundreds of big names are available on the web to support your business with multiple strategies and respond your queries like 24/7. With the intelligent experts in our best Search Engine Optimization Company, we have the excellency to support you with 100% revenue generating SEO marketing solutions that are exclusively tailored for your business.  </p>
       `,
  };

  const faq = [
    [
      "<p>Why Choose Simboz as your SEO Agency?</p>",
      "<p>Simboz is the best SEO Agency to trust if you intend to build a successful SEO strategy or require Organic Search Engine Optimization Services. Your present SEO campaign that is giving you trouble? Simboz can help with that, too. </p>",
    ],

    [
      "<p>What are SEO services?</p>",
      `<p>Our Organic Search Engine Optimization Services is a type of digital SEO marketing services that boosts a company's visibility in online search results for specific keywords. These boosted rankings raise the company's profile in search results and attract targeted visitors. The Best SEO company truly deliver on their promises must incorporate both a tailored plan and a wide range of on-page, off-page, and technical SEO techniques.</p>
            `,
    ],

    [
      "<p>Can you send me a URL to the page where I can view the recent links you've established on my site?</p>",
      `<p>Sign into Google Search Console, navigate to "Traffic" on the left sidebar, and then click "Links to your site" to view the recent list of links we've built for your website. You can save your most-recently-visited websites from that page.</p>`,
    ],
    [
      "<p>My investigation into Google's keyword rankings for my focus areas did not confirm the findings of your research. Why?</p>",
      "<p>Each user's experience will be different from anybody else's, as search engine results will change on a daily basis and be completely personalized depending on their location and browsing history. We've made sure our reported results are accurate by clearing browser cache and using random IP addresses to mimic a new search.</p>",
    ],
    [
      "<p>When can I expect to witness SEO's effects?</p>",
      "<p>Website analysis is performed after a website has been built in order to fine-tune its contents. The site has been structured such that search engine spiders may easily index its information. You should expect to see results on SERP fairly quickly if your website was established recently, the keyword placement is done appropriately, and on-page and off-page SEO have been implemented. However, you need the best SEO company for the SEO marketing on your site; otherwise, you won't be able to climb up in the search engine results pages. If you get your website optimized by professionals, you should start seeing results within a few weeks.</p>",
    ],
  ];

  const data = {
    h1: "Our Process",
    p: `
         <p className='fp'>
         We put into action to process the professional SEO services in order to obtain the following benefits as a whole:
        </p>`,

    arr: [
      [
        `Audit  `,
        `  Finds websites linking to your competitors but not to you`,
        "assets/icon/SEO/Audit.png",
      ],

      [
        `Competitor Analysis`,
        `Sees what’s already working for others`,
        "assets/icon/SEO/CompetitorAnalysis.png",
      ],

      [
        `Setup Goals/ Strategies and KPIs `,
        ` Helps you evaluate success and determine if you need to make any adjustments`,
        "assets/icon/SEO/SetupGoalsStrategiesandKPIs.png",
      ],

      [
        `Site Structure   `,
        `Helps Google better crawl your site and determine subject matter`,
        "assets/icon/SEO/SiteStructure.png",
      ],

      [
        `Keyword Research  `,
        `Useful ideas to start prioritizing the content for writing`,
        "assets/icon/SEO/KeywordResearch.png",
      ],

      [
        `Improve On-page SEO  `,
        `Optimizes your web content for search engines and users`,
        "assets/icon/SEO/ImproveOn-pageSEO.png",
      ],

      [
        `Find and Fix Technical Issues   `,
        `Fixes site architecture, structured data, duplicate content, etc.`,
        "assets/icon/SEO/FindandFixTechnicalIssues.png",
      ],

      [
        `Work on off-page SEO/ Backlinks  `,
        `Actions taken outside your website that impact your rankings`,
        "assets/icon/SEO/Workonoff-pageSEO Backlinks.png",
      ],

      [
        `Measure Performance`,
        `Measure performance and bugs fixings with recent updates`,
        "assets/icon/SEO/MeasurePerformance.png",
      ],
    ],

    // p2: `With each of our clients, we establish a long-lasting relationship in which we grow to become their go-to resource for a wide range of daily business challenges. Contact us now.`
  };

  return (
    <div className="seo">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best SEO Marketing Agency in USA - SEO Service</title>
        <meta
          name="description"
          content="Guarenteeing long-term Solutions through our effective SEO Services by building organic results to improve your website analytics and growth. "
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />

        {/* ----------------------------------- */}

        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://simboz.com/assets/logos/logo.png"
        />
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://simboz.com/seo-service"
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Guarenteeing long-term Solutions through our effective SEO Services by building organic results to improve your website analytics and growth. "
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Best SEO Marketing Agency in USA - SEO Service"
        />
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Best SEO Marketing Agency in USA - SEO Service | Simboz "
        />
        <meta data-react-helmet="true" property="fb:app_id" content="Simboz" />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Guarenteeing long-term Solutions through our effective SEO Services by building organic results to improve your website analytics and growth. "
        />
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://simboz.com/seo-service"
        />
      </Helmet>

      <CustomSubbanner data={banner} />
      <Description data={webd} />
      <Homeportfolio portfolio={portfolio} />
      {/* <Appemergingtec data={builtinfeature} /> */}
      <Descriptionwh data={builtinfeature} />

      <Cta data={cta} />
      <Dynamiccontentwh data={mdservice} />
      <Dynamiccontent data={mds} />
      <Dynamiccontentwh data={grow} />

      <Businesstaxsec data={data} />
      {/* <Description data={process} /> */}
      <Severalapp />
      <Faq faq={faq} />
    </div>
  );
}

export default Seo;
