import React, { useEffect } from "react";
import "./Home.scss";
import Custombanner from "../components/Custombanner";
import Brancslider from "../components/Brancslider";
import Cta from "../components/Cta";
import Ourexpertise from "../components/Ourexpertise";
import Footerv1 from "../components/Footerv1";
import Buildapp from "../components/Buildapp";
import Erangle from "../components/Erangle";
import Homeportfolio from "../components/Homeportfolio";
import Certificate from "../components/Certificate";
import Leadexpertise from "../components/Leadexpertise";
import Dynamiccontentwh from "../components/Dynamiccontentwh";
import { Helmet } from "react-helmet";

function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  let portfolio = {
    title: "Discover Our Previous Works!",
    desc: "As technology partners, this web and mobile app development company is dedicated to a thorough understanding of your company sector in order to realize your product with the design and engineering that is optimal for you. Discover our previous works! ",
  };

  let cta = {
    one: "14 combining years of knowledge and the power of collaboration to address business obstacles with innovative, high-quality, and industry-wide consulting and deployment services.",
    two: "",
  };

  let cta2 = {
    one: "Intelligent websites that work 24/7 for effective marketing results to support your business.",
    two: "A diversified platform of developers that fulfills your requirements.",
  };
  let developwithus = {
    h2: "Why Develop with Us?",
    subtitle: "",
    desc: `<p>In order to generate the finest product for your organization, you require an advance team with a diversified skill-set for your web and app development. Our web and mobile app development company creates stable and futuristic apps based on agile concepts to assist clients in achieving faster time-to-market and adapting to a business environment that is always evolving. We can develop your web application and cloud infrastructure to support scalability by leveraging the benefits of microservices architecture - modular components - while keeping your dynamic traffic loads, performance, and business growth requirements in mind. By adopting a cloud-native architecture, you can quickly and efficiently adjust expenses and accelerate the value realization of your investments. As your web and mobile app development company, we can accommodate you in leveraging Cloud concepts like container-based architecture to create secure, robust, and adaptive web and app development that decrease long-term operational expenses. </p>
 
        `,
  };
  return (
    <div className="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leading web & Mobile App Development Company - Simboz</title>
        <meta
          name="description"
          content="End-to-End business digitalization transformation through our web and mobile app development company that facilitates high-performing Web solutions with innovative UI/UX experts and software developers."
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link rel="canonical" href="https://simboz.com/" />
      </Helmet>

      <Custombanner />
      {/* <Brancslider /> */}
      <Ourexpertise />

      <Cta data={cta} />
      <Dynamiccontentwh data={developwithus} />
      <Cta data={cta2} />
      <Leadexpertise />

      <Buildapp />
      <Homeportfolio portfolio={portfolio} />
      <Erangle image={"assets/home/erangle.webp"} />
      {/* <Certificate /> */}
    </div>
  );
}

export default Home;
