import React from "react";
import "./css/Footerv1.scss";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagramSquare,
  FaYoutube,
  FaPinterestP,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import ContactForm from "./ContactForm";

function Footerv1() {
  let togocontacfmob = () => {
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#contact").getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          50,
      });
    }, 100);
  };
  return (
    <div className="wrap">
      <ContactForm />
      <div className="Footerv1">
        <div className="Footerinner">
          <div className="v1remote">
            <div className="v1fd">
              <p className="v1h1">
                <span className="aare"> Are you</span>{" "}
                <span className="ready"> Ready?</span>
                <img src="/assets/arrow.png" onClick={togocontacfmob} />
              </p>
              <h3 className="v1h3">We develop your ideas into reality!</h3>
            </div>
            <div className="v12d">
              <Link to={"/"}>Home</Link>
              <Link to={"/about"}>About</Link>
              <Link to={"/portfolio"}>Portfolio</Link>
              <Link to={"/blog"}>Blog</Link>
            </div>
          </div>
          <div className="cont1">
            <div className="v1links">
              <ul className="v1slinks">
                <li className="v1icon">
                  <a
                    href="https://www.facebook.com/people/Simboz/100090278544937/?sk=about"
                    target={"_blank"}
                  >
                    <FaFacebookF />
                  </a>
                </li>

                <li className="v1icon">
                  <a
                    href="https://twitter.com/simbozofficial"
                    target={"_blank"}
                  >
                    <FaTwitter />
                  </a>
                </li>

                <li className="v1icon">
                  <a
                    href="https://www.pinterest.com/simbozofficial/"
                    target={"_blank"}
                  >
                    <FaPinterestP />
                  </a>
                </li>
                {/* <li className="v1icon">
                  <a href="#">
                    <FaLinkedinIn />
                  </a>
                </li>
                <li className="v1icon">
                  <a href="#">
                    <FaYoutube />
                  </a>
                </li> */}
              </ul>
              <ul className="v1cc">
                <li>Copyright © 2023, Simboz.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footerv1;
