import React, { useState } from "react";
import "./css/Portfoliosec.scss";
function Portfoliosec() {
  const [act, setAct] = useState("all");

  let data = [
    {
      brand: "Crest Logistics",
      desc: "We boom by staring at the latest market trends, insights, and reality. For us, no boundary is limited, no complex product difficult to outline, and no obstacle is too enormous. We push our limitations beyond & create a sophisticated solution through dynamic mobile apps and websites.",
      background: "assets/port/crestbanner.webp",
      img: "assets/port/creast.webp",
    },
    {
      brand: "Adamjee Durabuilt",
      desc: "We boom by staring at the latest market trends, insights, and reality. For us, no boundary is limited, no complex product difficult to outline, and no obstacle is too enormous. We push our limitations beyond & create a sophisticated solution through dynamic mobile apps and websites.",
      background: "assets/port/adamjee.webp",
      img: "assets/port/adamje.webp",
    },
    {
      brand: "Clearstone Group",
      desc: "We boom by staring at the latest market trends, insights, and reality. For us, no boundary is limited, no complex product difficult to outline, and no obstacle is too enormous. We push our limitations beyond & create a sophisticated solution through dynamic mobile apps and websites.",
      background: "assets/port/clear.webp",
      img: "assets/port/clears.webp",
    },
    {
      brand: "Synergy Corporation",
      desc: "We boom by staring at the latest market trends, insights, and reality. For us, no boundary is limited, no complex product difficult to outline, and no obstacle is too enormous. We push our limitations beyond & create a sophisticated solution through dynamic mobile apps and websites.",
      background: "assets/port/syner.webp",
      img: "assets/port/synergy.webp",
    },
  ];
  return (
    <div className="portfoliosec">
      <div className="inner">
        {/* <div className='filter'>
                    <button onClick={() => { setAct('all') }} className={act == 'all' ? 'active' : 'inactive'}>All</button>
                    <button onClick={() => { setAct('web') }} className={act == 'web' ? 'active' : 'inactive'}>Web</button>
                    <button onClick={() => { setAct('ios') }} className={act == 'ios' ? 'active' : 'inactive'}>IOS</button>
                    <button onClick={() => { setAct('android') }} className={act == 'android' ? 'active' : 'inactive'}>Android</button>
                </div> */}

        <div className="portfoliosecinner">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="wraper"
                style={{ backgroundImage: `url(${item.background})` }}
              >
                <div className={index % 2 == 0 ? "inner" : "innerreverse"}>
                  <div className="left">
                    <h1>{item.brand}</h1>
                    <p>{item.desc}</p>
                    {/* <button>View More</button> */}
                  </div>
                  <div className="right">
                    <img src={item.img} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Portfoliosec;
