import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Appemergingtec from "../components/Appemergingtec";
import Buildin from "../components/Buildin";
import Businesstaxsec from "../components/Businesstaxsec";
import Cta from "../components/Cta";
import CustomSubbanner from "../components/CustomSubbanner";
import Description from "../components/Description";
import Descriptionwh from "../components/Descriptionwh";
import Dynamiccontent from "../components/Dynamiccontent";
import Dynamiccontentwh from "../components/Dynamiccontentwh";
import Faq from "../components/Faq";
import Homeportfolio from "../components/Homeportfolio";
import Severalapp from "../components/Severalapp";
import Webtec from "../components/Webtec";

import "./Mobileappdevelopmentservice.scss";
function Mobileappdevelopmentservice() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  let banner = {
    img: "assets/banners/mobile.webp",
    width: "400px",
    heading:
      "Feature-Rich<span> Mobile Application Development </span>  Solutions",

    para: "Delight your users with feature-rich mobile application development solutions that leverage our comprehensive understanding with all core technologies.",
    bottom: "-150px",
    marginbottom: "250px",
    backgroundImg: `url('assets/\appdevelopment/mobban.webp')`,
  };
  let webd = {
    title: "Robust and User-Friendly Mobile Application Development",
    desc: ``,
    arr: [
      "Give Life to your Idea",
      "Attractive & user-friendly ",
      "On Specification ",
      "Competitive price ",
      "Multiple payment options",
      "Most-recent frameworks & tools",
    ],
  };

  let portfolio = {
    title: "Our Work Experience",
    desc: `We proudly showcase the prior work we've done and helped many businesses get their attractive mobile app!`,
  };

  // builtin feature

  let builtinfeature = {
    title: "Fully Integrated & Optimized Build-In Features ",
    desc: "Our fully integrated and optimized build-in features can enhance the performance of your web and app development, such as:",
    arr: [
      "Virtual DOM",
      "Model View Controller ",
      "Declarative UI",
      "JavaScript XML ",
      "One-way Data Binding",
      "Speed, efficiency & Flexibility",
    ],
  };

  let cta = {
    one: "Our workflow and strategies allow you to stay at the top of rivalry!",
    two: `Get instant mobile app developers at Simboz!`,
  };

  let mdservice = {
    h1: "High-End IOS & Android Mobile App Development Services",
    subtitle: "We Give Life to Your Idea",
    desc: `<p>As a well-recognized Mobile and Application Development Company in NYC, we have a comprehensive team of developers and designers who bring life to your idea by launching successful and scalable mobile apps tailored with innovative functionality that sync the customer's requirements. </p>
            <p>At Simboz, we cater to every sized business with innovative and future success mobile applications. </p>`,
  };

  let mds = {
    title: "Up-To-Date Features with Modern Technologies ",
    subtitle: "",
    desc: `<p>Simboz has the technical tools and experienced team to customize every feature and create market-leading innovative solutions, connect standard-essential technologies, and develop architecture to get the final initiative mobile applications.</p>
        <p>Our team is experts in developing both IOS & Android Applications on different programming languages: React Native, Xamarin, Flutter and Dart; and making sure it runs smoothly on all mobile devices. </p>
       `,
  };

  let grow = {
    title: "According to your Specifications and Demands",
    subtitle: "",
    desc: `<p>Without compromising on technology, our trained and experienced teams built the app that our customers absolutely love because app functionality is designed as per demand.</p>
        <p>Our team specializes in developing futuristic PWAs using their ideal frameworks such as Ionic, Polymer, AngularJS, and React PWA library, ensuring that they don’t depend on single API and deliver the best possible web experience. </p>
        <p>Our experienced app developers are passionate about delivering impeccable applications; each functionality is customized to requirements and beneficial for both customers and their users.</p>`,
  };

  const faq = [
    [
      "<p>Why Simboz as Your Mobile Application Development Company?</p>",
      "<p>Get custom services from Simboz mobile app development company at a competitive price and unlock your true business potential. As a mobile app development company in the US we provide high quality mobile app development services without burning your pocket.</p>",
    ],

    [
      "<p>What is the total cost of mobile app development?</p>",
      "<p>It depends on the complexity of the project as its features, technologies that used, and the quality and creativity of the mobile app development.</p>",
    ],

    [
      "<p>What if I don’t like mobile app development or what if the designing and contents are not provided as I recommended?</p>",
      "<p>Simboz mobile application development company has the technical tools and experienced team, they design in that way which fulfills your objectives, and if you don’t like we will design on another way until you get complete satisfaction.</p>",
    ],
    [
      "<p>After launching the mobile app what if I get the errors or the app crashed?</p>",
      "<p>First thing is that we don’t launch the mobile app unless we testify all the programs and make surety that the mobile app will not create any hectic or simple problem. But even if there is an issue or crash – you have our backup team supporting all the crashes and problems raising on your mobile app for three months, until then your mobile app will start running smoothly.</p>",
    ],
    [
      "<p>Do you provide me the app content or I have to make my own?</p>",
      "<p>Yes, we do! But it’s up to you… If you don’t have the text of your mobile application & want to add impressive mobile application contents – texts, we are here to give you our quality content services as well.</p>",
    ],
  ];

  const data = {
    h1: "Our Process ",
    p: `
         <p className='fp'>
         For developing intriguing mobile apps, we use a proactive approach and agile development process. We affix ourselves from ideation to delivery and ongoing support as well.
        </p>`,

    arr: [
      [
        `Project Understanding`,
        `  Understanding your project brief `,
        "assets/icon/Mobile/ProjectUnderstanding.png",
      ],

      [
        `Mobile Application Development Team`,
        `Assigns you an expert team to work throughout your entire project`,
        "assets/icon/Mobile/MobileApplicationDevelopmentTeam.png",
      ],

      [
        `Designing  `,
        ` Creating architecture, selecting brand colors and themes to create attractive UI for each page`,
        "assets/icon/Mobile/Designing.png",
      ],

      [
        `Development `,
        `We do more than code to develop strong security features`,
        "assets/icon/Mobile/Development.png",
      ],

      [
        `Launch  `,
        `Keeping your quality assurance, we launch your mobile app to the Microsoft, Android, and iOS stores using your respective web domain`,
        "assets/icon/Mobile/Launch.png",
      ],

      [
        `Maintenance  `,
        `Maintain and fix bugs with recent updates`,
        "assets/icon/Mobile/Maintinance.png",
      ],
    ],

    // p2: `With each of our clients, we establish a long-lasting relationship in which we grow to become their go-to resource for a wide range of daily business challenges. Contact us now.`
  };

  return (
    <div className="Mobileappdevelopmentservice">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mobile Application Development Service Providing Company</title>
        <meta
          name="description"
          content="Being a mobile app development company, Simboz offers fully-featured customized modern stack engineering apps with the latest user experience."
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        {/* ---------- */}
        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://simboz.com/assets/logos/logo.png"
        />
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://simboz.com/mobile-app-development-service"
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Being a mobile app development company, Simboz offers fully-featured customized modern stack engineering apps with the latest user experience."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Mobile Application Development Service Providing Company"
        />
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Mobile Application Development Service Providing Company | Simboz "
        />
        <meta data-react-helmet="true" property="fb:app_id" content="Simboz" />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Being a mobile app development company, Simboz offers fully-featured customized modern stack engineering apps with the latest user experience."
        />
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://simboz.com/mobile-app-development-service"
        />
      </Helmet>
      <CustomSubbanner data={banner} />
      <Description data={webd} />
      <Homeportfolio portfolio={portfolio} />
      <Descriptionwh data={builtinfeature} />
      <Cta data={cta} />
      <Dynamiccontentwh data={mdservice} />
      <Dynamiccontent data={mds} />
      <Dynamiccontentwh data={grow} />

      <Businesstaxsec data={data} />
      {/* <Description data={process} /> */}

      {/* <Appemergingtec data={emmertec} /> */}
      <Severalapp />
      <Faq faq={faq} />
    </div>
  );
}

export default Mobileappdevelopmentservice;
