import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import "./css/Homeportfolio.scss";
function Homeportfolio({ portfolio }) {
  let data = [
    {
      brand: "Synergy Corporation",
      para: `We boom by staring at the latest market trends, insights, and reality. For us, no boundary is limited, no complex product difficult to outline, and no obstacle is too enormous. We push our limitations beyond & create a sophisticated solution through dynamic mobile apps and websites.

      `,
      btn: "View More",
      tech: ["Wordpress", "Javascript", "PHP", "Laravel"],
      image: "assets/home/synergy.webp",
    },

    {
      brand: "Adamjee Durabuilt      ",
      para: `We boom by staring at the latest market trends, insights, and reality. For us, no boundary is limited, no complex product difficult to outline, and no obstacle is too enormous. We push our limitations beyond & create a sophisticated solution through dynamic mobile apps and websites.

      `,
      btn: "View More",
      tech: ["React", "Javascript", "Laravel", "PHP"],
      image: "assets/home/adamjee.webp",
    },
  ];
  return (
    <>
      <div className="Homeportfolio">
        <div className="topinner">
          <h3 className="h1">{portfolio.title}</h3>
          <p>{portfolio.desc}</p>
        </div>

        <div className="portwraper">
          {data.map((item, index) => {
            return (
              <div className="box" key={index}>
                <div className="letftbox">
                  <h4 className="h1">{item.brand}</h4>
                  <p>{item.para}</p>
                  {/* <button>{item.btn}</button> */}
                  <ul>
                    {item.tech.map((item, index) => {
                      return <li key={index}>{item}</li>;
                    })}
                  </ul>
                </div>

                <div className="righttbox">
                  <img src={item.image} />
                </div>
              </div>
            );
          })}
        </div>
        <div className="btnwrapper">
          <button className="button">
            <Link to={"/portfolio"}>View More</Link>
          </button>
        </div>
      </div>
    </>
  );
}

export default Homeportfolio;
