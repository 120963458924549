import React from "react";
import "./css/Insig.scss";
import CountUp from "react-countup";

function Insig() {
  return (
    <div className="wrapins">
      <div className="clients">
        <div className="pri">
          <div className="fle">
            <CountUp duration={1.5} start={0} end={780} className="cup" />
            <p>+</p>
          </div>
          <p>Projects</p>
        </div>
        <div className="sec">
          <div className="fle">
            <CountUp duration={1.5} start={0} end={87} className="cup" />
            <p>+</p>
          </div>
          <p>Team</p>
        </div>

        <div className="sec">
          <div className="fle">
            <CountUp duration={1.5} start={0} end={227} className="cup" />
            <p>+</p>
          </div>
          <p>Client</p>
        </div>

        <div className="sec">
          <div className="fle">
            <CountUp duration={1.5} start={0} end={65} className="cup" />
            <p>%</p>
          </div>
          <p>Return Clients </p>
        </div>
      </div>
    </div>
  );
}

export default Insig;
