import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Appemergingtec from "../components/Appemergingtec";
import Buildin from "../components/Buildin";
import Businesstaxsec from "../components/Businesstaxsec";
import Cta from "../components/Cta";
import CustomSubbanner from "../components/CustomSubbanner";
import Description from "../components/Description";
import Descriptionwh from "../components/Descriptionwh";
import Dynamiccontent from "../components/Dynamiccontent";
import Dynamiccontentwh from "../components/Dynamiccontentwh";
import Faq from "../components/Faq";
import Homeportfolio from "../components/Homeportfolio";
import Severalapp from "../components/Severalapp";
import Webtec from "../components/Webtec";

import "./Magentodevelopmentservice.scss";

const Magentodevelopmentservice = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  let banner = {
    img: "assets/banners/magento.webp",
    width: "700px",
    heading:
      " Certified <span> Genius  </span> Team of <span> MAGENTO  </span>",

    para: "Magento experts uplift your e-commerce business, satisfy customers, and generate extensive revenues.",
    bottom: "-250px",
    marginbottom: "250px",
    backgroundImg: `url('assets/appdevelopment/magban.webp')`,
  };
  let webd = {
    title: "Magento Developers from NYC",
    desc: "While living in the early-adaptive New York city, as a Magento Development Company, we develop secured ecommerce store more than our customer’s expectations keeping the project’s brief. ",
    arr: [
      "Strong security features",
      "Quick page speed ",
      "Stunning themes",
      "Multiple payment options",
      "Use PHP future-promising frameworks",
    ],
  };

  let portfolio = {
    title: "Our Work Experience",
    desc: "Discover how our Magento Agency has helped businesses with our Magento development services and deliver customers beyond their expectations.",
  };
  // builtin feature

  let builtinfeature = {
    title: "Excellent Build-In Features ",
    desc: "Our excellent build-in features can enhance the performance of your e-commerce site, such as:",
    arr: [
      "Search Engine Optimization",
      "Conversion Rate Optimization",
      "Website Copywriting ",
      "Shopping Feed Management ",
      "Data Tracking & Analysis",
    ],
  };

  let cta = {
    one: "Enlist yourself amongst the world’s top-ranking ecommerce stores!",
    two: "Get Intelligent Magento Developers at Simboz!",
  };

  let mdservice = {
    h1: "Magento Development Services across the USA",
    subtitle: "We Fulfill your Specifications",
    desc: `<p>As a Magento development Company in NYC, we fulfill each customer's specifications while keeping your project’s brief in mind, no matter how complex your objective may raise. Whether it's customization or migration, a theme or design, our developers can do it all. </p>
            <p>We take pride in expanding your business on a large scale with our knowledgeable Magento developers, and the services we provide varies from the most imaginative Magento ecommerce development store design to relatively specialized work with one-of-a-kind insights.</p>
        <p>We execute a user-friendly eCommerce Magento web development and provide you the leverage to achieve remarkable success in the world of eCommerce. This includes everything from building a well-designed Magento store to its management and maintenance. </p>`,
  };

  let mds = {
    title: "Our cutting-Edge Tech-n-tools Give Life to your Project",
    subtitle: "",
    desc: `<p>Our developers at Simboz Magento Development Company are accustomed to accomplish all of your goals because of our in-depth knowledge of the Magento programming language as such PHP and its respective frameworks, Laminas (previously known as Zend) and Symfony. These frameworks allow us to take care of all factors that includes performance, extensibility, and security.  </p>
        <p>As a Magento Agency, we are constantly working to improve the functionality of the store and optimize it in MySQL and MariaDB relational database management systems in order to provide you with excellent Magento Ecommerce Development services that will exceed your expectations.  </p>
        <p>Our experienced team of Magento developers implement the most cutting-edge functionalities in accordance with the requirements outlined by the client, regardless of the technological constraints and limitations. </p>`,
  };

  let grow = {
    title: "Grow your Business with Magento web Development Solutions",
    subtitle: "",
    desc: `<p>You will easily grow your online business with the solutions that our Magento Agency provides, through the highly excellent skills and domain knowledge of our develops and designers in all the fields of designing, development, and maintenance sectors of the ecommerce industry. </p>
        <p>Whether you are a newly designed startup, a mid-sized corporation, or an established enterprise; we provide successful results that are customer-centered, and future oriented.</p>
        <p>Simboz offers the most up-to-date technical tools with an experienced staff ensuring that they can handle any kind of Magento development that are needed, regardless of what your project requires.</p>
        `,
  };

  const faq = [
    [
      "<p>Why choose Magento for your ecommerce platform?</p>",
      "<p>Magento facilitates you the advanced functionality with an application style platform that enables you to download, install as well as customize the whole store using the PHP. It offers inventory management, accounting, and business reporting as well as enhances sales and social media promotion. It offers an easy way to quickly launch an ecommerce store without the fuss over multiple programming languages and development costs. Since all the features are logically structured, the admin interface is clean and user-friendly.</p>",
    ],

    [
      "<p>What is the total cost of Magento web development?</p>",
      "<p>It depends on the complexity of the project as its features, technologies that used, and the quality and creativity to provide the ecommerce Magento web development services.</p>",
    ],

    [
      "<p>What if I don’t like the upgraded Magento development services or what if the designing and contents are not provided as I recommended?</p>",
      "<p>Using PHP and is leveraging elements of the Zend framework and the model-view-controller architecture development professionals design in that way which makes you happy, and if you don’t like, we will design on another way until you get complete satisfaction.</p>",
    ],
    [
      "<p>After launching the Magento development code what if I get the errors or the store crashed?</p>",
      "<p>First thing is that we don’t launch the Magento store randomly, unless we testify all the programs and make surety that there is no crash. But even if there is a bug or crash – you have our backup team supporting all the crashes popping-up for three months, until then your store will start running smoothly.</p>",
    ],
    [
      "<p>Do you provide me the Store content or I have to make my own?</p>",
      "<p>Yes, we do! But it’s up to you… If you don’t have the text for your Magento web & want to add impressive Magento ecommerce contents – texts, we are here to fulfill your every desire in one click.</p>",
    ],
  ];

  const data = {
    h1: "Our Process",
    p: `
         <p className='fp'>
         At Simboz, our Magento Agency puts into action to process the desired Magento development in order to obtain the following benefits as a whole:
        </p>`,

    arr: [
      [
        `Planning `,
        ` Plan after understanding the project brief`,
        "assets/icon/MAGENTO/Planning.png",
      ],

      [
        `Magento Team`,
        `Assign you a specified team throughout the entire development`,
        "assets/icon/MAGENTO/MagentoTeam.png",
      ],

      [
        `Designing UI/UX  `,
        ` Create catchy and user-friendly UI for each page`,
        "assets/icon/MAGENTO/DesigningUIUX.png",
      ],

      [
        `Development  `,
        `Develop each page as per specifications`,
        "assets/icon/MAGENTO/Development.png",
      ],

      [
        `Launch  `,
        `Keeping your quality assurance, we launch Magento store on your domain`,
        "assets/icon/MAGENTO/Launch.png",
      ],

      [
        `Maintenance  `,
        `Maintain and fix bugs with recent updates`,
        "assets/icon/MAGENTO/Maintenance.png",
      ],
    ],

    // p2: `With each of our clients, we establish a long-lasting relationship in which we grow to become their go-to resource for a wide range of daily business challenges. Contact us now.`
  };

  return (
    <div className="Magentodevelopmentservice">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Magento Development Service Providing Company</title>
        <meta
          name="description"
          content="Grow your business with Magento ecommerce store; we develop a customer-oriented store by customizing with PHP, along with its frameworks: Laminas and Symfony."
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />

        {/* ----------------- */}

        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://simboz.com/assets/logos/logo.png"
        />
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://simboz.com/magento-development-service"
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Grow your business with Magento ecommerce store; we develop a customer-oriented store by customizing with PHP, along with its frameworks: Laminas and Symfony."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Magento Development Service Providing Company"
        />
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Magento Development Service Providing Company | Simboz "
        />
        <meta data-react-helmet="true" property="fb:app_id" content="Simboz" />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Grow your business with Magento ecommerce store; we develop a customer-oriented store by customizing with PHP, along with its frameworks: Laminas and Symfony."
        />
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://simboz.com/magento-development-service"
        />
      </Helmet>

      <CustomSubbanner data={banner} />
      <Description data={webd} />
      <Homeportfolio portfolio={portfolio} />
      {/* <Appemergingtec data={builtinfeature} /> */}
      <Descriptionwh data={builtinfeature} />
      <Cta data={cta} />
      <Dynamiccontentwh data={mdservice} />
      <Dynamiccontent data={mds} />
      <Dynamiccontentwh data={grow} />
      <Businesstaxsec data={data} />

      {/* <Description data={process} /> */}
      {/* <Appemergingtec data={emmertec} /> */}
      <Severalapp />
      <Faq faq={faq} />
    </div>
  );
};

export default Magentodevelopmentservice;
