import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Appemergingtec from "../components/Appemergingtec";
import Buildin from "../components/Buildin";
import Businesstaxsec from "../components/Businesstaxsec";
import Cta from "../components/Cta";
import CustomSubbanner from "../components/CustomSubbanner";
import Description from "../components/Description";
import Descriptionwh from "../components/Descriptionwh";
import Dynamiccontent from "../components/Dynamiccontent";
import Dynamiccontentwh from "../components/Dynamiccontentwh";
import Faq from "../components/Faq";
import Homeportfolio from "../components/Homeportfolio";
import Severalapp from "../components/Severalapp";
import "./Smo.scss";
function Smo() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  let banner = {
    img: "assets/banners/smo.webp",
    width: "700px",
    heading: " Increasing <span> Your Brand Awareness</span>  ",
    para: "Strengthening your brand awareness across the most prominent Social Medias!",
    bottom: "-250px",
    marginbottom: "250px",
    backgroundImg: `url('assets/appdevelopment/gidim.webp')`,
  };
  let webd = {
    title: "Social Media Optimization Services in NYC",
    desc: `With the help of Social Media Optimization, we accommodate in establishing a trustworthy brand image in the social media market and drawing a substantial number of new visitors to the website.`,
    arr: [
      "Strategic Planning",
      "Drive Quality Traffic",
      "Higher Probability",
      "Secure Payment ",
    ],
  };

  let portfolio = {
    title: "Our Work Experience",
    desc: "Discover how we have helped businesses with our Professional SMO Services and delivered customers beyond their expectations.",
  };
  // builtin feature

  let builtinfeature = {
    title: "Excellent Built-In Features",
    desc: "Our excellent SMO built-in features can enhance the performance of your website, such as:",
    arr: [
      "Brand Recognition",
      "Improved ROI",
      "Strong Web presence",
      "Quality Content",
      "Engaging Designs ",
      "Measured campaign results",
    ],
  };

  let cta = {
    one: "Exclusively tailored tips to follow for Social Media Optimization!",
    two: "Get SMO specialists at Simboz! ",
  };
  // ------
  let mdservice = {
    h1: "Benefits of SMO in Digital Marketing at Simboz ",
    subtitle: "Credible Brand Identity",
    desc: `<p>In addition to assisting you in producing leads, Simboz Social Media Optimization Services may help you to develop a robust web presence, increase brand awareness, drive a considerable number of visitors, and improve your ranking in search engines.</p>
        <p>With the assistance of our efficient, advanced, and most recent SMO services, you will be able to raise awareness of your brand, products, and services while also promoting your website and growing the quantity of traffic that website receives.</p>
        <p>We conduct an in-depth analysis of the customer's website and do everything in our power to guarantee a distinct and rapid online presence for the customer and assist with a fully organized and working SMO in digital marketing platform bringing leads. </p>
        `,
  };

  let mds = {
    title: "Improved Results in Short Duration",
    subtitle: "",
    desc: `<p>With great pleasure, we share with you the facts that Simboz is committed to providing efficient Social Media Optimization Services to bring improved results in a shorter amount of time compared to the amount of time it takes to bring products to market. </p>
        <p>This ought to pique your attention towards SMO in digital marketing if you are interested in improving productivity and decreasing time wastage, both of which are amongst our important priorities, and this is because both of these things are among our key priorities. </p>
        <p>We meet all of the timeframes in order to provide your business with results that can be measured and are advantageous. With our aid, achieving the long-term targets and objectives that your organization has set for itself will no longer be an impossible task. </p>
        <p>On the other hand, we will assist you in accomplishing all of your goals in the quickest and easiest method possible by cooperating closely with you to achieve these goals.</p>
        `,
  };

  let grow = {
    title: "Social Medias Audit",
    subtitle: "",
    desc: `<p>Through the utilization of our Social Media Optimization Services, you will be able to improve the overall quality of your social media accounts, which will, in turn, result in an increase in the volume of traffic that is directed to your website. </p>
        <p>Greater awareness of the availability of the products in question can be achieved through the utilization of a promotional campaign for those products. A comprehensive social media audit is performed by us, a leading SMO in Digital Marketing firm in NYC.</p>
        <p>This audit includes an analysis of click-through rates (CTRs), user behavior, and engagement rates. The goal of this audit is to target new consumers and increase your business scalability.</p>
       `,
  };

  const faq = [
    [
      "<p>What does SMO stand for and what is its use?</p>",
      "<p>To increases a website's exposure across all digital channels, including social media, it must be optimized for these channels using a process known as Social Media Optimization, or SMO. By adjusting to new methods, you can raise your brand's profile on Social Medias and, in turn, generate more leads.</p>",
    ],

    [
      "<p>What role will SMO play in helping me progress my brand?</p>",
      `<p>Social media optimization (SMO) is now crucial in assessing the online performance of a company. The following advice will help you learn how to use SMO in digital marketing to grow your company:</p>
            <ul>
            <li>You can't succeed in the search engine rankings without using keywords, and doing so will allow you to reach a far larger audience. Taking care of the organization and use of those specific keywords in your social media profiles can help you stand out in search results.</li>
            <li>SMO in Digital Marketing works well if you know who you're trying to reach. For Social Media Optimization to be successful, it is necessary to conduct extensive research into the demographics of interest to your company. If you want to identify your brand and raise awareness of it within your target demographic, you need to create material that they will find interesting and share it across all of their social media platforms.</li>
            <li>Search Engine Optimization (SEO) is the key to a successful SMO campaign. Our Social Media Optimization Services integrates effective infographics in conjunction with written material, video, etc., is one method of accomplishing this. This material needs to have a concise, snappy title that also includes keywords that people actually use to find it.</;>
            </ul>
            `,
    ],

    [
      "<p>In SMO, why are hashtags “#” so necessary and important?</p>",
      `<p>Currently, more than 95% of firms use social media for marketing purposes. If you're looking for content related to a specific topic, hashtags are a must-have. When posting to social networking sites, hashtags should always be taken into account. It is essential to use hashtags to improve content for discoverability across social media and search engines.</p>
            <p>Hashtags that are currently trending can do wonders for your brand's exposure and discoverability online.</p>`,
    ],
    [
      "<p>Why should I work with an SMO Agency?</p>",
      "<p>When you hire the services of a skilled SMO Agency, you can count on excellent results. Digital Marketing Agencies like Simboz have a Social Media Staff with extensive experience and knowledge in this area.</p> <p>They look into your industry, your competitors, and your business in comprehensive details. So, they tailor a social media plan to your company's needs. A Social Media Optimization (SMO) specialist will ensure that your various social media channels are always filled with interesting and relevant material. Using an SMO agency can save you time and money because they are experts in promoting your brand on social medias.</p>",
    ],
  ];

  const data = {
    h1: "Our Process",
    p: `
         <p className='fp'>
         We put into action to process the SEO services in order to obtain the following benefits as a whole:
        </p>`,

    arr: [
      [
        `Brand  `,
        ` Understand your brand personality`,
        "assets/icon/MAGENTO/Planning.png",
      ],

      [
        `SMM Team `,
        `Assign you a team throughout the entire process`,
        "assets/icon/MAGENTO/MagentoTeam.png",
      ],

      [
        `Market  `,
        ` Create Brand Awareness in the Market`,
        "assets/icon/MAGENTO/DesigningUIUX.png",
      ],

      [
        `Social Media Platform   `,
        `Work on all Social Medias to bring lead on the website`,
        "assets/icon/MAGENTO/Development.png",
      ],

      [
        `Trend Analysis  `,
        `Complete analysis of the trend to create ads and videos accordingly`,
        "assets/icon/MAGENTO/Launch.png",
      ],

      [
        `Audience   `,
        ` Target audience according to your brand specifications`,
        "assets/icon/MAGENTO/Maintenance.png",
      ],

      [
        `Metrics    `,
        ` Bring positive metrics`,
        "assets/icon/MAGENTO/Maintenance.png",
      ],

      [
        `Adapt, learn and grow   `,
        `  Following the success ratio, we enhance our strategies with time and grow your business`,
        "assets/icon/MAGENTO/Maintenance.png",
      ],
    ],

    // p2: `With each of our clients, we establish a long-lasting relationship in which we grow to become their go-to resource for a wide range of daily business challenges. Contact us now.`
  };

  return (
    <div className="smo">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Social Media Optimization Services - SMO Service</title>
        <meta
          name="description"
          content="Establishing a trustworthy brand image with tailored Social Media Optimization Services to draw a substantial number of new visitors to the website."
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />

        {/* ------- */}

        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://simboz.com/assets/logos/logo.png"
        />
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://simboz.com/smo-service"
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Establishing a trustworthy brand image with tailored Social Media Optimization Services to draw a substantial number of new visitors to the website."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Social Media Optimization Services - SMO Service"
        />
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Social Media Optimization Services - SMO Service | Simboz "
        />
        <meta data-react-helmet="true" property="fb:app_id" content="Simboz" />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Establishing a trustworthy brand image with tailored Social Media Optimization Services to draw a substantial number of new visitors to the website."
        />
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://simboz.com/smo-service"
        />
      </Helmet>

      <CustomSubbanner data={banner} />
      <Description data={webd} />
      <Homeportfolio portfolio={portfolio} />
      {/* <Appemergingtec data={builtinfeature} /> */}
      <Descriptionwh data={builtinfeature} />

      <Cta data={cta} />
      <Dynamiccontentwh data={mdservice} />
      <Dynamiccontent data={mds} />
      <Dynamiccontentwh data={grow} />

      <Businesstaxsec data={data} />
      {/* <Description data={process} /> */}
      <Severalapp />
      <Faq faq={faq} />
    </div>
  );
}

export default Smo;
