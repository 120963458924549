import React from 'react'
import Appcta from '../components/Appcta'
import CustomSubbanner from '../components/CustomSubbanner'
import Dynamiccontent from '../components/Dynamiccontent'
import Dynamiccontentwh from '../components/Dynamiccontentwh'
import Magentolearn from '../components/Magentolearn'
import Magentoservice from '../components/Magentoservice'
import './Magento.scss'

function Magento() {

    let banner = {
        img: 'assets/magento/25.png',
        width: '700px',
        heading: ' Certified <span> Genius  </span> Team of <span> MAGENTO  </span>',

        para: 'Magento experts uplift your e-commerce business, satisfy customers, and generate extensive revenues.',
        bottom: '-250px',
        marginbottom: '250px',
        backgroundImg: `url('assets/appdevelopment/appbanner.png')`
    }
    let mdservice = {
        title: 'MAGENTO DEVELOPMENT SERVICES ACROSS THE USA',
        subtitle: 'We Fulfill your Specifications',
        desc: `<p>We fulfill each customer's specifications while keeping your project’s brief in mind, no matter how complex your objective may raise.Whether it's customization or migration, a theme or design, our developers can do it all. </p>
            <p>We take pride in expanding your business on a large scale with our knowledgeable Magento developers, and the services we provide varies from the most imaginative Magento ecommerce store design to relatively specialized work with one - of - a - kind insights.</p>
        <p>We execute a user-friendly e-commerce Magento store and provide you the leverage to achieve remarkable success in the world of e-commerce. This includes everything from building a well-designed Magento store to its management and maintenance. </p>`

    }

    let mds = {
        title: 'Our cutting-Edge Tech-n-tools Give Life to your Project',
        subtitle: '',
        desc: `<p>Our developers are accustomed to accomplish all of your goals because of our in-depth knowledge of the Magento programming language as such PHP and its respective frameworks, Laminas (previously known as Zend) and Symfony. These frameworks allow us to take care of all factors that includes performance, extensibility, and security. </p>
        <p>We are constantly working to improve the functionality of the store and optimize it in MySQL and MariaDB relational database management systems in order to provide you with excellent ecommerce development services that will exceed your priorities. </p>
        <p>Our experienced team of Magento developers implement the most cutting-edge functionalities in accordance with the requirements outlined by the client, regardless of the technological constraints and limitations. </p>`
    }

    let grow = {
        title: 'Grow your Business with Magento Development Solutions',
        subtitle: '',
        desc: `<p>You will easily grow your online business with the assistance of Magento development solutions, which can be provided by our Magento developers who are highly skilled in the designing, development, and maintenance sectors of the ecommerce industry. </p>
        <p>Whether you are a newly designed startup, a mid-sized corporation, or an established enterprise; we provide successful results that are customer-centered, and future oriented.</p><p>Simboz offers the most up-to-date technical tools with an experienced staff ensuring that they can handle any kind of Magento development that are needed, regardless of what your project requires.</p>`
    }
    return (

        <div className='magento'>
            <CustomSubbanner data={banner} />
            <Magentoservice />
            <Appcta />
            <Magentolearn />
            <Dynamiccontentwh data={mdservice} />
            <Dynamiccontent data={mds} />
            <Dynamiccontentwh data={grow} />
            <Dynamiccontent data={mds} />

        </div>
    )

}

export default Magento
