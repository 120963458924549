import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Appemergingtec from "../components/Appemergingtec";
import Buildin from "../components/Buildin";
import Businesstaxsec from "../components/Businesstaxsec";
import Cta from "../components/Cta";
import CustomSubbanner from "../components/CustomSubbanner";
import Description from "../components/Description";
import Descriptionwh from "../components/Descriptionwh";
import Dynamiccontent from "../components/Dynamiccontent";
import Dynamiccontentwh from "../components/Dynamiccontentwh";
import Faq from "../components/Faq";
import Homeportfolio from "../components/Homeportfolio";
import Severalapp from "../components/Severalapp";
import Webtec from "../components/Webtec";
import "./Smm.scss";

function Smm() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  let banner = {
    img: "assets/banners/22.webp",
    width: "700px",
    heading:
      "    Top Performing <span> Social Media Marketing </span>  Campaigns",
    para: "Targeting specific audience to get the positive content impressions, clicks, and lead generation.",
    bottom: "-250px",
    marginbottom: "250px",
    backgroundImg: `url('assets/appdevelopment/smm.webp')`,
  };
  let webd = {
    h1: "Social Media Marketing Services at NYC",
    desc: `We provide tailored recommendations to enhance your business, and assist in locating and engaging with your ideal clientele to generate tangible revenues. `,
    arr: [
      "Get analytics audit",
      "Marketing Automation",
      "Enjoy Popularity",
      "Guaranteed Services",
      "Secure Payment ",
    ],
  };

  let portfolio = {
    title: "Our Work Experience",
    desc: "Discover how we have helped businesses with our Professional SEO Services and delivered customers beyond their expectations.",
  };
  // builtin feature

  let builtinfeature = {
    title: "Excellent Built-In Features",
    desc: "Our excellent SEO built-in features can enhance the performance of your website, such as:",
    arr: [
      "Customer Feedback",
      "Right Audience Target",
      "Increasing trust",
      "Increasing Brand Awareness",
      "Connecting With Clients",
      "Increasing Website Traffic",
    ],
  };

  let cta = {
    one: "People love a great customer service that never leave them unanswered!",
    two: "Grow your business with effective SMM Experts at Simboz!",
  };
  // ------
  let mdservice = {
    title: "Solution Based Marketing across NYC ",
    subtitle: "Productive Advertising on FB and YT",
    desc: `<p>Our experts have experience over advertising audits for Google and Facebook, during which time we have gained the knowledge necessary to determine what it takes to make you look good and what it takes for you to be successful. </p>
        <p>Get your free digital marketing strategy and performance audit today. During this audit, we will examine the current state of affairs to determine precisely what is working and what is not working in your campaigns at this moment. We will also determine exactly why certain aspects of your campaigns are not working. </p>`,
  };

  let mds = {
    title: "Bringing Leads and Maintaining Strong Client Relationship  ",
    subtitle: "",
    desc: `<p>Our SMM experts have ultimately polished their approaches on how to identify, perfect, and uncover the experiences your audience needs to convert as a result of testing it with a descripted data of people who visit your website. Within the first six months of working together, we typically increase your conversion rates by more than fifty percent on average.</p>
        <p>Using communication tactics that are both personalized and dynamic, our services allow you to cultivate your leads and establish relationships with both your present and former clients. Through the use of email, text/SMS, and chatbots, you can send the most impactful messages at the precise times they are needed. </p>`,
  };

  let grow = {
    title: "Get Positive Analytics ",
    subtitle: "",
    desc: `<p>Our expert growth strategist from Simboz will provide you with consistent recommendations and feedback on how to continue boosting performance, and the results that our skilled marketing strategists are producing across all campaigns and channels that can be viewed on your dashboard at any time.</p>
        <p>Our SMM Experts monitor everything that a user does on your website using tools such as Google or Adobe analytics, call tracking, tag management, heat mapping, as well as Customer Relationship Management (CRM) connectors. This means allowing us to calculate the amount of actual revenue that a user's actions on your website make a significant contribution to your business.</p>
       `,
  };

  let process = {
    title: "OUR PROCESS",
    desc: "We put into action to process the SEO services in order to obtain the following benefits as a whole:",
    arr: [
      "Business objective – Understand your business level view of the system",
      "SMM Team – Assign you a team throughout the entire process",
      "Brand persona – How do we present your brand products to the customers",
      "Social Media Platform – Work on all Social Medias to bring lead on the website",
      "Audience – Target audience according to your brand specifications ",
      "Metrics – Bring positive metrics ",
      "Adapt, learn and grow – Following the success ratio, we enhance our strategies with time and grow your business",
    ],
  };

  const faq = [
    [
      "<p>How often should I post?</p>",
      "<p>SMM services in New York City publish on social media according to their SMM plans and strategies. In most circumstances, publishing a restricted number of updates weekly increases engagement and attention. For instance, business posts should not exceed three per day. Engagement then progressively declines. Facebook recommends posting twice a day since after that, likes and comments decline substantially. LinkedIn limits monthly posts to 20. On most sites, three postings each day is ideal.</p>",
    ],

    [
      "<p>How Do I Add Money Using My Credit Card?</p>",
      `<p>Credit cards, bKash, PayTM, Payoneer, Perfect Money, Web Money, and Wise Payment are all accepted for adding funds on the PEAKERR SMM Panel.</p>
            <p>Step 1: Register and log in.</p>
            <p>Step 2: Visit the ADD FUND website.</p>
            <p>Step 3: From the Payment List, choose Checkout.com.</p>
            <p>Step 4: Add Money Using Debit and Credit Cards.</p>
            `,
    ],

    [
      "<p>What services SMM panels provide?</p>",
      "<p>There are several services to target different social media sites. SMM panels provide services for most social media platforms. Visit our website to find the social networking platform you want. Famous ones include Facebook, Instagram, Twitter, and YouTube. At Simboz, we give likes, following, subscriptions, views, and more for each site.</p>",
    ],
    [
      "<p>Is it harmful to use SMM panels?</p>",
      "<p>Digital marketing runs excellent with social media panels. Our SMM panel services are safe, easy, and cost-effective. Our system automatically sends you the service you ordered. We renew our tests to keep your accounts spam-free. Ordering more expedites service. Payments usually process within 48 hours. </p>",
    ],
    [
      "<p>Why were my orders canceled?</p>",
      "<p>Mistaken orders are usually canceled and returned. Your orders will also be canceled if they violate service restrictions. There are various cancellation causes. Some include typing the erroneous URL, concealing the profile, sending it to restricted accounts, etc. Make sure the profile isn't private on the URL and service you're using. For details, contact us.</p>",
    ],
  ];

  const data = {
    h1: "Our Process",
    p: `
         <p className='fp'>
         We put into action to process the SEO services in order to obtain the following benefits as a whole:
        </p>`,

    arr: [
      [
        `Business objective `,
        `Understand your business level view of the system`,
        "assets/icon/smm/Businessobjective.png",
      ],

      [
        `SMM Team `,
        `Assign you a team throughout the entire process`,
        "assets/icon/smm/SMMTeam.png",
      ],

      [
        `Brand persona `,
        ` How do we present your brand products to the customers`,
        "assets/icon/smm/Brandpersonal.png",
      ],

      [
        `Social Media Platform   `,
        ` Work on all Social Medias to bring lead on the website`,
        "assets/icon/smm/SocialMediaPlatform.png",
      ],

      [
        `Audience   `,
        `Target audience according to your brand specifications`,
        "assets/icon/smm/Audience.png",
      ],

      [`Metrics   `, `Bring positive metrics`, "assets/icon/smm/Metrics.png"],

      [
        `Adapt, learn and grow   `,
        ` Following the success ratio, we enhance our strategies with time and grow your business`,
        "assets/icon/smm/Adaptlearnandgrow.png",
      ],
    ],

    // p2: `With each of our clients, we establish a long-lasting relationship in which we grow to become their go-to resource for a wide range of daily business challenges. Contact us now.`
  };

  return (
    <div className="smm">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Best Social Media Marketing Agency - SMM Service</title>
        <meta
          name="description"
          content="Providing tailored SEM Services to enhance your business, and assisting in locating and engaging with your clients to generate tangible revenues."
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />

        {/* ---------------------------- */}

        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://simboz.com/assets/logos/logo.png"
        />
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://simboz.com/sem-service"
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Providing tailored SEM Services to enhance your business, and assisting in locating and engaging with your clients to generate tangible revenues."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Best Social Media Marketing Agency - SMM Service"
        />
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Best Social Media Marketing Agency - SMM Service | Simboz "
        />
        <meta data-react-helmet="true" property="fb:app_id" content="Simboz" />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Providing tailored SEM Services to enhance your business, and assisting in locating and engaging with your clients to generate tangible revenues."
        />
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://simboz.com/sem-service"
        />
      </Helmet>

      <CustomSubbanner data={banner} />
      <Description data={webd} />
      <Homeportfolio portfolio={portfolio} />
      <Descriptionwh data={builtinfeature} />
      <Cta data={cta} />
      <Dynamiccontentwh data={mdservice} />
      <Dynamiccontent data={mds} />
      <Dynamiccontentwh data={grow} />

      <Businesstaxsec data={data} />
      {/* <Description data={process} /> */}
      <Severalapp />
      <Faq faq={faq} />
    </div>
  );
}

export default Smm;
