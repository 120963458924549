import React, { useEffect } from "react";
import Portfoliobanner from "../components/Portfoliobanner";
import Portfoliosec from "../components/Portfoliosec";
import "./Portfolio.scss";
function Portfolio() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  return (
    <div className="portfolio">
      <Portfoliobanner />
      <Portfoliosec />
    </div>
  );
}

export default Portfolio;
