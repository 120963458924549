import React, { useState } from "react";
import "./css/Faq.scss";
function Faq({ faq }) {
  const [flag, setFlag] = useState(0);
  const toggle = (index) => {
    let a = flag != index ? index : null;
    // console.log("aaaaaaaaa", a);
    setFlag(a);
  };

  const createMarkup = (pro) => {
    return { __html: pro };
  };

  return (
    <div className="faq">
      <div className="faqinner">
        <div className="accordion">
          <h3 className="heading">Frequently Asked Questions </h3>

          {faq.map((item, index) => {
            return (
              <div
                key={index}
                className="innermao"
                onClick={() => {
                  toggle(index);
                }}
              >
                <div className="wraper">
                  <div
                    className="acctitle"
                    dangerouslySetInnerHTML={createMarkup(item[0])}
                  ></div>
                  <span className="spanr">{flag == index ? "+ " : "-"}</span>
                </div>
                <div
                  className={flag == index ? "accpara" : "hide"}
                  dangerouslySetInnerHTML={createMarkup(item[1])}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Faq;
