import React from "react";
import "./css/Blogbanner.scss";
function Blogbanner({ data }) {
  return (
    <div
      className="Blogbanner"
      style={{ backgroundImage: `url(${data.image})` }}
    >
      <div className="blogbannerinner">
        <h1>{data.heading}</h1>
        <h4>{data.p}</h4>
      </div>
    </div>
  );
}

export default Blogbanner;
