import React from "react";
import "./css/Cta.scss";
import Button from "../components/Button";
function Cta({ data }) {
  let togocontacl = () => {
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.querySelector("#contact").getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          160,
      });
    }, 100);
  };
  return (
    <div className="cta">
      <div className="inner">
        <p>{data.one}</p>
        <p>{data.two}</p>
        <div className="buttons">
          {/* <Button title={'Get Qoute'} className='custombtn'/> */}
          <button
            className="custombtn"
            onClick={() => {
              togocontacl();
            }}
          >
            Get Quote
          </button>
          <a href="tel:+13477593666">Get Call</a>
        </div>
      </div>
    </div>
  );
}

export default Cta;
