import React, { useEffect } from "react";
import Appemergingtec from "../components/Appemergingtec";
import Buildin from "../components/Buildin";
import Cta from "../components/Cta";
import CustomSubbanner from "../components/CustomSubbanner";
import Description from "../components/Description";
import Dynamiccontent from "../components/Dynamiccontent";
import Dynamiccontentwh from "../components/Dynamiccontentwh";
import Faq from "../components/Faq";
import Homeportfolio from "../components/Homeportfolio";
import Webtec from "../components/Webtec";
import "./Webdevelopment.scss";
import Businesstaxsec from "../components/Businesstaxsec";
import Severalapp from "../components/Severalapp";
import Descriptionwh from "../components/Descriptionwh";
import ContactForm from "../components/ContactForm";
import { Helmet } from "react-helmet";

function Webdevelopment() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  let url = window.location.href;
  let banner = {
    img: "assets/webdevelopment/web.webp",
    width: "800px",
    heading: " <span>Unique & Innovative Website </span> Expand Your Business",
    para: "Giving you reliable, 100% future success, and revenue generating web solutions. ",
    bottom: "-160px",
    marginbottom: "200px",
    backgroundImg: `url('assets/appdevelopment/webban.webp')`,
  };

  let webd = {
    h1: "Bespoke WEB Development Company Across NYC ",
    desc: `Simboz helps every-sized and every niche business with robust, fully-featured, and best-in-class custom web development services.`,
    arr: [
      "Excellent Google Support",
      "Declarative UI",
      "Revenue Productive ",
      "Cross Browser Compatibility ",
      "User & Mobile Friendly   ",
      "Clear & Effective CTAs",
    ],
  };

  let portfolio = {
    title: "Our Work Experience ",
    desc: "Discover how we have helped businesses with our web development services and deliver customers beyond their expectations.",
  };

  let builtinfeature = {
    title: "Excellent Build-In Features",
    desc: "Our excellent build-in features can enhance the performance of your Shopify e-commerce store, such as: ",
    arr: [
      "SEO Friendly",
      "Easy to Manage",
      "Mobile Responsive",
      "Strong Security ",
      "Support & Maintenance ",
    ],
  };

  let cta = {
    one: "Digitally transform your business and start generating excessive revenues!",
    two: "Get intelligent web developers at Simboz!",
  };

  let mdservice = {
    title: "Bespoke Website Solutions across the Nyc ",
    subtitle: "Stay on top of your Rivals  ",
    desc: `<p>Being a full-cycle website development company, we execute custom web designing and development. Our developers provide everything you need to stay on top of rivalry. From technology to design, we deliver ready-to-use solutions that assist in expanding your business with our web development service.</p>`,
  };

  let mds = {
    title: "Most Recent Technologies and Tools",
    subtitle: "",
    desc: `<p>Without regard to any technological constraints, as a ranking web design and development company in New York City, we allow you to implements the most recent functionality in accordance with the customer's specifications. </p>
        <p>We have firsthand knowledge of tech languages Such as PHP, JavaScript, and its vast array of frameworks and libraries. We constantly work towards functionality, improvement and site optimization with the latest features.</p>`,
  };

  let grow = {
    title: "We Work According to your Specification",
    subtitle: "",
    desc: `<p>As a recognized web design and development company in NYC, we work with startups, mid-sized businesses, and enterprises focusing on customer centricity, future success, and scalability. Whatever your project requires, Simboz has the technical tools and experienced team that ensure to deliver exceptional websites with scalability.</p>`,
  };

  const faq = [
    [
      "<p>Will I get only limited number of pages on my site or I can get as much as I require?</p>",
      "<p>No, you get unlimited pages. It’s totally up to you! To remove your confusion, the developer team provides the three pages or layouts; homepage, services page, and about page. You can see all the layouts of pages then after decided which one you want to add more to your website – it will be much better to describe your details of pages in a list so that the team can operate smoothly following up your instructions.</p>",
    ],

    [
      "<p>What is the total cost of website development?</p>",
      "<p>It depends on the complexity of the project as its features, technologies that used, and the length of the website plus the quality of pages and content – mainly starts from $1000 and the budget inclines positively as per the quality and content requirements.</p>",
    ],

    [
      "<p>What if I don’t like web designing or what if the designing and contents are not provided as I recommended?</p>",
      "<p>As a ranking Website Development Company in NYC, Simboz has the most reliable expert team of UX/UI design, the website we design in that way which fulfills your objectives, and if you don’t like we will design on another way until you get complete satisfaction.</p>",
    ],
    [
      "<p>After launching the website what if I get the errors or the website crashed?</p>",
      "<p>First thing is that we don’t launch the website unless we testify all the programs and make surety that the website will not create any problem. But even if there is an issue – you have our backup team supporting all the crashes and problems raising on your website for three months, until then your websites will start running smoothly.</p>",
    ],
    [
      "<p>Do you provide me the website content or I have to make my own?</p>",
      "<p>Yes, we do! But it’s up to you… If you don’t have the text of your website & want to add impressive web contents – texts, we are here to give you our quality content services as well.</p>",
    ],
  ];

  const data = {
    h1: "Our Process",
    p: `
         <p className='fp'>
         We split our web development process into different stages.
        </p>`,

    arr: [
      [
        `Project Understanding`,
        `  Understanding your project brief `,
        "assets/icon/Web/ProjectUnderstanding.png",
      ],

      [
        `Web Development Team`,
        `Assigns you an expert team to work throughout your entire project`,
        "assets/icon/Web/WebDevelopmentTeam.png",
      ],

      [
        `Designing  `,
        ` Creating architecture, selecting brand colors and themes to create attractive UI for each page`,
        "assets/icon/Web/Designing.png",
      ],

      [
        `Development `,
        `We do more than code to develop rich and strong security features`,
        "assets/icon/Web/Development.png",
      ],

      [
        `Launch  `,
        `Keeping your quality assurance, we launch your website on your web domain`,
        "assets/icon/Web/Launch.png",
      ],

      [
        `Maintenance  `,
        `Maintain and fix bugs with recent updates`,
        "assets/icon/Web/Maintenance.png",
      ],
    ],
  };

  return (
    <div className="webdevelopment">
      <Helmet>
        <meta charSet="utf-8" />
        <title data-react-helmet="true">
          Best Website Design & Development Service Providing company
        </title>
        <meta
          name="description"
          content="Being a website development company, Simboz offers rich-featured, SEO-friendly, and responsive web development solutions with the latest user experience."
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />

        {/* -----------------------og */}
        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://simboz.com/assets/logos/logo.png"
        />
        <meta data-react-helmet="true" property="og:url" content={`${url}`} />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Being a website development company, Simboz offers rich-featured, SEO-friendly, and responsive web development solutions with the latest user experience."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content=" Best Website Design & Development Service Providing company"
        />
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Simboz | Web Development Service"
        />
        <meta data-react-helmet="true" property="fb:app_id" content="Simboz" />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Being a website development company, Simboz offers rich-featured, SEO-friendly, and responsive web development solutions with the latest user experience."
        />
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://simboz.com/web-development-service"
        />
      </Helmet>
      <CustomSubbanner data={banner} />

      <Description data={webd} />
      <Homeportfolio portfolio={portfolio} />
      {/* <Appemergingtec data={builtinfeature} /> */}
      {/* <Buildin data={builtinfeature} /> */}
      <Descriptionwh data={builtinfeature} />
      <Cta data={cta} />
      <Dynamiccontentwh data={mdservice} />
      <Dynamiccontent data={mds} />
      <Dynamiccontentwh data={grow} />

      <Businesstaxsec data={data} />
      <Severalapp />
      {/* <Description data={process} /> */}
      <Faq faq={faq} />
    </div>
  );
}

export default Webdevelopment;
