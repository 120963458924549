import React from "react";
import "./css/Dynamiccontentwh.scss";
function Dynamiccontentwh({ data }) {
  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <div className="Dynamiccontentwh">
      <div className="inner">
        {data.h1 ? (
          <h1>{data.h1}</h1>
        ) : data.h2 ? (
          <h2 className="hedi">{data.h2}</h2>
        ) : (
          <h2 className="hedi">{data.title}</h2>
        )}
        <h3 className="subhedi">{data.subtitle ? data.subtitle : null}</h3>
        {window.screen.width > 550 ? (
          <div
            className="sec"
            dangerouslySetInnerHTML={createMarkup(data.desc)}
          ></div>
        ) : (
          <div
          className="sec"
          dangerouslySetInnerHTML={createMarkup(data.desc)}
        ></div>
        )}
      </div>
    </div>
  );
}

export default Dynamiccontentwh;
