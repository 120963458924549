import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import "./css/Testimonial.scss";
function Testimonial() {
  return (
    <div className="testimonialsec">
      <div className="testisec">
        <div className="testleftsec">
          <h2 className="h1">See how our customers drive impact</h2>
          <p>
            Get ready to be inspired! Our customers are our valuable assets,
            they're not just driving impact; they're smashing it out. We drive
            game-changing results to achieve customer feedback. Check out their
            feedback and join the movement!
          </p>
        </div>
        <div className="testrightsec">
          <Carousel
            // autoPlay
            infiniteLoop
            centerMode
            centerSlidePercentage={101}
          >
            <div className="box">
              <p>
                <FaQuoteLeft className="quote" /> We were looking for a reliable
                and experienced company to develop the ecommerce website for our
                startup, and highly glad to found this team. They took the time
                to understand our vision and worked closely with us throughout
                the development process. I recommended them to other
                entrepreneurs who are looking for professional development
                services.
                <FaQuoteRight className="quote" />
                <img src={"/assets/testim/test2.jpg"} className="boximg" />
              </p>
            </div>
            <div className="box">
              <p>
                {" "}
                <FaQuoteLeft className="quote" />
                I'm extremely happy with the Magento development work done by
                Simboz. They transformed my online store into a top-of-the-line
                e-commerce website. Their team of experts made the process
                simple and efficient. I recommend and highly satisfied with the
                professional and cooperative work of the entire team.
                <FaQuoteRight className="quote" />
                <img src={"/assets/testim/test3.jpg"} className="boximg" />
              </p>
            </div>
            <div className="box">
              <p>
                <FaQuoteLeft className="quote" /> Wonderful and creative work!
                Their team of experts is highly skilled, and their in-depth
                knowledge of Android development is impressive. They developed a
                high-quality and intuitive Android app for our business that
                exceeded our expectations. They have the most technical and
                professional Android app development partners. Thank you!
                <FaQuoteRight className="quote" />
                <img src={"/assets/testim/test1.jpg"} className="boximg" />
              </p>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
