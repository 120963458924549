import React, { useEffect } from "react";
import Blogbanner from "../components/Blogbanner";
import "./BlogListing.scss";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaGooglePlusG,
  FaYoutube,
  FaPinterestP,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

function BlogListing() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    axios
      .get("https://simboz.admin.simboz.com/blogapi.php")
      .then(function (response) {
        // Handle success
        console.log(response.data);
      })
      .catch(function (error) {
        // Handle error
        console.log(error);
      });
  }, []);

  let data = {
    image: "./assets/banners/Tax-Solution.jpg",
    heading: "We help businesses",
    p: "Empower people through technology and innovative solutions",
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" data-react-helmet="true"></meta>
      </Helmet>

      <div className="BlogListing">
        <Blogbanner data={data} />
        <div className="blogcont">
          <div className="bloglist">
            {/* blog cont */}
            <div className="blogcontwrap">
              <h2>Why Mobile Apps Are Important For Your Business</h2>
              <img src="assets/banners/Tax-Solution.webp" />
              <p className="detaildesc">
                For the years, mobile apps have played a pivotal role in the
                center of innovative technology. With the fast-evolving world,
                an upcoming new high-tech, and rapidly growing market, mobile
                applications keep us relevant and stronger among the
                fast-changing world, making our life most accessible and helps
                to compete with our competitors.
              </p>
              <Link
                to={`/blog/why-mobile-apps-are-important-for-your-business`}
              >
                Continue Reading
              </Link>
            </div>

            {/* blog cont end*/}
            {/* <div className="blogcontwrap">
              <h2>Artificial intelligence</h2>
              <p className="hedidisc">
                artificial intelligence (AI), the ability of a digital computer
                or computer-controlled robot to perform tasks commonly
                associated with intelligent.
              </p>
              <img src="assets/blogs/2.webp" />
              <p className="detaildesc">
                artificial intelligence (AI), the ability of a digital computer
                or computer-controlled robot to perform tasks commonly
                associated with intelligent beings. The term is frequently
                applied to the project of developing systems endowed with the
                intellectual processes characteristic of humans, such as the
                ability to reason, discover meaning, generalize, or learn from
                past experience. Since the development of the digital computer
                in the 1940s,
              </p>
              <Link to={`/a`}>Continue Reading</Link>
            </div>
            <div className="blogcontwrap">
              <h2>Virtual Reality Arcade</h2>
              <p className="hedidisc">
                Guests get to immerse themselves in a virtual reality experience
                of their choosing, including mysteries, thrillers, and space
                shooters
              </p>
              <img src="assets/blogs/3.webp" />
              <p className="detaildesc">
                Virtual reality allows an individual to experience another
                generated reality that is produced by special software and
                hardware. Virtual reality arcades give you the opportunity to
                visualise an alternate reality without the need of buying your
                own headset. This experience is based and simulated in
                warehouses or courtyards which gives the space for individuals
                to run.
              </p>
              <Link to={`/a`}>Continue Reading</Link>
            </div> */}
            {/* dummy text */}
          </div>

          {/* snd sec */}
          <div className="blogsidebar">
            {/* Cat */}
            <div className="followme">
              <div className="blogheader">
                <p>Categories </p>
              </div>
              <div className="blogsidebody">
                <ul>
                  <li>Info Tech</li>
                </ul>
              </div>
            </div>
            {/* search */}
            <div className="followme">
              <div className="blogheader">
                <p>Search Blog</p>
              </div>
              <div className="blogsidebody">
                <input className="inp" placeholder="Type here" />
                <button>Get Blog</button>
              </div>
            </div>
            <div className="followme">
              <div className="blogheader">
                <p>Follow Simboz</p>
              </div>
              <div className="blogsidebody">
                <div className="innerfollwme">
                  <a
                    href="https://www.facebook.com/people/Simboz/100090278544937/?sk=about"
                    target={"_blank"}
                  >
                    <FaFacebookF className="icon" />
                    <p>Facebook</p>
                  </a>
                  <a
                    href="https://twitter.com/simbozofficial"
                    target={"_blank"}
                  >
                    <FaTwitter className="icon" />
                    <p>Twitter</p>
                  </a>
                  {/* <div>
                    <FaInstagram className="icon" />
                    <p>Instagram</p>
                  </div>
                  <div>
                    <FaGooglePlusG className="icon" />
                    <p>Goolge +</p>
                  </div>
                  <div>
                    <FaYoutube className="icon" />
                    <p>Youtube</p>
                  </div> */}
                  <a
                    href="https://www.pinterest.com/simbozofficial/"
                    target={"_blank"}
                  >
                    <FaPinterestP className="icon" />
                    <p>Pintrest</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogListing;
