import React from "react";
import "./css/Ourexpertise.scss";
function Ourexpertise() {
  const mapdata = [
    {
      icon: `
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" fill='#091b3f'>
      <g>
        <path class="st0" d="M45.91,35.14c-0.65-0.01-1.31-0.03-1.96,0.01c-0.4,0.02-0.53-0.06-0.53-0.51c0.02-7.51,0.01-15.02,0.01-22.53
          c0-0.28,0.02-0.55-0.06-0.83c-0.26-0.92-1-1.43-2.09-1.43c-10.65,0-21.3,0-31.96,0c-0.1,0-0.2,0-0.29,0
          c-0.71,0.06-1.27,0.38-1.63,1.01c-0.21,0.37-0.23,0.76-0.23,1.17c0,7.56,0,15.13,0.01,22.69c0,0.31-0.06,0.43-0.4,0.42
          c-0.65-0.03-1.31,0.01-1.96-0.01c-0.41-0.01-0.7,0.13-0.88,0.49c0,0.19,0,0.39,0,0.58c0.12,0.13,0.1,0.28,0.07,0.43
          c0.27,2.44,2.38,4.78,5.22,4.78c10.72-0.03,21.44-0.01,32.16-0.01c0.3,0,0.62,0,0.91-0.06c2.52-0.48,4.43-2.87,4.36-5.46
          C46.66,35.4,46.41,35.14,45.91,35.14z M9.35,11.16c10.63,0,21.25,0,31.88,0c0.76,0,0.93,0.17,0.93,0.93c0,7.52,0,15.05,0.01,22.57
          c0,0.37-0.07,0.48-0.47,0.48c-5.47-0.01-10.93-0.01-16.4-0.01c-5.45,0-10.9-0.01-16.36,0.01c-0.42,0-0.49-0.12-0.49-0.51
          c0.01-7.52,0-15.05,0-22.57C8.45,11.32,8.62,11.16,9.35,11.16z M30.15,36.46c-0.22,0.72-0.77,1.15-1.46,1.16
          c-2.25,0.01-4.5,0.01-6.75,0c-0.6,0-1.05-0.31-1.37-0.81c-0.17-0.28-0.12-0.36,0.22-0.36c2.01,0.02,4.03,0.01,6.04,0.02
          C27.92,36.46,29.02,36.46,30.15,36.46z M45.3,36.75c-0.35,1.98-2,3.35-4.07,3.35c-5.31,0-10.62,0-15.93,0s-10.62,0-15.93,0
          c-2.05,0-3.68-1.36-4.04-3.33c-0.05-0.26,0-0.33,0.27-0.33c4.39,0.01,8.79,0.01,13.18,0c0.25,0,0.32,0.08,0.38,0.31
          c0.34,1.22,1.36,2.11,2.63,2.13c2.35,0.05,4.7,0.05,7.05,0c1.29-0.03,2.3-0.91,2.65-2.16c0.06-0.22,0.14-0.29,0.36-0.29
          c4.41,0.01,8.81,0.01,13.22,0C45.32,36.43,45.34,36.51,45.3,36.75z"/>
        <path class="st0" d="M3.95,36.2c0,0.14,0,0.28,0,0.42l0.03,0.02l0.04,0C3.94,36.5,4.04,36.33,3.95,36.2z"/>
        <path class="st0" d="M15.68,26.58c-0.43,1.59,0.26,2.8,1.85,3.21c1.2,0.31,2.4,0.2,3.59-0.07c0.34-0.08,0.47-0.02,0.57,0.33
          c0.33,1.07,0.77,2.08,1.52,2.93c1.27,1.45,2.94,1.43,4.18-0.05c0.73-0.86,1.17-1.88,1.5-2.95c0.09-0.3,0.21-0.35,0.5-0.28
          c0.67,0.17,1.37,0.25,1.99,0.25c0.52,0.01,0.96-0.02,1.4-0.1c1.77-0.33,2.58-1.66,2.07-3.38c-0.35-1.16-1.04-2.11-1.85-2.98
          c-0.22-0.24-0.21-0.36,0-0.59c0.82-0.88,1.5-1.86,1.86-3.02c0.52-1.68-0.21-2.96-1.91-3.38c-1.19-0.29-2.38-0.17-3.55,0.1
          c-0.32,0.07-0.42-0.01-0.51-0.3c-0.33-1.08-0.77-2.11-1.52-2.98c-1.24-1.44-2.91-1.42-4.15,0.03c-0.73,0.86-1.19,1.87-1.51,2.94
          c-0.1,0.33-0.24,0.38-0.55,0.3c-1.06-0.25-2.14-0.34-3.23-0.17c-1.93,0.31-2.8,1.74-2.17,3.59c0.37,1.1,1.03,2.03,1.81,2.87
          c0.2,0.22,0.22,0.33,0.01,0.56C16.74,24.35,16.01,25.35,15.68,26.58z M29.69,17.86c0.6-0.1,1.2-0.21,1.82-0.21
          c0.47,0,0.94,0.01,1.4,0.17c0.71,0.26,0.99,0.73,0.8,1.46c-0.29,1.11-0.99,1.97-1.72,2.81c-0.12,0.14-0.2,0.08-0.3-0.01
          c-0.55-0.49-1.12-0.95-1.72-1.38c-0.13-0.1-0.2-0.21-0.22-0.38c-0.06-0.69-0.16-1.38-0.32-2.05C29.35,18.01,29.43,17.9,29.69,17.86
          z M30.93,23.14c-0.34,0.29-0.65,0.55-1.03,0.87c0-0.61,0-1.13,0-1.74C30.27,22.59,30.59,22.86,30.93,23.14z M29.39,28.09
          c0.15-0.73,0.28-1.47,0.34-2.22c0.01-0.15,0.1-0.21,0.2-0.29c0.56-0.45,1.12-0.9,1.69-1.35c0.09-0.07,0.17-0.24,0.33-0.06
          c0.72,0.81,1.42,1.64,1.71,2.71c0.24,0.87-0.1,1.42-0.98,1.64c-0.38,0.1-0.77,0.11-1.16,0.1c-0.65,0-1.3-0.1-1.94-0.23
          C29.4,28.35,29.35,28.28,29.39,28.09z M22.33,26.69c0.46,0.26,0.91,0.52,1.42,0.81c-0.1,0.05-0.16,0.08-0.22,0.11
          C22.4,28.06,22.27,27.97,22.33,26.69z M27.72,29.45c-0.31,0.89-0.64,1.77-1.24,2.52c-0.3,0.37-0.63,0.7-1.01,0.73
          c-0.47,0.01-0.74-0.13-0.97-0.34c-0.86-0.8-1.25-1.87-1.62-2.95c-0.05-0.15,0.03-0.18,0.15-0.23c0.71-0.27,1.42-0.55,2.12-0.84
          c0.14-0.06,0.24-0.03,0.35,0.02c0.66,0.29,1.32,0.56,2.01,0.78C27.68,29.19,27.8,29.24,27.72,29.45z M28.14,27.82
          c-0.03,0.18-0.15,0.13-0.25,0.1c-0.32-0.11-0.64-0.24-1.04-0.38c0.52-0.3,0.97-0.56,1.46-0.84C28.32,27.1,28.19,27.46,28.14,27.82z
           M28.31,19.52c-0.28-0.06-0.48-0.26-0.71-0.38c-0.23-0.12-0.45-0.26-0.73-0.42c0.09-0.04,0.14-0.07,0.19-0.09
          C28.2,18.18,28.24,18.21,28.31,19.52z M22.9,16.8c0.32-0.93,0.67-1.85,1.32-2.62c0.28-0.33,0.61-0.61,1.04-0.63
          c0.43,0,0.73,0.2,0.98,0.47c0.76,0.8,1.13,1.79,1.48,2.81c0.06,0.17-0.03,0.21-0.17,0.26c-0.7,0.23-1.38,0.49-2.05,0.8
          c-0.16,0.07-0.28,0.04-0.43-0.02c-0.65-0.27-1.31-0.52-1.97-0.77C22.93,17.05,22.83,17.01,22.9,16.8z M22.48,18.42
          c0.02-0.19,0.13-0.13,0.23-0.09c0.34,0.12,0.67,0.24,1.07,0.38c-0.53,0.31-0.98,0.56-1.46,0.84C22.3,19.14,22.43,18.78,22.48,18.42
          z M22.04,21.63c0.01-0.27,0.11-0.42,0.33-0.56c0.86-0.56,1.75-1.07,2.66-1.54c0.19-0.1,0.35-0.1,0.54,0
          c0.94,0.48,1.85,1.01,2.73,1.58c0.17,0.11,0.25,0.23,0.25,0.43c-0.01,0.53,0,1.06,0,1.58c0.01,0,0.01,0,0.02,0
          c0,0.33-0.03,0.67,0.01,1c0.07,0.64-0.18,1.04-0.76,1.34c-0.76,0.4-1.49,0.86-2.25,1.26c-0.19,0.1-0.34,0.1-0.53,0
          c-0.92-0.47-1.8-0.98-2.66-1.54c-0.22-0.14-0.32-0.29-0.33-0.56C22.03,23.63,22,22.63,22.04,21.63z M16.94,19.33
          c-0.2-0.78,0.09-1.3,0.86-1.54c0.5-0.15,1.01-0.18,1.53-0.15c0.54,0.02,1.08,0.11,1.61,0.21c0.24,0.04,0.36,0.11,0.29,0.4
          c-0.17,0.69-0.28,1.39-0.34,2.09c-0.01,0.15-0.08,0.26-0.2,0.34c-0.6,0.43-1.17,0.89-1.72,1.38c-0.09,0.08-0.16,0.19-0.3,0.04
          C17.93,21.27,17.22,20.43,16.94,19.33z M20.69,22.28c0,0.58,0,1.1,0,1.65c-0.37-0.21-0.65-0.52-1.01-0.8
          C20.04,22.83,20.34,22.58,20.69,22.28z M18.65,24.15c0.12-0.13,0.19-0.04,0.27,0.03c0.56,0.5,1.14,0.97,1.75,1.41
          c0.12,0.09,0.18,0.2,0.2,0.35c0.06,0.72,0.17,1.43,0.34,2.14c0.05,0.21-0.02,0.29-0.23,0.33c-0.63,0.13-1.26,0.23-2.01,0.22
          c-0.35,0.01-0.81-0.02-1.25-0.18c-0.71-0.25-1-0.73-0.82-1.46C17.18,25.86,17.9,24.99,18.65,24.15z"/>
        <path class="st0" d="M25.32,25.45c1.26,0,2.31-1.04,2.31-2.3c0.01-1.28-1.04-2.32-2.33-2.33c-1.28-0.01-2.34,1.05-2.33,2.32
          C22.99,24.43,24.02,25.45,25.32,25.45z M25.32,22.1c0.57,0.01,1.01,0.47,1,1.05c-0.01,0.56-0.47,1.01-1.01,1
          c-0.58-0.01-1.03-0.46-1.02-1.04C24.29,22.54,24.74,22.09,25.32,22.1z"/>
      </g>
      </svg>
      `,
      cat: "Web Development",
      desc: `By using the latest technology, we build responsive websites and our main aim is to fulfill all your requirements, with a certain time and budget.`,
    },
    {
      icon: `<svg version="1.1" className='iccol'  id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
   <g>
     <path class="st0" d="M38.29,14.1c-1.18,0-2.37-0.01-3.55,0.01c-0.32,0.01-0.41-0.09-0.41-0.41c0.01-2.06,0.01-4.12,0.01-6.18
       c0-1.87-1.11-2.98-2.97-2.98c-6.19,0-12.39,0.03-18.58-0.02c-1.71-0.01-3.04,1.25-3.02,3.02c0.05,5.81,0.02,11.62,0.02,17.43
       c0,5.89,0,11.77,0,17.66c0,1.71,1.15,2.86,2.86,2.86c6.27,0,12.54,0,18.81,0c1.74,0,2.88-1.15,2.88-2.89
       c0-1.63,0.01-3.26-0.01-4.89c0-0.35,0.1-0.43,0.44-0.43c1.17,0.02,2.34,0.01,3.5,0.01c1.75,0,2.89-1.13,2.89-2.87
       c0-5.81,0-11.62,0-17.43C41.16,15.24,40.03,14.1,38.29,14.1z M18.64,6.01c0.06-0.15,0.17-0.1,0.27-0.1c2.1,0,4.2,0,6.3-0.01
       c0.19,0,0.26,0.05,0.27,0.25c0.04,0.86-0.18,1.12-1.03,1.12c-0.8,0-1.59,0-2.39,0c-0.86,0-1.72,0.01-2.58,0
       C18.8,7.26,18.4,6.63,18.64,6.01z M32.97,42.56c0,1.04-0.52,1.56-1.55,1.56c-6.24,0-12.48,0-18.72,0c-1.04,0-1.55-0.51-1.55-1.55
       c0-5.86,0-11.71,0-17.57c0-5.79,0.02-11.59-0.02-17.38c-0.01-1.1,0.58-1.77,1.74-1.72c1.32,0.06,2.64,0.03,3.96,0
       c0.36-0.01,0.45,0.09,0.45,0.45c-0.02,1.11,0.49,1.87,1.42,2.18c0.24,0.08,0.48,0.1,0.73,0.1c1.75,0,3.5,0.01,5.26,0
       c1.31-0.01,2.18-0.86,2.15-2.17c-0.01-0.49,0.14-0.57,0.58-0.56c1.35,0.03,2.7,0.01,4.06,0.01c0.98,0,1.5,0.53,1.51,1.51
       c0.01,2.09-0.01,4.18,0.01,6.27c0,0.38-0.14,0.41-0.45,0.41c-5.66-0.01-11.31,0.03-16.97-0.03c-1.82-0.02-3.1,1.25-3.08,3.1
       c0.05,5.73,0.02,11.46,0.02,17.2c0,1.48,0.82,2.56,2.17,2.86c0.27,0.06,0.55,0.06,0.82,0.06c5.66,0,11.31,0.01,16.97-0.01
       c0.43,0,0.53,0.12,0.52,0.53C32.96,39.39,32.97,40.97,32.97,42.56z M39.79,34.31c0,1.13-0.49,1.62-1.63,1.62
       c-3.78,0-7.56,0-11.34,0c-3.77,0-7.53,0-11.3,0c-1.16,0-1.66-0.5-1.66-1.65c0-5.73,0-11.47,0-17.2c0-1.11,0.52-1.63,1.64-1.63
       c7.55,0,15.09,0,22.64,0c1.15,0,1.65,0.5,1.65,1.66C39.79,22.84,39.79,28.57,39.79,34.31z"/>
     <path class="st0" d="M24.71,27.74c-0.44,0-0.74-0.51-0.52-1.03c0.44-1.04,0.89-2.08,1.34-3.12c0.87-2.02,1.74-4.05,2.6-6.07
       c0.03-0.07,0.06-0.14,0.09-0.21c0.21-0.44,0.55-0.59,0.92-0.43c0.38,0.17,0.51,0.52,0.32,0.97c-0.61,1.45-1.24,2.9-1.86,4.35
       c-0.71,1.66-1.43,3.32-2.13,4.98C25.34,27.5,25.15,27.72,24.71,27.74z"/>
     <path class="st0" d="M30.91,26.38c-0.27-0.02-0.49-0.15-0.6-0.41c-0.12-0.3-0.06-0.56,0.18-0.8c0.85-0.84,1.68-1.71,2.55-2.53
       c0.32-0.3,0.27-0.46-0.02-0.73c-0.83-0.8-1.64-1.62-2.45-2.44c-0.38-0.38-0.41-0.78-0.1-1.08c0.31-0.29,0.68-0.26,1.05,0.11
       c1.07,1.06,2.13,2.13,3.19,3.19c0.42,0.42,0.42,0.76,0,1.19c-1.06,1.07-2.13,2.13-3.19,3.2C31.35,26.24,31.17,26.37,30.91,26.38z"
       />
     <path class="st0" d="M22.7,18.19c0.29,0.02,0.51,0.14,0.64,0.4c0.14,0.3,0.07,0.57-0.16,0.8c-0.48,0.49-0.97,0.98-1.46,1.47
       c-0.4,0.4-0.79,0.81-1.21,1.2c-0.19,0.18-0.19,0.27,0,0.46c0.88,0.86,1.74,1.73,2.61,2.6c0.29,0.29,0.41,0.62,0.14,0.98
       c-0.24,0.34-0.69,0.39-1.01,0.08c-1.15-1.13-2.29-2.27-3.42-3.42c-0.29-0.29-0.29-0.65,0-0.95c1.13-1.15,2.28-2.28,3.42-3.42
       C22.38,18.26,22.54,18.2,22.7,18.19z"/>
     <path class="st0" d="M20.67,29.8c1.32,0,2.64,0,3.96,0c0.51,0,0.82,0.25,0.83,0.66c0.01,0.42-0.31,0.7-0.84,0.7
       c-2.63,0-5.25,0-7.88,0c-0.5,0-0.82-0.27-0.83-0.67c-0.01-0.43,0.3-0.69,0.84-0.69C18.06,29.79,19.36,29.8,20.67,29.8z"/>
     <path class="st0" d="M19.32,33.88c-0.86,0-1.72,0-2.58,0c-0.52,0-0.81-0.24-0.81-0.67c-0.01-0.42,0.29-0.69,0.8-0.69
       c1.74-0.01,3.47-0.01,5.21,0c0.51,0,0.79,0.27,0.79,0.7c-0.01,0.43-0.29,0.66-0.81,0.66C21.04,33.88,20.18,33.88,19.32,33.88z"/>
     <path class="st0" d="M22.06,41.4c0.86,0,1.72,0,2.58,0c0.51,0,0.82,0.26,0.82,0.67c0,0.41-0.3,0.68-0.81,0.68
       c-1.74,0.01-3.47,0.01-5.21,0c-0.49,0-0.81-0.29-0.79-0.69c0.01-0.39,0.31-0.65,0.79-0.65C20.31,41.39,21.19,41.4,22.06,41.4z"/>
   </g>
   </svg>`,
      cat: "Mobile App Development",
      desc: `Our experts can develop fast and smooth-running mobile applications and we provide the backend system, user interface, startup solutions, etc.`,
    },
    {
      icon: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve" >
   <g>
     <g>
       <path class="st0" d="M45.91,3.58c-5,0-9.99,0-14.99,0c-1.63,0-2.47,0.85-2.47,2.48c0,3.61-0.01,7.21,0.01,10.82
         c0,0.47-0.13,0.56-0.57,0.55c-7.42-0.01-14.84,0.01-22.26-0.03c-1.62-0.01-2.58,1.02-2.57,2.56c0.05,6.46,0.02,12.91,0.02,19.37
         c0,1.12,0,1.12-1.13,1.16c-0.02,0-0.03,0-0.05,0c-0.58,0.03-0.81,0.24-0.82,0.82c-0.02,0.55-0.01,1.1,0,1.65
         c0.01,2.89,1.88,4.77,4.76,4.77c5.27,0,10.54,0,15.82,0c5.36,0,10.72,0,16.07,0c2.11,0,3.85-1.32,4.39-3.3
         c0.21-0.78,0.14-1.57,0.15-2.36c0.01-1.57,0-1.58-1.54-1.58c-0.37,0-0.45-0.11-0.45-0.46c0.02-2.2,0.02-4.4,0-6.59
         c0-0.39,0.13-0.44,0.47-0.43c1.73,0.02,3.47,0.01,5.2,0.01c1.61,0,2.47-0.87,2.47-2.49c0-8.14,0-16.28,0-24.42
         C48.42,4.47,47.53,3.58,45.91,3.58z M4.56,19.92c0-0.83,0.2-1.03,1.03-1.03c7.45,0,14.91,0,22.36-0.01c0.46,0,0.51,0.15,0.51,0.55
         c-0.02,3.68-0.01,7.35-0.01,11.03c0,1.64,0.83,2.48,2.46,2.48c2.47,0,4.95,0.01,7.42-0.01c0.42,0,0.47,0.14,0.47,0.5
         c-0.02,2.2-0.02,4.4,0,6.59c0,0.39-0.11,0.48-0.49,0.48c-5.55-0.01-11.09-0.01-16.64-0.01c-5.53,0-11.06-0.01-16.59,0.01
         c-0.43,0-0.53-0.11-0.53-0.54C4.56,33.28,4.56,26.6,4.56,19.92z M40.81,42.51c-0.06,0.44,0.01,0.89-0.06,1.33
         c-0.25,1.42-1.41,2.46-2.86,2.47c-2.09,0.02-4.19,0.01-6.28,0.01c-8.62,0-17.24,0-25.86,0c-1.59,0-2.73-0.84-3.09-2.26
         c-0.16-0.62-0.05-1.26-0.08-1.9c-0.01-0.17,0.09-0.19,0.23-0.19c0.12,0,0.24,0,0.36,0c6.18,0,12.36,0,18.54,0s12.36,0,18.54-0.01
         C40.68,41.96,40.87,42.03,40.81,42.51z M46.92,30.73c-0.02,0.52-0.27,0.77-0.8,0.81c-0.1,0.01-0.21,0-0.31,0
         c-4.92,0-9.85,0-14.77,0c-0.94,0-1.13-0.18-1.12-1.1c0.01-0.46-0.2-1.05,0.08-1.34c0.28-0.29,0.88-0.09,1.34-0.09
         c2.37-0.01,4.74,0,7.1,0c2.68,0,5.35,0.01,8.03-0.01c0.35,0,0.49,0.07,0.46,0.45C46.9,29.87,46.93,30.3,46.92,30.73z M46.48,27.55
         c-5.38-0.01-10.75-0.01-16.13,0c-0.37,0-0.44-0.11-0.44-0.46c0.01-5.5,0.01-10.99,0-16.49c0-0.36,0.1-0.46,0.46-0.46
         c5.38,0.01,10.75,0.01,16.13,0c0.38,0,0.44,0.14,0.44,0.47c-0.01,2.75-0.01,5.5-0.01,8.24s-0.01,5.5,0.01,8.24
         C46.94,27.47,46.83,27.55,46.48,27.55z M46.48,8.69c-2.7-0.02-5.39-0.01-8.09-0.01c-2.66,0-5.32-0.01-7.98,0.01
         c-0.42,0-0.51-0.13-0.5-0.52c0.03-0.74,0-1.48,0.01-2.21c0.01-0.6,0.3-0.89,0.88-0.89c5.1,0,10.2,0,15.3,0
         c0.58,0,0.81,0.23,0.82,0.81c0.01,0.79-0.02,1.58,0.01,2.37C46.95,8.62,46.83,8.69,46.48,8.69z"/>
       <path class="st0" d="M21.68,37.96c-4.41,0-8.82,0-13.24,0c-0.64,0-0.92-0.22-0.92-0.71c0-0.42,0.22-0.67,0.63-0.74
         c0.17-0.03,0.34-0.01,0.51-0.01c8.67,0,17.34,0,26.01,0c0.84,0,1.16,0.21,1.15,0.75c-0.01,0.53-0.33,0.72-1.18,0.72
         C30.34,37.96,26.01,37.96,21.68,37.96z"/>
       <path class="st0" d="M16.51,32.06c2.69,0,5.39,0,8.08,0c0.37,0,0.69,0.06,0.84,0.44c0.2,0.5-0.12,0.98-0.66,1.02
         c-0.12,0.01-0.24,0-0.36,0c-5.27,0-10.54,0-15.8,0c-0.22,0-0.45,0.04-0.65-0.08c-0.33-0.2-0.5-0.48-0.41-0.86
         c0.09-0.4,0.39-0.53,0.78-0.52c2.56,0,5.11,0,7.67,0C16.17,32.06,16.34,32.06,16.51,32.06z"/>
       <path class="st0" d="M21.8,23.76c-0.94,0-1.89,0-2.83,0c-0.57,0-0.86-0.25-0.87-0.71c-0.01-0.48,0.3-0.75,0.89-0.76
         c1.87,0,3.74,0,5.61,0c0.61,0,0.89,0.24,0.89,0.74c0,0.5-0.27,0.72-0.9,0.72C23.65,23.76,22.72,23.76,21.8,23.76z"/>
       <path class="st0" d="M21.8,28.2c-0.94,0-1.89,0.01-2.83,0c-0.57,0-0.86-0.25-0.87-0.71c-0.01-0.48,0.3-0.75,0.89-0.76
         c1.87,0,3.74,0,5.61,0c0.61,0,0.89,0.25,0.89,0.74c0,0.5-0.27,0.72-0.9,0.73C23.65,28.2,22.72,28.2,21.8,28.2z"/>
       <path class="st0" d="M42.13,16.15c0.48,0,0.96-0.01,1.44,0c0.5,0.02,0.76,0.27,0.77,0.71c0.01,0.46-0.26,0.76-0.77,0.77
         c-0.96,0.02-1.92,0.02-2.88,0c-0.5-0.01-0.79-0.32-0.78-0.77c0.02-0.43,0.28-0.69,0.78-0.71C41.17,16.14,41.65,16.15,42.13,16.15z
         "/>
       <path class="st0" d="M34.73,17.64c-0.48,0-0.96,0.01-1.44,0c-0.5-0.02-0.79-0.32-0.78-0.77c0.01-0.43,0.29-0.7,0.78-0.71
         c0.96-0.02,1.92-0.02,2.88,0c0.5,0.01,0.76,0.27,0.77,0.71c0.01,0.47-0.25,0.75-0.77,0.77C35.69,17.65,35.21,17.64,34.73,17.64z"
         />
       <path class="st0" d="M34.72,21.56c-0.48,0-0.96,0.01-1.44,0c-0.48-0.02-0.76-0.29-0.77-0.72c-0.01-0.43,0.25-0.74,0.73-0.75
         c0.99-0.02,1.99-0.02,2.98,0c0.47,0.01,0.73,0.34,0.71,0.77c-0.02,0.46-0.3,0.68-0.73,0.7C35.71,21.57,35.21,21.56,34.72,21.56z"
         />
       <path class="st0" d="M42.11,25.48c-0.48,0-0.96,0.01-1.44,0c-0.48-0.02-0.76-0.3-0.77-0.73c-0.01-0.44,0.25-0.73,0.74-0.75
         c0.99-0.02,1.99-0.02,2.98,0c0.49,0.01,0.73,0.31,0.71,0.76c-0.02,0.46-0.3,0.68-0.73,0.7C43.11,25.49,42.61,25.48,42.11,25.48z"
         />
       <path class="st0" d="M42.11,20.07c0.48,0,0.96-0.02,1.44,0c0.51,0.02,0.79,0.31,0.78,0.76c-0.01,0.45-0.29,0.71-0.82,0.72
         c-0.93,0.01-1.85,0.01-2.78,0c-0.52-0.01-0.81-0.27-0.83-0.71c-0.02-0.47,0.27-0.75,0.82-0.77
         C41.19,20.06,41.65,20.07,42.11,20.07C42.11,20.07,42.11,20.07,42.11,20.07z"/>
       <path class="st0" d="M34.73,25.48c-0.46,0-0.93,0.01-1.39,0c-0.53-0.01-0.81-0.27-0.83-0.72c-0.02-0.45,0.26-0.75,0.78-0.76
         c0.96-0.02,1.92-0.02,2.88,0c0.53,0.01,0.78,0.29,0.77,0.76c-0.01,0.46-0.28,0.71-0.82,0.72C35.66,25.49,35.19,25.48,34.73,25.48z
         "/>
       <path class="st0" d="M42.16,12.17c0.48,0,0.96-0.01,1.44,0c0.5,0.02,0.74,0.27,0.74,0.73c0,0.45-0.26,0.72-0.74,0.73
         c-0.98,0.01-1.95,0.02-2.93,0c-0.45-0.01-0.76-0.33-0.76-0.74c0-0.43,0.27-0.7,0.76-0.72C41.16,12.16,41.66,12.17,42.16,12.17z"/>
       <path class="st0" d="M34.73,12.17c0.5,0,0.99-0.01,1.49,0c0.45,0.01,0.7,0.25,0.71,0.7c0.02,0.46-0.24,0.74-0.72,0.75
         c-0.99,0.02-1.99,0.01-2.98,0c-0.45,0-0.69-0.28-0.72-0.71c-0.03-0.43,0.26-0.73,0.73-0.75C33.74,12.16,34.24,12.17,34.73,12.17z"
         />
       <path class="st0" d="M21.66,44.85c-0.5,0-0.99,0.01-1.49,0c-0.5-0.02-0.8-0.3-0.81-0.74c-0.01-0.46,0.25-0.73,0.78-0.74
         c1.03-0.02,2.05-0.02,3.08,0c0.52,0.01,0.8,0.31,0.79,0.75c-0.01,0.44-0.31,0.72-0.82,0.73C22.68,44.86,22.17,44.85,21.66,44.85z"
         />
       <path class="st0" d="M15.14,22.31c0-0.59-0.26-0.83-0.87-0.84c-1.96-0.01-3.91,0-5.87,0c-0.64,0-0.87,0.24-0.87,0.87
         c-0.01,0.96,0,1.92,0,2.88c0,0.98,0,1.96,0,2.94c0,0.64,0.28,0.93,0.89,0.93c1.96,0.01,3.91,0.01,5.87,0
         c0.59,0,0.84-0.25,0.85-0.85C15.15,26.26,15.15,24.29,15.14,22.31z M13.29,27.63c-0.67-0.01-1.34-0.01-2.01-0.01
         c-0.65,0-1.3-0.01-1.95,0.01c-0.27,0.01-0.35-0.09-0.35-0.35c0.01-1.32,0.01-2.64,0-3.96c0-0.25,0.04-0.37,0.33-0.37
         c1.34,0.01,2.68,0.01,4.01,0c0.25,0,0.32,0.08,0.32,0.33c-0.01,1.34-0.01,2.68,0,4.01C13.66,27.57,13.55,27.63,13.29,27.63z"/>
     </g>
   </g>
   </svg>
   `,
      cat: "PWA Development",
      desc: `We develop an inspiring PWA that works seamlessly and works without causing a problem and can be used on all devices.`,
    },
    {
      icon: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
   <g>
     <path class="st0" d="M44.75,39.08c-0.26-0.04-0.54-0.05-0.8-0.01c-0.8,0.13-0.97-0.18-0.97-0.96C43.02,32.05,43.01,26,43,19.95
       c0-2.29-1.52-3.79-3.81-3.82c-0.45-0.01-0.9-0.05-1.34,0.01c-0.77,0.1-1.1-0.15-1.15-0.98c-0.07-1.2-0.71-2.13-1.72-2.78
       c-0.87-0.57-1.86-0.65-2.84-0.55c-0.92,0.1-1.16-0.2-1.16-1.13c-0.01-2.78-2.37-5.07-5.16-5.13c-2.76-0.07-5.15,2.05-5.41,4.8
       c-0.14,1.45-0.14,1.45-1.65,1.45c-2.89,0-4.05,0.96-4.54,3.8c-0.07,0.43-0.21,0.54-0.61,0.53c-0.89-0.02-1.79-0.01-2.68-0.01
       c-2.65,0.01-4.09,1.44-4.09,4.08c0,5.78,0,11.57,0,17.35c0,1.45,0,1.47-1.47,1.51c-0.75,0.02-0.93,0.3-0.69,1.01
       c0.49,1.5,0.96,3,1.36,4.53c0.2,0.77,0.55,1.03,1.36,1.02c5.84-0.04,11.69-0.02,17.53-0.02c2.47,0,4.95,0,7.42,0
       c3.43,0,6.86,0,10.28,0c0.43,0,0.86,0.03,1.02-0.5c0.53-1.76,1.07-3.53,1.6-5.29C45.38,39.38,45.18,39.14,44.75,39.08z M25.67,6.53
       c2.53-0.02,4.48,2.07,4.42,4.7c-0.01,0.49-0.18,0.59-0.62,0.58c-1.25-0.02-2.5-0.01-3.75-0.01c-1.28,0-2.56-0.01-3.84,0.01
       c-0.4,0.01-0.55-0.08-0.57-0.53C21.21,8.65,23.11,6.55,25.67,6.53z M15.24,15.31c0.23-1.59,1.23-2.49,2.82-2.55
       c0.09,0,0.18,0,0.27,0c2.25-0.02,2.28,0.03,2.05,2.3c-0.03,0.32-0.2,0.45-0.4,0.64c-0.62,0.59-0.65,1.42-0.12,1.98
       c0.52,0.54,1.39,0.55,1.93,0.04c0.61-0.59,0.6-1.44-0.09-2.03c-0.69-0.59-0.22-1.33-0.36-1.98c-0.17-0.8,0.18-0.98,0.96-0.96
       c2.35,0.05,4.71,0.03,7.06,0.01c0.52,0,0.78,0.09,0.72,0.68c-0.05,0.47-0.03,0.95,0,1.43c0.02,0.36-0.1,0.58-0.38,0.83
       c-0.68,0.6-0.69,1.46-0.09,2.03c0.55,0.52,1.4,0.5,1.92-0.05c0.55-0.58,0.51-1.37-0.12-1.98c-0.31-0.3-0.6-2.39-0.37-2.76
       c0.05-0.08,0.18-0.14,0.28-0.16c2.42-0.63,4.19,0.61,4.52,3.07c0.72,5.31,1.48,10.62,2.25,15.92c0.36,2.45-0.72,3.75-3.22,3.75
       c-3.13,0-6.26,0-9.39,0s-6.26,0-9.39,0c-2.47,0-3.53-1.21-3.19-3.66C13.68,26.34,14.46,20.83,15.24,15.31z M7.77,20.14
       c0-1.99,1.03-3.02,3.03-3.03c0.86-0.01,1.73,0.01,2.59-0.01c0.45-0.01,0.62,0.06,0.54,0.59c-0.69,4.71-1.34,9.43-2.03,14.14
       c-0.4,2.75,1.21,4.65,4.01,4.65c6.38,0.01,12.75,0.01,19.13,0c2.8,0,4.38-1.76,4.01-4.53c-0.61-4.57-1.27-9.14-1.92-13.71
       c-0.16-1.14-0.18-1.08,0.92-1.15c3.2-0.21,4.09,1.09,4.04,4.04c-0.1,5.66-0.05,11.33-0.01,16.99c0.01,0.83-0.24,0.96-0.99,0.96
       c-5.39-0.03-10.79-0.02-16.18-0.02c-5.42,0-10.85-0.01-16.27,0.02c-0.68,0-0.9-0.15-0.89-0.87C7.79,32.18,7.77,26.16,7.77,20.14z
        M42.87,44.3c-0.1,0.36-0.33,0.36-0.61,0.35c-0.69,0-1.37,0-2.06,0c-10.7,0-21.39-0.01-32.09,0.03c-0.87,0-1.18-0.28-1.37-1.05
       c-0.29-1.17-0.68-2.32-1.05-3.54c12.84,0,25.59,0,38.44,0C43.71,41.52,43.28,42.9,42.87,44.3z"/>
     <path class="st0" d="M26.4,28.94c0.08,0.41,0.43,0.65,0.85,0.69c0.41,0.04,0.74-0.13,0.94-0.54c0.13-0.27,0.17-0.61,0.48-0.82
       c0.42,0.42,0.82,0.82,1.22,1.22c1.55,1.55,1.7,1.54,3.22-0.09c0.61-0.66,0.62-1.11-0.01-1.76c-0.62-0.64-1.26-1.25-1.97-1.95
       c0.37-0.17,0.61-0.29,0.85-0.39c0.45-0.19,0.63-0.54,0.58-0.99c-0.06-0.45-0.34-0.76-0.8-0.85c-1.7-0.31-3.39-0.61-5.09-0.88
       c-0.68-0.11-1.25,0.36-1.16,1.01C25.74,25.38,26.06,27.16,26.4,28.94z M27.11,23.63c1.38,0.23,2.75,0.49,4.18,0.75
       c-0.09,0.12-0.12,0.18-0.16,0.2c-1.6,0.87-1.6,0.87-0.27,2.2c0.38,0.38,0.72,0.81,1.15,1.13c0.64,0.48,0.47,0.79-0.03,1.27
       c-0.45,0.44-0.7,0.43-1.1-0.03c-0.57-0.65-1.2-1.24-1.82-1.84c-0.64-0.63-0.84-0.59-1.22,0.2c-0.14,0.29-0.27,0.59-0.52,1.14
       c-0.29-1.74-0.56-3.22-0.79-4.71C26.43,23.34,26.9,23.6,27.11,23.63z"/>
     <path class="st0" d="M22.86,42.8c0.68,0,1.36,0,2.04,0c0.68,0,1.36,0,2.04,0c0.33,0,0.62-0.11,0.61-0.48
       c-0.01-0.3-0.22-0.46-0.54-0.46c-1.39,0-2.78,0-4.17,0c-0.31,0-0.54,0.14-0.56,0.44C22.26,42.68,22.53,42.8,22.86,42.8z"/>
   </g>
   </svg>
   `,
      cat: "Shopify Development",
      desc: `Our experts can develop fast and smooth-running mobile applications and we provide the backend system, user interface, startup solutions, etc.`,
    },
    {
      icon: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
   <g>
     <g>
       <g>
         <path class="st0" d="M34.06,18.07c0-0.12-0.03-0.22-0.13-0.29c-0.05-0.03-0.11-0.05-0.16-0.08c-0.15-0.09-0.3-0.18-0.45-0.27
           c-0.19-0.1-0.36-0.21-0.55-0.31c-0.25-0.14-0.51-0.29-0.76-0.44c-0.23-0.13-0.47-0.26-0.69-0.4c-0.23-0.14-0.47-0.26-0.69-0.41
           c-0.17-0.11-0.35-0.19-0.52-0.29c-0.18-0.11-0.36-0.22-0.54-0.31c-0.2-0.1-0.4-0.23-0.59-0.35c-0.21-0.12-0.42-0.23-0.63-0.36
           c-0.17-0.11-0.36-0.2-0.54-0.31c-0.15-0.08-0.29-0.18-0.44-0.25c-0.24-0.12-0.46-0.27-0.7-0.4c-0.19-0.1-0.38-0.22-0.57-0.33
           c-0.17-0.1-0.35-0.19-0.52-0.29c-0.1-0.06-0.21-0.09-0.33-0.06c-0.12,0.02-0.22,0.1-0.32,0.16c-0.18,0.1-0.36,0.21-0.55,0.31
           c-0.14,0.08-0.27,0.18-0.42,0.24c-0.24,0.11-0.45,0.26-0.68,0.39c-0.21,0.12-0.42,0.24-0.63,0.36c-0.21,0.12-0.42,0.25-0.64,0.37
           c-0.23,0.12-0.46,0.25-0.67,0.39c-0.15,0.1-0.32,0.17-0.46,0.26c-0.31,0.2-0.64,0.36-0.95,0.55c-0.26,0.15-0.52,0.3-0.78,0.45
           c-0.29,0.17-0.57,0.33-0.86,0.5c-0.28,0.16-0.55,0.33-0.83,0.48c-0.18,0.1-0.34,0.22-0.52,0.3c-0.21,0.09-0.31,0.23-0.31,0.48
           c0.01,3.17,0.01,6.35,0.01,9.52c0,0.03,0,0.06,0,0.1c0.01,0.11,0.04,0.21,0.16,0.27c0.25,0.14,0.5,0.29,0.75,0.44
           c0.19,0.11,0.37,0.22,0.56,0.32c0.25,0.13,0.48,0.29,0.73,0.42c0.09,0.05,0.17,0.11,0.26,0.16c0.2,0.12,0.37,0.02,0.37-0.21
           c0-0.02,0-0.04,0-0.06c-0.01-2.02,0.01-4.03-0.02-6.05c-0.01-1.15,0-2.3,0-3.45c0-0.06,0.01-0.1,0.07-0.12
           c0.05-0.02,0.1-0.05,0.15-0.08c0.22-0.13,0.43-0.27,0.66-0.39c0.26-0.14,0.51-0.29,0.77-0.44c0.22-0.12,0.44-0.25,0.66-0.38
           c0.42-0.25,0.85-0.48,1.26-0.73c0.16-0.1,0.33-0.17,0.48-0.27c0.26-0.16,0.52-0.31,0.79-0.45c0.17-0.09,0.33-0.2,0.5-0.29
           c0.17-0.09,0.32-0.19,0.48-0.28c0.04-0.02,0.07-0.02,0.11,0c0.15,0.09,0.3,0.17,0.45,0.26c0.17,0.1,0.35,0.19,0.51,0.3
           c0.22,0.14,0.45,0.25,0.67,0.39c0.2,0.13,0.42,0.22,0.61,0.36c0.12,0.08,0.25,0.13,0.37,0.2c0.21,0.14,0.43,0.25,0.65,0.38
           c0.15,0.09,0.31,0.18,0.46,0.27c0.15,0.08,0.3,0.19,0.45,0.27c0.24,0.13,0.47,0.28,0.71,0.4c0.27,0.14,0.52,0.3,0.79,0.45
           c0.1,0.05,0.14,0.11,0.14,0.24c-0.01,3.14,0,6.28,0,9.42c0,0.04,0,0.07,0,0.11c0.02,0.18,0.18,0.27,0.34,0.18
           c0.2-0.11,0.4-0.22,0.6-0.34c0.27-0.16,0.55-0.31,0.82-0.47c0.3-0.18,0.61-0.35,0.92-0.53c0.14-0.08,0.18-0.18,0.18-0.33
           c0-1.6,0-3.21,0-4.81c0-0.08,0-0.15,0-0.23C34.06,21.14,34.06,19.61,34.06,18.07z M33.63,20.46c0,2.35,0,4.69,0,7.04
           c0,0.2,0,0.2-0.17,0.3c-0.21,0.12-0.42,0.24-0.63,0.36c-0.2,0.12-0.41,0.24-0.61,0.35c-0.2,0.11-0.39,0.22-0.6,0.34
           c0-0.04,0-0.08,0-0.11c0-3.07,0-6.15,0-9.22c0-0.19-0.09-0.29-0.25-0.38c-0.16-0.09-0.32-0.17-0.48-0.28
           c-0.11-0.08-0.24-0.13-0.36-0.2c-0.11-0.06-0.22-0.13-0.34-0.19c-0.22-0.12-0.44-0.25-0.66-0.38c-0.17-0.1-0.34-0.21-0.51-0.3
           c-0.25-0.13-0.5-0.29-0.75-0.42c-0.28-0.15-0.54-0.33-0.82-0.48c-0.2-0.11-0.4-0.23-0.61-0.35c-0.31-0.18-0.62-0.36-0.93-0.54
           c-0.14-0.08-0.29-0.17-0.43-0.25c-0.09-0.05-0.19-0.05-0.28,0c-0.16,0.08-0.31,0.18-0.47,0.26c-0.23,0.13-0.45,0.27-0.69,0.4
           c-0.14,0.07-0.28,0.17-0.42,0.25c-0.36,0.21-0.72,0.41-1.08,0.62c-0.26,0.15-0.52,0.31-0.78,0.45c-0.23,0.13-0.45,0.27-0.68,0.39
           c-0.16,0.09-0.33,0.19-0.49,0.29c-0.16,0.1-0.34,0.19-0.51,0.28c-0.18,0.1-0.35,0.21-0.53,0.32c-0.13,0.08-0.28,0.14-0.41,0.23
           c-0.08,0.05-0.12,0.11-0.12,0.2c0,0.03,0,0.05,0,0.08c0,3.07,0,6.14,0,9.21c0,0.03,0,0.07,0,0.12c-0.2-0.11-0.39-0.22-0.58-0.32
           c-0.26-0.15-0.53-0.3-0.79-0.46c-0.18-0.11-0.38-0.2-0.56-0.33c-0.02-0.02-0.03-0.03-0.03-0.05c0-0.02,0-0.04,0-0.07
           c0-3.12,0-6.24,0-9.37c0-0.09,0.03-0.13,0.1-0.17c0.24-0.13,0.46-0.28,0.7-0.41c0.22-0.11,0.42-0.26,0.64-0.36
           c0.1-0.04,0.18-0.11,0.28-0.16c0.19-0.11,0.37-0.22,0.56-0.32c0.28-0.15,0.55-0.34,0.83-0.49c0.25-0.13,0.48-0.29,0.74-0.42
           c0.15-0.07,0.28-0.17,0.43-0.25c0.14-0.07,0.26-0.15,0.4-0.23c0.16-0.09,0.31-0.18,0.46-0.27c0.18-0.11,0.37-0.2,0.55-0.31
           c0.21-0.13,0.42-0.24,0.63-0.37c0.19-0.12,0.39-0.23,0.58-0.34c0.18-0.11,0.36-0.22,0.55-0.32c0.17-0.09,0.33-0.21,0.51-0.28
           c0.1-0.04,0.2-0.11,0.29-0.18c0.03-0.02,0.04-0.01,0.07,0c0.35,0.2,0.7,0.41,1.05,0.61c0.36,0.21,0.72,0.42,1.07,0.62
           c0.26,0.15,0.52,0.29,0.77,0.44c0.26,0.15,0.52,0.31,0.79,0.46c0.3,0.18,0.62,0.34,0.91,0.52c0.26,0.16,0.53,0.3,0.78,0.46
           c0.25,0.16,0.52,0.29,0.78,0.44c0.21,0.12,0.41,0.24,0.62,0.36c0.26,0.15,0.52,0.29,0.77,0.44c0.22,0.12,0.43,0.25,0.65,0.37
           c0.05,0.03,0.05,0.07,0.05,0.12C33.63,18.95,33.63,19.7,33.63,20.46z"/>
         <path class="st0" d="M19.26,29.88c-0.13,0-0.26-0.04-0.39-0.11c-0.04-0.02-0.08-0.05-0.13-0.08c-0.04-0.03-0.08-0.05-0.12-0.07
           c-0.13-0.07-0.26-0.14-0.38-0.22c-0.12-0.07-0.24-0.14-0.35-0.2c-0.13-0.07-0.26-0.15-0.38-0.22c-0.06-0.04-0.13-0.08-0.19-0.11
           c-0.1-0.06-0.2-0.12-0.3-0.18c-0.15-0.09-0.29-0.18-0.44-0.25c-0.23-0.12-0.36-0.33-0.39-0.61c-0.01-0.05,0-0.1,0-0.13l0-0.02
           l0-1.92c0-2.49,0-5.07-0.01-7.6c0-0.43,0.2-0.74,0.59-0.89c0.08-0.03,0.17-0.09,0.26-0.14c0.07-0.04,0.14-0.09,0.22-0.13
           c0.2-0.11,0.41-0.23,0.6-0.34l0.22-0.13c0.13-0.08,0.26-0.15,0.4-0.23c0.15-0.09,0.31-0.18,0.46-0.27
           c0.09-0.05,0.19-0.11,0.28-0.16c0.16-0.09,0.33-0.19,0.49-0.28c0.14-0.08,0.28-0.16,0.41-0.24c0.18-0.1,0.36-0.2,0.52-0.31
           c0.09-0.06,0.18-0.11,0.27-0.15c0.07-0.04,0.14-0.08,0.2-0.11c0.22-0.14,0.45-0.27,0.7-0.4c0.15-0.08,0.31-0.17,0.46-0.26
           l0.8-0.46c0.07-0.04,0.14-0.08,0.21-0.12c0.16-0.1,0.32-0.2,0.51-0.28c0.06-0.03,0.12-0.07,0.19-0.11
           c0.06-0.04,0.13-0.08,0.21-0.12c0.1-0.05,0.2-0.11,0.3-0.17c0.08-0.05,0.16-0.09,0.24-0.14c0.02-0.01,0.04-0.03,0.06-0.04
           c0.09-0.06,0.22-0.14,0.4-0.17c0.22-0.04,0.44,0,0.64,0.12c0.09,0.05,0.18,0.1,0.28,0.16c0.08,0.04,0.15,0.08,0.23,0.13
           c0.08,0.04,0.16,0.09,0.23,0.14c0.11,0.07,0.21,0.13,0.32,0.19c0.12,0.06,0.23,0.13,0.34,0.2c0.12,0.07,0.23,0.14,0.35,0.2
           c0.11,0.05,0.21,0.12,0.3,0.17c0.05,0.03,0.1,0.06,0.15,0.09c0.06,0.03,0.12,0.06,0.18,0.1c0.13,0.07,0.26,0.14,0.39,0.22
           c0.11,0.07,0.23,0.14,0.36,0.21c0.08,0.05,0.17,0.09,0.25,0.14c0.06,0.03,0.12,0.07,0.17,0.1c0.14,0.08,0.26,0.16,0.39,0.23
           c0.14,0.07,0.27,0.15,0.4,0.23c0.06,0.03,0.11,0.07,0.17,0.1c0.06,0.04,0.13,0.07,0.19,0.1c0.11,0.06,0.23,0.12,0.35,0.2
           c0.11,0.07,0.23,0.14,0.36,0.21c0.1,0.06,0.21,0.12,0.32,0.19c0.14,0.09,0.28,0.16,0.43,0.25c0.08,0.05,0.17,0.09,0.25,0.14
           c0.11,0.06,0.22,0.13,0.32,0.19c0.14,0.08,0.29,0.17,0.43,0.25c0.06,0.04,0.13,0.07,0.19,0.11c0.12,0.07,0.23,0.13,0.34,0.19
           c0.11,0.06,0.22,0.12,0.32,0.18c0.05,0.03,0.1,0.06,0.15,0.09c0.01,0.01,0.02,0.01,0.03,0.02c0.04,0.02,0.1,0.05,0.16,0.09
           c0.15,0.1,0.32,0.3,0.32,0.66v0c0,1.23,0,2.46,0,3.68l0,1.15l0,1.01c0,1.27,0,2.53,0,3.8c0,0.31-0.14,0.55-0.4,0.71
           c-0.14,0.08-0.27,0.16-0.41,0.23c-0.17,0.1-0.34,0.19-0.51,0.29c-0.15,0.09-0.3,0.18-0.46,0.27c-0.12,0.07-0.24,0.14-0.37,0.21
           c-0.14,0.08-0.28,0.16-0.43,0.24l-0.18,0.1c-0.2,0.11-0.43,0.12-0.63,0.02c-0.2-0.1-0.34-0.3-0.37-0.53
           c-0.01-0.06-0.01-0.11-0.01-0.15l0-0.02l0-1.11c0-2.69,0-5.47,0-8.21c-0.07-0.04-0.15-0.09-0.22-0.13
           c-0.16-0.1-0.32-0.19-0.48-0.27c-0.12-0.07-0.24-0.14-0.36-0.2c-0.12-0.07-0.24-0.14-0.35-0.2c-0.1-0.05-0.19-0.11-0.28-0.16
           c-0.06-0.04-0.12-0.08-0.18-0.11c-0.1-0.06-0.2-0.12-0.31-0.18c-0.05-0.03-0.11-0.07-0.16-0.1c-0.07-0.04-0.14-0.08-0.21-0.12
           c-0.15-0.08-0.31-0.17-0.47-0.28c-0.03-0.02-0.08-0.05-0.13-0.07c-0.07-0.04-0.15-0.08-0.23-0.13c-0.09-0.06-0.19-0.12-0.3-0.18
           c-0.1-0.05-0.2-0.11-0.3-0.17c-0.1-0.06-0.21-0.12-0.32-0.18c-0.11-0.06-0.23-0.13-0.35-0.2c-0.09-0.05-0.18-0.11-0.27-0.16
           c-0.08-0.04-0.16-0.09-0.24-0.14c-0.09-0.05-0.18-0.11-0.28-0.16c-0.11,0.06-0.22,0.13-0.33,0.19c-0.1,0.05-0.19,0.11-0.29,0.17
           c-0.06,0.04-0.13,0.08-0.19,0.11c-0.06,0.04-0.13,0.07-0.19,0.11c-0.2,0.11-0.39,0.22-0.57,0.33c-0.1,0.06-0.2,0.12-0.3,0.17
           c-0.07,0.04-0.13,0.07-0.2,0.11c-0.22,0.13-0.44,0.26-0.65,0.38c-0.2,0.12-0.41,0.23-0.61,0.35l-0.02,0.01
           c-0.21,0.13-0.43,0.26-0.65,0.38c-0.1,0.06-0.2,0.11-0.29,0.17c-0.16,0.09-0.32,0.19-0.49,0.27c-0.14,0.07-0.27,0.15-0.41,0.24
           c-0.08,0.05-0.15,0.09-0.23,0.14l0,0c0,0.34,0,0.68,0,1.01c0,0.74,0,1.5,0.01,2.25c0.01,1.23,0.01,2.47,0.01,3.67
           c0,0.79,0,1.59,0,2.38l0,0.02c0,0.02,0,0.04,0,0.06c-0.01,0.27-0.13,0.49-0.34,0.61C19.48,29.85,19.37,29.88,19.26,29.88z
            M25.33,15.24c0.12,0,0.24,0.03,0.35,0.09c0.11,0.06,0.21,0.12,0.3,0.18c0.05,0.03,0.1,0.06,0.14,0.09l0.23,0.13
           c0.23,0.14,0.47,0.27,0.7,0.41c0.08,0.04,0.15,0.09,0.22,0.13c0.12,0.07,0.25,0.14,0.37,0.21c0.14,0.08,0.28,0.16,0.42,0.24
           c0.14,0.08,0.27,0.16,0.4,0.24c0.13,0.07,0.25,0.14,0.37,0.21c0.13,0.08,0.25,0.15,0.37,0.21c0.15,0.08,0.29,0.16,0.42,0.25
           l0.12,0.07c0.25,0.15,0.45,0.27,0.64,0.37c0.06,0.04,0.13,0.07,0.19,0.11c0.05,0.03,0.1,0.06,0.15,0.09
           c0.03,0.02,0.07,0.04,0.1,0.05c0.09,0.05,0.19,0.1,0.29,0.17c0.12,0.09,0.26,0.16,0.41,0.24l0.03,0.01
           c0.32,0.17,0.48,0.43,0.48,0.77c0,2.48,0,4.96,0,7.44l0,1.14c0.12-0.07,0.23-0.13,0.35-0.2c0.06-0.04,0.13-0.08,0.19-0.11
           l0.09-0.05c0.16-0.09,0.33-0.19,0.49-0.28v-7.39c0-0.56,0-1.12,0-1.68c-0.07-0.04-0.14-0.08-0.21-0.12
           c-0.09-0.05-0.17-0.1-0.26-0.15c-0.11-0.06-0.23-0.13-0.34-0.19c-0.15-0.08-0.29-0.16-0.44-0.25c-0.1-0.06-0.21-0.12-0.31-0.18
           c-0.1-0.06-0.21-0.12-0.31-0.18c-0.09-0.05-0.18-0.1-0.27-0.15c-0.17-0.09-0.35-0.19-0.53-0.3c-0.12-0.08-0.25-0.15-0.39-0.23
           c-0.13-0.07-0.26-0.15-0.39-0.23c-0.18-0.11-0.36-0.21-0.56-0.32c-0.11-0.06-0.23-0.13-0.34-0.19c-0.14-0.08-0.28-0.17-0.42-0.25
           c-0.12-0.07-0.24-0.14-0.36-0.21c-0.14-0.08-0.28-0.16-0.42-0.24c-0.12-0.07-0.23-0.13-0.35-0.2c-0.24-0.14-0.47-0.27-0.71-0.41
           l-0.63-0.36c-0.2-0.11-0.4-0.23-0.59-0.34c-0.05,0.03-0.11,0.06-0.17,0.09c-0.07,0.03-0.15,0.08-0.23,0.13
           c-0.07,0.05-0.16,0.1-0.25,0.15c-0.12,0.06-0.23,0.13-0.35,0.2c-0.06,0.04-0.12,0.07-0.18,0.1c-0.06,0.04-0.13,0.07-0.19,0.11
           c-0.13,0.07-0.26,0.15-0.38,0.22c-0.12,0.08-0.24,0.14-0.36,0.21c-0.09,0.05-0.18,0.1-0.27,0.16c-0.1,0.06-0.2,0.12-0.3,0.17
           c-0.08,0.05-0.16,0.09-0.25,0.14c-0.13,0.08-0.3,0.18-0.48,0.28c-0.05,0.03-0.1,0.06-0.15,0.09c-0.08,0.05-0.17,0.1-0.26,0.15
           c-0.05,0.03-0.11,0.06-0.17,0.1c-0.08,0.05-0.17,0.1-0.26,0.15c-0.13,0.06-0.25,0.14-0.38,0.21c-0.11,0.07-0.22,0.13-0.34,0.2
           c-0.14,0.07-0.28,0.16-0.42,0.25c-0.13,0.08-0.26,0.16-0.4,0.24c-0.11,0.06-0.22,0.12-0.33,0.19c-0.08,0.04-0.15,0.09-0.23,0.13
           c-0.03,0.02-0.06,0.03-0.08,0.05c-0.07,0.04-0.14,0.09-0.23,0.13c-0.1,0.05-0.21,0.11-0.32,0.18c-0.09,0.06-0.19,0.12-0.3,0.17
           c-0.13,0.07-0.25,0.14-0.39,0.22c-0.06,0.04-0.12,0.07-0.18,0.11c0,2.66,0,5.31,0,7.97v1.1c0.04,0.02,0.08,0.05,0.13,0.07
           c0.08,0.04,0.17,0.09,0.26,0.15c0.2,0.12,0.41,0.24,0.61,0.36l0.08,0.05l0-8.59c0-0.03,0-0.06,0-0.09
           c0.01-0.16,0.07-0.39,0.32-0.55c0.09-0.06,0.18-0.11,0.27-0.15c0.05-0.03,0.1-0.06,0.16-0.09c0.07-0.04,0.15-0.09,0.22-0.14
           c0.1-0.06,0.21-0.13,0.31-0.19c0.08-0.04,0.15-0.09,0.23-0.13c0.09-0.05,0.18-0.1,0.27-0.15c0.16-0.1,0.33-0.2,0.5-0.3
           c0.1-0.06,0.2-0.12,0.3-0.18c0.12-0.07,0.25-0.15,0.38-0.22c0.17-0.09,0.34-0.19,0.51-0.29c0.09-0.05,0.18-0.1,0.27-0.16
           l0.08-0.05c0.33-0.19,0.67-0.39,1-0.58c0.05-0.03,0.11-0.06,0.16-0.1c0.09-0.05,0.18-0.11,0.28-0.16
           c0.11-0.06,0.23-0.13,0.35-0.2c0.11-0.06,0.22-0.13,0.33-0.19c0.07-0.04,0.13-0.07,0.19-0.11c0.09-0.05,0.18-0.11,0.28-0.16
           C25.1,15.27,25.22,15.24,25.33,15.24z"/>
       </g>
       <g>
         <g>
           <path class="st0" d="M29.2,20.8c0-0.04,0-0.08,0-0.12c-0.01-0.06-0.02-0.13-0.08-0.17c-0.11-0.07-0.21-0.14-0.32-0.19
             c-0.17-0.07-0.31-0.19-0.47-0.28c-0.06-0.04-0.13-0.05-0.19-0.09c-0.25-0.17-0.51-0.31-0.77-0.45
             c-0.21-0.12-0.42-0.25-0.64-0.36c-0.1-0.05-0.19-0.07-0.29-0.03c-0.1,0.05-0.1,0.15-0.1,0.24c0,3.16,0,6.33,0,9.49
             c0,0.02,0,0.05,0,0.07c0.01,0.05-0.01,0.07-0.06,0.1c-0.11,0.06-0.21,0.12-0.32,0.19c-0.17,0.09-0.33,0.2-0.5,0.29
             c-0.1,0.06-0.17,0.04-0.25-0.01c-0.26-0.15-0.52-0.3-0.78-0.44c-0.07-0.04-0.09-0.09-0.09-0.17c0-3.15,0-6.31,0-9.46
             c0-0.04,0-0.08-0.01-0.12c-0.03-0.18-0.21-0.25-0.36-0.15c-0.21,0.13-0.42,0.25-0.63,0.37c-0.21,0.12-0.43,0.24-0.64,0.36
             c-0.18,0.1-0.36,0.2-0.53,0.32c-0.17,0.11-0.35,0.18-0.51,0.29c-0.06,0.04-0.14,0.06-0.16,0.14c-0.02,0.06-0.02,0.12-0.02,0.18
             c0,1.61,0,3.21,0,4.82c0,0,0,0,0,0c0,1.61,0,3.21,0,4.82c0,0.04,0,0.07,0.01,0.11c0.01,0.06,0.03,0.12,0.09,0.15
             c0.38,0.22,0.75,0.44,1.13,0.66c0.28,0.16,0.56,0.32,0.84,0.48c0.21,0.12,0.41,0.24,0.62,0.36c0.25,0.15,0.51,0.3,0.76,0.44
             c0.1,0.06,0.19,0.12,0.29,0.16c0.1,0.05,0.2,0.05,0.3-0.01c0.26-0.15,0.53-0.31,0.79-0.46c0.2-0.12,0.41-0.23,0.61-0.35
             c0.22-0.14,0.45-0.26,0.68-0.39c0.16-0.09,0.32-0.17,0.47-0.27c0.3-0.19,0.6-0.36,0.91-0.53c0.16-0.09,0.23-0.19,0.23-0.38
             C29.2,27.2,29.21,24,29.2,20.8z M28.68,30.45c-0.24,0.1-0.44,0.26-0.67,0.38c-0.18,0.09-0.35,0.19-0.52,0.3
             c-0.19,0.12-0.39,0.24-0.59,0.34c-0.24,0.13-0.47,0.28-0.71,0.41c-0.22,0.12-0.46,0.24-0.66,0.39c-0.12,0.09-0.21,0.09-0.33,0
             c-0.18-0.12-0.38-0.23-0.57-0.33c-0.29-0.17-0.59-0.33-0.88-0.5c-0.21-0.13-0.43-0.25-0.64-0.38c-0.21-0.12-0.42-0.26-0.64-0.36
             c-0.1-0.05-0.19-0.12-0.29-0.18c-0.06-0.03-0.12-0.05-0.17-0.08c-0.06-0.03-0.08-0.06-0.08-0.13c0-3.14,0-6.27,0-9.41
             c0-0.06,0.01-0.09,0.07-0.12c0.3-0.16,0.6-0.33,0.89-0.5c0.3-0.17,0.59-0.35,0.89-0.52c0.05-0.03,0.06-0.05,0.16-0.1
             c0,0.07,0,0.11,0,0.14c0,3.07,0,6.15,0,9.22c0,0.18,0.08,0.28,0.23,0.35c0.22,0.11,0.44,0.24,0.65,0.37
             c0.08,0.05,0.17,0.08,0.25,0.14c0.05,0.03,0.1,0.06,0.15,0.1c0.07,0.07,0.2,0.08,0.28,0.02c0.26-0.18,0.55-0.31,0.81-0.48
             c0.13-0.08,0.27-0.16,0.4-0.24c0.04-0.02,0.06-0.06,0.07-0.11c0.01-0.06,0.02-0.11,0.02-0.17c0-3.07,0-6.14,0-9.21
             c0-0.03,0-0.05,0-0.08c0-0.05,0.01-0.05,0.06-0.03c0.36,0.22,0.72,0.42,1.08,0.62c0.28,0.16,0.55,0.32,0.83,0.48
             c0.02,0.01,0.03,0.03,0.03,0.05c0,0.02,0,0.04,0,0.06c0,3.13,0,6.26,0,9.4C28.78,30.38,28.75,30.42,28.68,30.45z"/>
           <path class="st0" d="M25.35,33.26c-0.11,0-0.23-0.03-0.33-0.08c-0.09-0.04-0.17-0.09-0.25-0.14l-0.41-0.24
             c-0.14-0.08-0.28-0.17-0.42-0.25c-0.21-0.12-0.41-0.24-0.62-0.36c-0.11-0.06-0.22-0.12-0.33-0.19c-0.17-0.1-0.34-0.19-0.51-0.29
             c-0.28-0.16-0.56-0.32-0.83-0.49l-0.3-0.18c-0.1-0.06-0.27-0.2-0.3-0.49c0-0.05-0.01-0.1-0.01-0.15l0-5.62c0-1.34,0-2.68,0-4.01
             c0-0.09,0-0.19,0.03-0.3c0.07-0.24,0.26-0.34,0.33-0.38l0.01,0c0.12-0.09,0.23-0.15,0.34-0.2c0.07-0.04,0.13-0.07,0.19-0.1
             c0.15-0.1,0.31-0.19,0.46-0.27l0.1-0.05c0.11-0.06,0.21-0.12,0.32-0.18c0.11-0.06,0.21-0.12,0.32-0.18l0.05-0.03
             c0.19-0.11,0.38-0.22,0.57-0.33c0.2-0.12,0.44-0.14,0.65-0.05c0.2,0.09,0.34,0.28,0.38,0.51c0.01,0.08,0.01,0.14,0.01,0.19
             c0,3.11,0,6.21,0,9.32c0.19,0.11,0.38,0.22,0.57,0.33c0.05-0.03,0.09-0.06,0.14-0.08c0.09-0.05,0.17-0.1,0.26-0.15
             c0.04-0.02,0.08-0.05,0.13-0.07c0.01,0,0.02-0.01,0.02-0.01l0-9.37c0-0.12,0-0.48,0.36-0.64c0.22-0.1,0.45-0.09,0.69,0.04
             c0.16,0.09,0.32,0.18,0.47,0.27l0.17,0.1c0.07,0.04,0.13,0.08,0.2,0.11c0.19,0.11,0.39,0.22,0.59,0.35
             c0.01,0,0.02,0.01,0.02,0.01c0.04,0.02,0.09,0.04,0.15,0.07c0.06,0.03,0.11,0.07,0.17,0.11c0.09,0.06,0.17,0.11,0.25,0.14
             c0.12,0.05,0.22,0.11,0.3,0.17c0.03,0.02,0.05,0.03,0.08,0.05c0.23,0.14,0.27,0.37,0.28,0.46l0,0.01
             c0.01,0.07,0.01,0.13,0.01,0.17l0,0.02v1.92c0,2.56,0,5.12,0,7.68c0,0.34-0.16,0.59-0.46,0.76l-0.13,0.07
             c-0.26,0.15-0.51,0.29-0.76,0.44c-0.11,0.07-0.21,0.13-0.32,0.19c-0.06,0.03-0.11,0.06-0.17,0.09c-0.08,0.05-0.17,0.1-0.25,0.14
             c-0.14,0.08-0.28,0.16-0.41,0.24c-0.14,0.09-0.28,0.16-0.41,0.24c-0.07,0.04-0.14,0.08-0.22,0.12c-0.12,0.07-0.23,0.14-0.35,0.2
             c-0.15,0.09-0.29,0.17-0.44,0.26C25.61,33.23,25.48,33.26,25.35,33.26z M22.36,30.12c0.01,0.01,0.02,0.01,0.03,0.02
             c0.05,0.03,0.1,0.06,0.14,0.09c0.04,0.03,0.09,0.06,0.12,0.07c0.17,0.08,0.32,0.17,0.47,0.26c0.07,0.04,0.13,0.08,0.2,0.12
             c0.24,0.14,0.45,0.26,0.66,0.38c0.18,0.11,0.36,0.21,0.55,0.32c0.1,0.06,0.21,0.12,0.31,0.18c0.04,0.02,0.09,0.05,0.13,0.07
             c0.12,0.07,0.25,0.14,0.38,0.22c0.17-0.11,0.34-0.21,0.51-0.3l0.12-0.06c0.1-0.06,0.2-0.12,0.31-0.18
             c0.13-0.08,0.27-0.16,0.41-0.23c0.18-0.1,0.37-0.21,0.55-0.33c0.18-0.11,0.37-0.22,0.56-0.32c0.08-0.04,0.17-0.09,0.25-0.15
             c0.08-0.05,0.17-0.11,0.27-0.16c0-2.74,0-5.48,0-8.22v-0.84c-0.05-0.03-0.1-0.06-0.15-0.09c-0.16-0.09-0.32-0.19-0.48-0.28
             c-0.15-0.08-0.31-0.18-0.48-0.27c0,2.86,0,5.72,0,8.58c0,0.1-0.01,0.18-0.03,0.27c-0.04,0.17-0.14,0.31-0.28,0.39
             c-0.07,0.04-0.13,0.08-0.2,0.12c-0.07,0.04-0.13,0.08-0.2,0.12c-0.11,0.07-0.23,0.13-0.34,0.19c-0.16,0.09-0.31,0.17-0.45,0.27
             c-0.25,0.17-0.61,0.14-0.83-0.07c-0.01,0-0.02-0.01-0.03-0.02c-0.02-0.01-0.04-0.02-0.06-0.04c-0.02-0.01-0.05-0.03-0.09-0.05
             c-0.05-0.02-0.1-0.05-0.15-0.08c-0.18-0.11-0.4-0.24-0.62-0.35c-0.31-0.16-0.47-0.41-0.47-0.75c0-2.52,0-5.04,0-7.56l0-1.02
             c-0.13,0.07-0.25,0.15-0.38,0.22c-0.24,0.14-0.49,0.28-0.74,0.42C22.36,24.09,22.36,27.1,22.36,30.12z M25.9,28.97
             C25.9,28.97,25.9,28.97,25.9,28.97C25.9,28.97,25.9,28.97,25.9,28.97z M26.08,28.62C26.08,28.62,26.08,28.62,26.08,28.62
             C26.08,28.62,26.08,28.62,26.08,28.62z M21.4,20.11C21.4,20.11,21.4,20.11,21.4,20.11C21.4,20.11,21.4,20.11,21.4,20.11z
              M28.39,19.6C28.39,19.6,28.39,19.6,28.39,19.6C28.39,19.6,28.39,19.6,28.39,19.6z"/>
         </g>
       </g>
     </g>
     <g>
       <path class="st0" d="M45.71,34.74c-0.62,0.02-1.25-0.02-1.87,0.02c-0.42,0.03-0.49-0.12-0.49-0.5c0.01-7.47,0.01-14.93,0.01-22.4
         c0-0.28,0.02-0.55-0.06-0.82c-0.26-0.92-0.99-1.43-2.08-1.43c-10.59,0-21.18,0-31.77,0c-0.1,0-0.19,0-0.29,0
         C8.44,9.67,7.88,10,7.53,10.65C7.33,11,7.32,11.39,7.32,11.78c0,7.52,0,15.04,0.01,22.56c0,0.32-0.06,0.43-0.4,0.42
         c-0.65-0.03-1.3-0.01-1.95-0.01c-0.66,0-0.89,0.24-0.87,0.91c0.01,0.25,0.04,0.5,0.08,0.74c0.4,2.53,2.53,4.6,5.25,4.59
         c10.62-0.04,21.24-0.02,31.86-0.01c2.03,0,3.49-0.95,4.52-2.64c0.4-0.65,0.56-1.39,0.77-2.11c0-0.33,0-0.66,0-1
         C46.4,34.87,46.11,34.73,45.71,34.74z M9.5,10.91c10.55,0,21.1,0,31.65,0c0.78,0,0.94,0.16,0.94,0.95c0,7.48,0,14.96,0.01,22.44
         c0,0.35-0.06,0.46-0.44,0.46c-5.45-0.01-10.9-0.01-16.35-0.01c-5.41,0-10.81,0-16.22,0.01c-0.38,0-0.51-0.06-0.51-0.48
         c0.01-7.48,0-14.96,0-22.44C8.58,11.07,8.74,10.91,9.5,10.91z M30.06,36.38c-0.32,0.52-0.77,0.83-1.39,0.83
         c-2.22,0.01-4.45,0.01-6.67,0c-0.71,0-1.27-0.45-1.49-1.15c2.11,0,4.2,0,6.28,0c1.02,0,2.04,0.01,3.07,0
         C30.16,36.06,30.2,36.14,30.06,36.38z M45.2,36.43c-0.39,1.94-1.99,3.25-4.03,3.25c-5.28,0-10.56,0-15.84,0s-10.56,0-15.84,0
         c-2.04,0-3.66-1.35-4.02-3.31c-0.05-0.26-0.01-0.33,0.26-0.33c4.37,0.01,8.74,0.01,13.11,0c0.24,0,0.32,0.08,0.38,0.31
         c0.33,1.21,1.35,2.1,2.61,2.12c2.34,0.05,4.67,0.05,7.01,0c1.26-0.02,2.3-0.9,2.62-2.11c0.08-0.28,0.2-0.33,0.45-0.33
         c4.33,0.01,8.65,0.01,12.98,0C45.23,36.04,45.27,36.12,45.2,36.43z"/>
     </g>
   </g>
   </svg>
   `,
      cat: "Magento Development",
      desc: `We develop an inspiring PWA that works seamlessly and works without causing a problem and can be used on all devices.`,
    },
    {
      icon: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
   <g>
     <path class="st0" d="M45.91,35.14c-0.65-0.01-1.31-0.03-1.96,0.01c-0.4,0.02-0.53-0.06-0.53-0.51c0.02-7.51,0.01-15.02,0.01-22.53
       c0-0.28,0.02-0.55-0.06-0.83c-0.26-0.92-1-1.43-2.09-1.43c-10.65,0-21.3,0-31.96,0c-0.1,0-0.2,0-0.29,0
       c-0.71,0.06-1.27,0.38-1.63,1.01c-0.21,0.37-0.23,0.76-0.23,1.17c0,7.56,0,15.13,0.01,22.69c0,0.31-0.06,0.43-0.4,0.42
       c-0.65-0.03-1.31,0.01-1.96-0.01c-0.41-0.01-0.7,0.13-0.88,0.49c0,0.19,0,0.39,0,0.58c0.12,0.13,0.1,0.28,0.07,0.43
       c0.27,2.44,2.38,4.78,5.22,4.78c10.72-0.03,21.44-0.01,32.16-0.01c0.3,0,0.62,0,0.91-0.06c2.52-0.48,4.43-2.87,4.36-5.46
       C46.66,35.4,46.41,35.14,45.91,35.14z M9.35,11.16c10.63,0,21.25,0,31.88,0c0.76,0,0.93,0.17,0.93,0.93c0,7.52,0,15.05,0.01,22.57
       c0,0.37-0.07,0.48-0.47,0.48c-5.47-0.01-10.93-0.01-16.4-0.01c-5.45,0-10.9-0.01-16.36,0.01c-0.42,0-0.49-0.12-0.49-0.51
       c0.01-7.52,0-15.05,0-22.57C8.45,11.32,8.62,11.16,9.35,11.16z M30.15,36.46c-0.22,0.72-0.77,1.15-1.46,1.16
       c-2.25,0.01-4.5,0.01-6.75,0c-0.6,0-1.05-0.31-1.37-0.81c-0.17-0.28-0.12-0.36,0.22-0.36c2.01,0.02,4.03,0.01,6.04,0.02
       C27.92,36.46,29.02,36.46,30.15,36.46z M45.3,36.75c-0.35,1.98-2,3.35-4.07,3.35c-5.31,0-10.62,0-15.93,0s-10.62,0-15.93,0
       c-2.05,0-3.68-1.36-4.04-3.33c-0.05-0.26,0-0.33,0.27-0.33c4.39,0.01,8.79,0.01,13.18,0c0.25,0,0.32,0.08,0.38,0.31
       c0.34,1.22,1.36,2.11,2.63,2.13c2.35,0.05,4.7,0.05,7.05,0c1.29-0.03,2.3-0.91,2.65-2.16c0.06-0.22,0.14-0.29,0.36-0.29
       c4.41,0.01,8.81,0.01,13.22,0C45.32,36.43,45.34,36.51,45.3,36.75z"/>
     <path class="st0" d="M3.95,36.2c0,0.14,0,0.28,0,0.42l0.03,0.02l0.04,0C3.94,36.5,4.04,36.33,3.95,36.2z"/>
     <path class="st0" d="M15.68,26.58c-0.43,1.59,0.26,2.8,1.85,3.21c1.2,0.31,2.4,0.2,3.59-0.07c0.34-0.08,0.47-0.02,0.57,0.33
       c0.33,1.07,0.77,2.08,1.52,2.93c1.27,1.45,2.94,1.43,4.18-0.05c0.73-0.86,1.17-1.88,1.5-2.95c0.09-0.3,0.21-0.35,0.5-0.28
       c0.67,0.17,1.37,0.25,1.99,0.25c0.52,0.01,0.96-0.02,1.4-0.1c1.77-0.33,2.58-1.66,2.07-3.38c-0.35-1.16-1.04-2.11-1.85-2.98
       c-0.22-0.24-0.21-0.36,0-0.59c0.82-0.88,1.5-1.86,1.86-3.02c0.52-1.68-0.21-2.96-1.91-3.38c-1.19-0.29-2.38-0.17-3.55,0.1
       c-0.32,0.07-0.42-0.01-0.51-0.3c-0.33-1.08-0.77-2.11-1.52-2.98c-1.24-1.44-2.91-1.42-4.15,0.03c-0.73,0.86-1.19,1.87-1.51,2.94
       c-0.1,0.33-0.24,0.38-0.55,0.3c-1.06-0.25-2.14-0.34-3.23-0.17c-1.93,0.31-2.8,1.74-2.17,3.59c0.37,1.1,1.03,2.03,1.81,2.87
       c0.2,0.22,0.22,0.33,0.01,0.56C16.74,24.35,16.01,25.35,15.68,26.58z M29.69,17.86c0.6-0.1,1.2-0.21,1.82-0.21
       c0.47,0,0.94,0.01,1.4,0.17c0.71,0.26,0.99,0.73,0.8,1.46c-0.29,1.11-0.99,1.97-1.72,2.81c-0.12,0.14-0.2,0.08-0.3-0.01
       c-0.55-0.49-1.12-0.95-1.72-1.38c-0.13-0.1-0.2-0.21-0.22-0.38c-0.06-0.69-0.16-1.38-0.32-2.05C29.35,18.01,29.43,17.9,29.69,17.86
       z M30.93,23.14c-0.34,0.29-0.65,0.55-1.03,0.87c0-0.61,0-1.13,0-1.74C30.27,22.59,30.59,22.86,30.93,23.14z M29.39,28.09
       c0.15-0.73,0.28-1.47,0.34-2.22c0.01-0.15,0.1-0.21,0.2-0.29c0.56-0.45,1.12-0.9,1.69-1.35c0.09-0.07,0.17-0.24,0.33-0.06
       c0.72,0.81,1.42,1.64,1.71,2.71c0.24,0.87-0.1,1.42-0.98,1.64c-0.38,0.1-0.77,0.11-1.16,0.1c-0.65,0-1.3-0.1-1.94-0.23
       C29.4,28.35,29.35,28.28,29.39,28.09z M22.33,26.69c0.46,0.26,0.91,0.52,1.42,0.81c-0.1,0.05-0.16,0.08-0.22,0.11
       C22.4,28.06,22.27,27.97,22.33,26.69z M27.72,29.45c-0.31,0.89-0.64,1.77-1.24,2.52c-0.3,0.37-0.63,0.7-1.01,0.73
       c-0.47,0.01-0.74-0.13-0.97-0.34c-0.86-0.8-1.25-1.87-1.62-2.95c-0.05-0.15,0.03-0.18,0.15-0.23c0.71-0.27,1.42-0.55,2.12-0.84
       c0.14-0.06,0.24-0.03,0.35,0.02c0.66,0.29,1.32,0.56,2.01,0.78C27.68,29.19,27.8,29.24,27.72,29.45z M28.14,27.82
       c-0.03,0.18-0.15,0.13-0.25,0.1c-0.32-0.11-0.64-0.24-1.04-0.38c0.52-0.3,0.97-0.56,1.46-0.84C28.32,27.1,28.19,27.46,28.14,27.82z
        M28.31,19.52c-0.28-0.06-0.48-0.26-0.71-0.38c-0.23-0.12-0.45-0.26-0.73-0.42c0.09-0.04,0.14-0.07,0.19-0.09
       C28.2,18.18,28.24,18.21,28.31,19.52z M22.9,16.8c0.32-0.93,0.67-1.85,1.32-2.62c0.28-0.33,0.61-0.61,1.04-0.63
       c0.43,0,0.73,0.2,0.98,0.47c0.76,0.8,1.13,1.79,1.48,2.81c0.06,0.17-0.03,0.21-0.17,0.26c-0.7,0.23-1.38,0.49-2.05,0.8
       c-0.16,0.07-0.28,0.04-0.43-0.02c-0.65-0.27-1.31-0.52-1.97-0.77C22.93,17.05,22.83,17.01,22.9,16.8z M22.48,18.42
       c0.02-0.19,0.13-0.13,0.23-0.09c0.34,0.12,0.67,0.24,1.07,0.38c-0.53,0.31-0.98,0.56-1.46,0.84C22.3,19.14,22.43,18.78,22.48,18.42
       z M22.04,21.63c0.01-0.27,0.11-0.42,0.33-0.56c0.86-0.56,1.75-1.07,2.66-1.54c0.19-0.1,0.35-0.1,0.54,0
       c0.94,0.48,1.85,1.01,2.73,1.58c0.17,0.11,0.25,0.23,0.25,0.43c-0.01,0.53,0,1.06,0,1.58c0.01,0,0.01,0,0.02,0
       c0,0.33-0.03,0.67,0.01,1c0.07,0.64-0.18,1.04-0.76,1.34c-0.76,0.4-1.49,0.86-2.25,1.26c-0.19,0.1-0.34,0.1-0.53,0
       c-0.92-0.47-1.8-0.98-2.66-1.54c-0.22-0.14-0.32-0.29-0.33-0.56C22.03,23.63,22,22.63,22.04,21.63z M16.94,19.33
       c-0.2-0.78,0.09-1.3,0.86-1.54c0.5-0.15,1.01-0.18,1.53-0.15c0.54,0.02,1.08,0.11,1.61,0.21c0.24,0.04,0.36,0.11,0.29,0.4
       c-0.17,0.69-0.28,1.39-0.34,2.09c-0.01,0.15-0.08,0.26-0.2,0.34c-0.6,0.43-1.17,0.89-1.72,1.38c-0.09,0.08-0.16,0.19-0.3,0.04
       C17.93,21.27,17.22,20.43,16.94,19.33z M20.69,22.28c0,0.58,0,1.1,0,1.65c-0.37-0.21-0.65-0.52-1.01-0.8
       C20.04,22.83,20.34,22.58,20.69,22.28z M18.65,24.15c0.12-0.13,0.19-0.04,0.27,0.03c0.56,0.5,1.14,0.97,1.75,1.41
       c0.12,0.09,0.18,0.2,0.2,0.35c0.06,0.72,0.17,1.43,0.34,2.14c0.05,0.21-0.02,0.29-0.23,0.33c-0.63,0.13-1.26,0.23-2.01,0.22
       c-0.35,0.01-0.81-0.02-1.25-0.18c-0.71-0.25-1-0.73-0.82-1.46C17.18,25.86,17.9,24.99,18.65,24.15z"/>
     <path class="st0" d="M25.32,25.45c1.26,0,2.31-1.04,2.31-2.3c0.01-1.28-1.04-2.32-2.33-2.33c-1.28-0.01-2.34,1.05-2.33,2.32
       C22.99,24.43,24.02,25.45,25.32,25.45z M25.32,22.1c0.57,0.01,1.01,0.47,1,1.05c-0.01,0.56-0.47,1.01-1.01,1
       c-0.58-0.01-1.03-0.46-1.02-1.04C24.29,22.54,24.74,22.09,25.32,22.1z"/>
   </g>
   </svg>
   `,
      cat: "React Development",
      desc: `Our experts can develop fast and smooth-running mobile applications and we provide the backend system, user interface, startup solutions, etc.`,
    },
  ];
  function createMarkup(data) {
    return {
      __html: data,
    };
  }
  return (
    <div className="ourexpertise">
      <div className="inner">
        <div className="text">
          <h3>We Work With?</h3>
          <p>
            In order to generate the finest product for your organization, you
            require an advance team with a diversified skill-set. Simboz creates
            stable and futuristic apps based on agile concepts to assist clients
            in achieving faster time-to-market and adapting to a business
            environment that is always evolving.{" "}
          </p>
        </div>
        <div className="mapdata">
          {mapdata.map((item, index) => {
            return (
              <div className="box" key={index}>
                <div
                  dangerouslySetInnerHTML={createMarkup(item.icon)}
                  key={index}
                  className="icon"
                ></div>
                <h4>{item.cat}</h4>
                <p>{item.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Ourexpertise;
