import React from "react";
import "./css/Businesstaxsec.scss";
import { Link } from "react-router-dom";

function Businesstaxsec({ data }) {
  function createMarkup(abcd) {
    return { __html: abcd };
  }
  return (
    <>
      {window.screen.width > 750 ? (
        <div
          className="Businesstaxsec"
          style={{ backgroundImage: `url('assets/webdevelopment/201.webp')` }}
        >
          <div className="Businesstaxsecinner">
            <h2 className="h1">{data.h1}</h2>
            <div className="line"></div>
            <div dangerouslySetInnerHTML={createMarkup(data.p)}></div>

            <div className="row">
              {data.arr.map((item, index) => {
                return (
                  <div className="cols" key={index}>
                    <div className="sec">
                      <div className="fb">
                        {item[2] ? <img src={item[2]} /> : null}
                        <p> {item[0]}</p>
                      </div>
                      <span>{item[1]}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="ourprocess">
          <div className="innerwi">
            <h2 className="h1">{data.h1}</h2>
            <div className="line"></div>
            <div dangerouslySetInnerHTML={createMarkup(data.p)}></div>
          </div>
          <div className="row">
            {data.arr.map((item, index) => {
              return (
                <div className="cols" key={index}>
                  <div className="sec">
                    <div className="fb">
                      {item[2] ? <img src={item[2]} /> : null}
                    </div>
                    <div className="infb">
                      <p
                        className="para"
                        style={{ fontWeight: "600", fontSize: "18px" }}
                      >
                        {" "}
                        {item[0]}
                      </p>
                      <p>{item[1]}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default Businesstaxsec;
