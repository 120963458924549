import React from "react";
import "./css/CustomSubbanner.scss";
function CustomSubbanner({ data }) {
  function createMarkup(param) {
    return { __html: param };
  }

  return (
    <div
      className="CustomSubbanner"
      style={{
        marginBottom: data.marginbottom,
        backgroundImage: data.backgroundImg,
      }}
    >
      <div className="heading">
        <h2 dangerouslySetInnerHTML={createMarkup(data.heading)}></h2>
        <p>{data.para}</p>
        <a href="tel:+13477593666">Get Call</a>
        
      </div>

      <img
        className="image"
        src={data.img}
        style={{ width: data.width, bottom: data.bottom }}
      />
    </div>
  );
}

export default CustomSubbanner;
