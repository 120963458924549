import React from "react";
import "./css/Portfoliobanner.scss";
import Button from "./Button";
function Portfoliobanner() {
  return (
    <div className="portfoliobanner">
      <div className="inner">
        <h1>
          Give a look at our <span>standard</span> & <span>wondering</span> work
        </h1>
        <p>
          We have vast experience of work, we turn small start-up businesses
          into a large brand by developing with new techniques website, fast app
          development, and sophisticated designs of PWA. Let’s give a glance
          look at the impressive work that we have done!
        </p>
        <a href="tel:+19563023709">Get Call</a>
      </div>
    </div>
  );
}

export default Portfoliobanner;
