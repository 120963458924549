import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Appemergingtec from "../components/Appemergingtec";
import Buildin from "../components/Buildin";
import Businesstaxsec from "../components/Businesstaxsec";
import Cta from "../components/Cta";
import CustomSubbanner from "../components/CustomSubbanner";
import Description from "../components/Description";
import Descriptionwh from "../components/Descriptionwh";
import Dynamiccontent from "../components/Dynamiccontent";
import Dynamiccontentwh from "../components/Dynamiccontentwh";
import Faq from "../components/Faq";
import Homeportfolio from "../components/Homeportfolio";
import Severalapp from "../components/Severalapp";
import Webtec from "../components/Webtec";

import "./Shopifydevelopmentservice.scss";

function Shopifydevelopmentservice() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  let banner = {
    img: "assets/magento/25.webp",
    width: "700px",
    heading: "  Result-Oriented  <span> Shopify Development  </span>  Solution",
    para: "A perfect solution for you to establish a brand ecommerce store that builds customer confidence & loyalty.",
    bottom: "-250px",
    marginbottom: "250px",
    backgroundImg: `url('assets/appdevelopment/shopifyban.webp')`,
  };
  let webd = {
    title: "Shopify Developers from NYC",
    desc: `Without regard to the technological constraints and limitations, our proficient Shopify developers implement the most up-to-date functionalities in accordance with the customer's specifications.`,
    arr: [
      "Strong Security features",
      "Fast page loading",
      "User friendly themes",
      "Maximizing Business Profits",
      "Updating and maintenance ",
      "Multiple payment Options ",
    ],
  };

  let portfolio = {
    title: "Our Work Experience",
    desc: "Discover how we have helped businesses with our Shopify development services and deliver customers beyond their expectations.",
  };
  // builtin feature

  let builtinfeature = {
    title: "Excellent Build-In Features",
    desc: "Our excellent build-in features can enhance the performance of your Shopify e-commerce store, such as:",
    arr: [
      "Easy to Set Up",
      "Easy to Manage",
      "Store Management",
      "SEO",
      "Exceptional Security ",
      "Support & Maintenance ",
    ],
  };

  let cta = {
    one: "Maximize your revenues with Shopify development services!",
    two: "Hire veteran Shopify developers at Simboz!",
  };

  let mdservice = {
    h1: "Shopify Development Services in NYC",
    subtitle: "We Fulfill Your Customization",
    desc: `<p>From a well-built Shopify store to managing everything, we execute a user-friendly eCommerce store with our Shopify Services and give the leverage to achieve extraordinary success. No matter how complicated your requirements are, either its customization or migration; theme or design; we meet each customer's requirements focusing on your business objectives.  </p>
            <p>With our extraordinarily Shopify website design services, we execute from the most creative website design and development to relatively unique insights. We take pride in extending your business on a large scale with our expert Shopify developers.</p>`,
  };

  let mds = {
    title: "Most Recent Tools and Languages",
    subtitle: "",
    desc: `<p>We fulfill all of your objectives using the firsthand knowledge of Shopify programming languages, such as Ruby and Python in order to surpass your priorities with the quality Shopify services that we offer. </p>
        <p>We continuously work to improve the functionality of the store and optimize it with the most up-to-date frontend framework: Hydrogen. By using Shopify's theme template language, Liquid, along with HTML, CSS, JavaScript, and JSON, you can leave your innovative and customer-oriented Shopify theme customization for us.</p>
        <p>Without regard to the technological constraints and limitations, our proficient Shopify developers implement the most up-to-date functionalities in accordance with the customer's specifications. </p>`,
  };

  let grow = {
    title: "We Provide Future-Promising Shopify Stores ",
    subtitle: "",
    desc: `<p>At Simboz, our expert developers are highly skilled at designing, development, and maintenance sector to help you with Shopify website design services to expand your online business. </p>
        <p>Whether you are a newly designed startup, mid-sized business, or an organized enterprises focusing on customer-centered, future succeeding, and scalable results, we provide you tailored Shopify Services for you.  </p>
        <p>We have the main concern regarding what your project requires. Keeping in mind, Simboz has the most recent technical tools and experienced team that ensures to tackle all sorts of desired Shopify development services.</p>`,
  };

  const faq = [
    [
      "<p>Why choose Shopify for your ecommerce platform?</p>",
      "<p>Shopify Website Design Services facilitate you the advanced functionality with an application style platform that enables you to download, install as well as customize the whole store. It offers inventory management, accounting, and business reporting as well as enhances sales and social media promotion. It offers an easy way to quickly launch an ecommerce store without the fuss over servers and development costs. Since all the features are logically structured, the admin interface is clean, user-friendly and pretty intuitive.</p>",
    ],

    [
      "<p>What is the total cost of Shopify development services?</p>",
      "<p>It depends on the complexity of the project as its features, technologies that used, and the quality and creativity to provide the Shopify Website Design Services.</p>",
    ],

    [
      "<p>What if I don’t like the upgraded Shopify development services or what if the designing and contents are not provided as I recommended?</p>",
      "<p>Our Ruby and Python expert developers design the store in that way which makes you happy, and if you don’t like, we will design on another way until you get complete satisfaction.</p>",
    ],
    [
      "<p>After launching the Shopify development code what if I get the errors or the app crashed?</p>",
      "<p>First thing is that we don’t launch the code randomly, unless we testify all the programs and make surety that the store will not create any hectic or simple problem. But even if there is a malfunction – you have our backup team supporting all the crashes popping-up for three months, until then your app will start running smoothly.</p>",
    ],
    [
      "<p>Do you provide me the store content or I have to make my own?</p>",
      "<p>Yes, we do! But it’s up to you… If you don’t have the text for your Shopify store & want to add impressive Shopify contents – texts and blogs, we are here to give you our enriched quality Shopify services as well.</p>",
    ],
  ];

  const data = {
    h1: "Our Process",
    p: `
         <p className='fp'>
         We put into action to process the desired Shopify development in order to obtain the following benefits as a whole:
        </p>`,

    arr: [
      [
        `Planning  `,
        `Plan after understanding the project brief`,
        "assets/icon/SHOPIFY/Planning.png",
      ],

      [
        `Shopify Team`,
        `Assign you a team throughout the entire procedure`,
        "assets/icon/SHOPIFY/Shopify-Team.png",
      ],

      [
        `Designing UI/UX `,
        ` Create eye-catching and user-friendly UI for each page`,
        "assets/icon/SHOPIFY/DesigningUIUX.png",
      ],

      [
        `Development   `,
        `Code from the ground to develop and customize each page on specification`,
        "assets/icon/SHOPIFY/Development.png",
      ],

      [
        `Launch  `,
        `Keeping your quality assurance, we launch Shopify store on your domain`,
        "assets/icon/SHOPIFY/Launch.png",
      ],

      [
        `Maintenance  `,
        `Maintain and fix bugs with recent updates`,
        "assets/icon/SHOPIFY/Maintenance.png",
      ],
    ],

    // p2: `With each of our clients, we establish a long-lasting relationship in which we grow to become their go-to resource for a wide range of daily business challenges. Contact us now.`
  };

  return (
    <div className="Shopifydevelopmentservice">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shopify development Service Providing Agency</title>
        <meta
          name="description"
          content="Expand your eCommerce business to new heights, our developers create a customer-oriented store by customizing with Liquid, along with Ruby, Python and Hydrogen."
          data-react-helmet="true"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        {/* ------------------------------- */}

        <meta
          data-react-helmet="true"
          property="og:image"
          content="https://simboz.com/assets/logos/logo.png"
        />
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://simboz.com/shopify-development-service"
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Expand your eCommerce business to new heights, our developers create a customer-oriented store by customizing with Liquid, along with Ruby, Python and Hydrogen."
        />
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Shopify development Service Providing Agency"
        />
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Shopify development Service Providing Agency | Simboz "
        />
        <meta data-react-helmet="true" property="fb:app_id" content="Simboz" />
        <meta data-react-helmet="true" property="og:type" content="website" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Expand your eCommerce business to new heights, our developers create a customer-oriented store by customizing with Liquid, along with Ruby, Python and Hydrogen."
        />
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://simboz.com/shopify-development-service"
        />
      </Helmet>

      <CustomSubbanner data={banner} />
      <Description data={webd} />
      <Homeportfolio portfolio={portfolio} />
      {/* <Appemergingtec data={builtinfeature} /> */}
      <Descriptionwh data={builtinfeature} />
      <Cta data={cta} />
      <Dynamiccontentwh data={mdservice} />
      <Dynamiccontent data={mds} />
      <Dynamiccontentwh data={grow} />

      <Businesstaxsec data={data} />
      {/* <Description data={process} /> */}
      <Severalapp />
      <Faq faq={faq} />
    </div>
  );
}

export default Shopifydevelopmentservice;
