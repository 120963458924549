import React from "react";
import "./css/Wearecreative.scss";
function Wearecreative() {
  return (
    <div className="Wearecreative">
      <div className="digiex">
        <div className="bor">
          <div className="hedi">
            <h4>Vision</h4>
            <p>
              <span>
                Our main vision is to sort out all the business challenges with
                innovative thinking and providing unique services and build
                strong business relationships through integrity, understanding,
                and highly technical leadership.
              </span>
            </p>
          </div>
        </div>
        <div className="texi">
          <h4>Mission</h4>
          <p>
            <span>
              Our mission is to provide excellent services, professional skills,
              and fast delivery, we are totally focused on the client's needs
              and requirements, Our mission is to fulfill all our client's
              requirements.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Wearecreative;
