import React, { useState } from "react";
import "./css/Severalapp.scss";
function Severalapp() {
  let arr = [
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M22.5 12H18.5L15.5 21L9.5 3L6.5 12H2.5"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg> Healthcare`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M20.02 11V21H4.01999V11"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2.92099 6.8885C2.15599 8.8685 3.63149 11 5.75499 11C7.41199 11 8.76449 9.657 8.76449 8C8.76449 8.79565 9.08056 9.55871 9.64317 10.1213C10.2058 10.6839 10.9688 11 11.7645 11H12.2735C13.0691 11 13.8322 10.6839 14.3948 10.1213C14.9574 9.55871 15.2735 8.79565 15.2735 8C15.2735 9.657 16.6265 11 18.2835 11C20.4075 11 21.884 8.8675 21.1185 6.886L19.617 3H4.42249L2.92099 6.8885V6.8885Z"  stroke-width="2" stroke-linejoin="round"></path></svg>E-Commerce`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M19 5L5 19"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6.5 9C7.88071 9 9 7.88071 9 6.5C9 5.11929 7.88071 4 6.5 4C5.11929 4 4 5.11929 4 6.5C4 7.88071 5.11929 9 6.5 9Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17.5 20C18.8807 20 20 18.8807 20 17.5C20 16.1193 18.8807 15 17.5 15C16.1193 15 15 16.1193 15 17.5C15 18.8807 16.1193 20 17.5 20Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Marketplace`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path d="M2.5 3H8.5C9.56087 3 10.5783 3.42143 11.3284 4.17157C12.0786 4.92172 12.5 5.93913 12.5 7V21C12.5 20.2044 12.1839 19.4413 11.6213 18.8787C11.0587 18.3161 10.2956 18 9.5 18H2.5V3Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M22.5 3H16.5C15.4391 3 14.4217 3.42143 13.6716 4.17157C12.9214 4.92172 12.5 5.93913 12.5 7V21C12.5 20.2044 12.8161 19.4413 13.3787 18.8787C13.9413 18.3161 14.7044 18 15.5 18H22.5V3Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Education`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.59 13.51L15.42 17.49"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.41 6.51001L8.59 10.49"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Social Media`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M5.5 7L12.5 2V22H5.5V7Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12.5 6.5L19.5 11.5V22"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2 22H22"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Real Estate`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3 6H21"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Food delivery`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M16 3H1V16H16V3Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M16 8H20L23 11V16H16V8Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5.5 21C6.88071 21 8 19.8807 8 18.5C8 17.1193 6.88071 16 5.5 16C4.11929 16 3 17.1193 3 18.5C3 19.8807 4.11929 21 5.5 21Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18.5 21C19.8807 21 21 19.8807 21 18.5C21 17.1193 19.8807 16 18.5 16C17.1193 16 16 17.1193 16 18.5C16 19.8807 17.1193 21 18.5 21Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Logistics`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1 10H23"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Finance`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 17.5V13.855C2.49997 13.2976 2.65522 12.7513 2.94834 12.2772C3.24146 11.8031 3.66086 11.4201 4.1595 11.171L4.5005 11.001L5.6555 7.046C5.75796 6.60641 6.00611 6.21441 6.3596 5.93372C6.71309 5.65304 7.15113 5.50018 7.6025 5.5H17.4465C17.9012 5.49998 18.3423 5.65489 18.6972 5.9392C19.052 6.22351 19.2994 6.62024 19.3985 7.064L20.501 11.0005L20.841 11.1705C21.3395 11.4196 21.7588 11.8027 22.0519 12.2768C22.3449 12.7508 22.5001 13.2972 22.5 13.8545V17.5C22.5 17.8978 22.342 18.2794 22.0607 18.5607C21.7794 18.842 21.3978 19 21 19H20.0015V19.5C20.0015 20.0304 19.7908 20.5391 19.4157 20.9142C19.0406 21.2893 18.5319 21.5 18.0015 21.5C17.7387 21.5002 17.4785 21.4486 17.2357 21.3482C16.9928 21.2478 16.7722 21.1005 16.5863 20.9147C16.4004 20.729 16.253 20.5085 16.1524 20.2657C16.0518 20.023 16 19.7628 16 19.5V19H9V19.5C9 20.0304 8.78929 20.5391 8.41421 20.9142C8.03914 21.2893 7.53043 21.5 7 21.5C6.46957 21.5 5.96086 21.2893 5.58579 20.9142C5.21071 20.5391 5 20.0304 5 19.5V19H4C3.60218 19 3.22064 18.842 2.93934 18.5607C2.65804 18.2794 2.5 17.8978 2.5 17.5V17.5Z"  stroke-width="2" stroke-linejoin="round"></path><path d="M7.25 16.5C7.94036 16.5 8.5 15.9404 8.5 15.25C8.5 14.5596 7.94036 14 7.25 14C6.55964 14 6 14.5596 6 15.25C6 15.9404 6.55964 16.5 7.25 16.5Z" fill></path><path d="M17.75 16.5C18.4404 16.5 19 15.9404 19 15.25C19 14.5596 18.4404 14 17.75 14C17.0596 14 16.5 14.5596 16.5 15.25C16.5 15.9404 17.0596 16.5 17.75 16.5Z" fill></path><path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 5.5H15.5L15.0045 3.022C14.9435 2.717 14.6995 2.5 14.4185 2.5H10.5815C10.3 2.5 10.0565 2.717 9.9955 3.022L9.5 5.5Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8 11.5H17"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Taxi booking`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path d="M12.5 20.5099C13.7155 20.5099 14.2155 21.1649 14.867 21.3104C15.5185 21.4564 16.0655 21.4964 16.6255 21.2499C17.735 20.7609 18.411 20.1789 19.2315 19.3094C20.7525 17.6974 21.5 15.4819 21.5 12.5144C21.5 9.54691 20.7 8.01291 19.4665 7.00991C18.2335 6.00691 17.5185 5.81491 16.0275 6.00691C14.5365 6.19891 13.7245 7.80091 12.504 7.80091C11.284 7.80091 9.7965 6.22141 8.503 6.00691C7.209 5.79191 6.5 6.00691 5.38 7.00991C4.26 8.01291 3.5 9.57491 3.5 12.5149C3.5 15.4539 4.276 17.6329 5.7965 19.2449C6.6165 20.1144 7.265 20.7614 8.374 21.2499C8.934 21.4969 9.5135 21.4569 10.142 21.3109C10.771 21.1659 11.271 20.5109 12.5 20.5109V20.5099Z"  stroke-width="2"></path><path d="M12.504 7.801C12.8965 3.934 14.564 2 17.5055 2C17.7755 2.814 17.845 3.6015 17.7145 4.363C17.6525 4.725 17.4605 5.241 17.138 5.911"  stroke-width="2" stroke-linecap="round"></path><path d="M7.61498 13C7.47648 13.673 7.47648 14.3655 7.61498 15.0785C7.75298 15.791 8.04748 16.341 8.49798 16.7285"  stroke-width="2" stroke-linecap="round"></path></svg>AgriTech`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path d="M12.5 2L15.59 8.26L22.5 9.27L17.5 14.14L18.68 21.02L12.5 17.77L6.32 21.02L7.5 14.14L2.5 9.27L9.41 8.26L12.5 2Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Events`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><g clip-path="url(#clip0_451_3778)"><path d="M9.5 22C10.0523 22 10.5 21.5523 10.5 21C10.5 20.4477 10.0523 20 9.5 20C8.94772 20 8.5 20.4477 8.5 21C8.5 21.5523 8.94772 22 9.5 22Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M20.5 22C21.0523 22 21.5 21.5523 21.5 21C21.5 20.4477 21.0523 20 20.5 20C19.9477 20 19.5 20.4477 19.5 21C19.5 21.5523 19.9477 22 20.5 22Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M1.5 1H5.5L8.18 14.39C8.27144 14.8504 8.52191 15.264 8.88755 15.5583C9.25318 15.8526 9.7107 16.009 10.18 16H19.9C20.3693 16.009 20.8268 15.8526 21.1925 15.5583C21.5581 15.264 21.8086 14.8504 21.9 14.39L23.5 6H6.5"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_451_3778"><rect width="24" height="24" fill="white" transform="translate(0.5)"></rect></clipPath></defs></svg>Retail`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path d="M21.71 15.8901C21.0738 17.3946 20.0788 18.7203 18.8119 19.7514C17.545 20.7825 16.0448 21.4875 14.4424 21.8049C12.8401 22.1222 11.1844 22.0422 9.62015 21.5719C8.05588 21.1015 6.63063 20.2551 5.46903 19.1067C4.30742 17.9583 3.44482 16.5428 2.95664 14.984C2.46846 13.4252 2.36957 11.7706 2.6686 10.1647C2.96764 8.55886 3.6555 7.05071 4.67205 5.77211C5.6886 4.49351 7.00289 3.4834 8.50001 2.83008"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M22.5 12C22.5 10.6868 22.2413 9.38642 21.7388 8.17317C21.2362 6.95991 20.4997 5.85752 19.5711 4.92893C18.6425 4.00035 17.5401 3.26375 16.3268 2.7612C15.1136 2.25866 13.8132 2 12.5 2V12H22.5Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Productivity`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none"><path d="M4.45002 2.5C5.86852 4.01 6.57802 5.97 6.57802 8.382C6.57802 12 3.49952 14.769 2.98452 16.75C2.64102 18.0705 2.47952 19.654 2.49952 21.5"  stroke-width="2" stroke-linecap="round"></path><path d="M20.128 2.5C18.709 4.01 17.9995 5.97 17.9995 8.382C18 12 21.078 14.769 21.593 16.75C21.9365 18.0705 22.098 19.654 22.078 21.5"  stroke-width="2" stroke-linecap="round"></path><path d="M3.62201 15.4185C6.88451 17.4835 9.84701 18.516 12.5105 18.516C15.1735 18.516 17.99 17.4835 20.9595 15.4185"  stroke-width="2" stroke-linecap="round"></path><path d="M12.5 15.5C12.8315 15.5 13.1495 15.3683 13.3839 15.1339C13.6183 14.8995 13.75 14.5815 13.75 14.25C13.75 13.9185 13.6183 13.6005 13.3839 13.3661C13.1495 13.1317 12.8315 13 12.5 13C12.1685 13 11.8505 13.1317 11.6161 13.3661C11.3817 13.6005 11.25 13.9185 11.25 14.25C11.25 14.5815 11.3817 14.8995 11.6161 15.1339C11.8505 15.3683 12.1685 15.5 12.5 15.5Z" fill></path></svg>Fitness`,
    ],
    [
      `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M20 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M16 21V5C16 4.46957 15.7893 3.96086 15.4142 3.58579C15.0391 3.21071 14.5304 3 14 3H10C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V21"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>Travel`,
    ],
  ];

  function createMarkup(data) {
    return {
      __html: data,
    };
  }
  return (
    <div className="Severalapp">
      <div className="severalintro">
        <h3 className="h1">
          We have built several stores in your industry as well.
        </h3>
        <p>
          We will have our insight numbers running on the top right corner of
          each industry.
        </p>
      </div>
      <div className="mapcont">
        {arr.map((item, index) => {
          return (
            <div
              dangerouslySetInnerHTML={createMarkup(item)}
              key={index}
              className="inner"
            ></div>
          );
        })}
      </div>
    </div>
  );
}

export default Severalapp;
