import "./css/ContactForm.scss";
import { FaMobileAlt, FaEnvelope, FaHome } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import React, { useState, useRef } from "react";

function ContactForm() {
  const [isSubmit, setIsSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: true });
  let initialVailue = {
    name: "",
    email: "",
    number: "",
    message: "",
  };

  let url = "https://simboz.admin.simboz.com/insertapi.php";
  const [formData, setFormData] = useState(initialVailue);

  const getData = (e) => {
    e.preventDefault();
    console.log(e.target.name, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    const { name, email, number, message } = formData;
    if (name && email && number && message) {
      const _formaData = new FormData();
      console.log(_formaData, "formdata 1");
      _formaData.append("name", name);
      _formaData.append("email", email);
      _formaData.append("phone", number);
      _formaData.append("message", message);
      console.log(_formaData, "formdata 2");

      axios
        .post(url, _formaData)
        .then((res) => {
          console.log(res);
          if (res.data) {
            Swal.fire(
              "Query Sent Successfully",
              "Thank you for contacting Us, One of our representative will get back to you shortly",
              "success"
            );
            setFormData({
              name: null,
              email: null,
              number: null,
              message: null,
            });
          }
        })
        .then(reset);
    } else {
      console.log("nt");
    }
  };

  return (
    <div className="Contactformcont" id="contact">
      <div className="contactforminner">
        <div className="contactinner">
          <p className="contacth3">Say Hi </p>
          <p className="contacth1">Let’s Collaborate.</p>
          <p className="contactpara">
            Connect with us now to discuss your project and possible
            partnerships.
          </p>
          <div className="contacticonsec">
            <span className="contacticons">
              {" "}
              <FaMobileAlt />
            </span>
            <a href="tel:+13477593666">+1 (347) 759-3666</a>
          </div>
          <div className="contacticonsec">
            <span className="contacticons">
              {" "}
              <FaEnvelope />
            </span>
            <a href="mailto:contact@simboz.com">contact@simboz.com</a>
          </div>
          <div className="contacticonsec">
            <span className="contacticons">
              {" "}
              <FaHome />
            </span>

            <a
              href="https://www.google.com/maps/place/873+Broadway,+New+York,+NY+10010,+USA/@40.7381295,-73.9912491,18.5z/data=!4m6!3m5!1s0x89c259a2478c382f:0xdd2aa58a546ff175!8m2!3d40.7381491!4d-73.9903457!16s%2Fg%2F11bw3xgvsz"
              target="_blank"
            >
              873 Broadway nyc ny 10003
            </a>
          </div>
        </div>

        <div className="contactr">
          <form onSubmit={handleSubmit(onSubmit)} onKeyUp={getData}>
            <input
              className="inputfield"
              type="text"
              placeholder="Full Name"
              {...register("name", {
                required: "Full name is Required",
                maxLength: 80,
              })}
            />
            <input
              className="inputfield"
              type="text"
              placeholder="Email"
              {...register("email", {
                required: "Email  is Required",
                pattern: /^\S+@\S+$/i,
              })}
            />
            <input
              className="inputfield"
              type="tel"
              placeholder="Mobile number"
              {...register("number", {
                required: "Mobile number  is Required",
                minLength: 6,
                pattern:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i,
              })}
            />
            <textarea
              className="textarea"
              rows="5"
              cols="50"
              type="text"
              placeholder="Message"
              {...register("message", {
                required: "Message  is Required",
                maxLength: "auto",
              })}
            />
            <button type="submit" className="inpbtn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
